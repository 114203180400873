import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styles from './searchForm.module.css';

const SearchForm = ({ handleSubmit, formSubmit, placeHolder, emptyFieldErrorMessage }) => {

    const onFormSubmit = formValues => {
        formSubmit(formValues);
    };

    const renderForm = () => {
        return (
            <div className='row'>
                <div className='col'>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <Field
                            name='searchCriteria'
                            component={renderSearchTextBox}
                            placeHolder={placeHolder}
                        />
                    </form>
                </div>
            </div>
        )
    };

    return (
        <div className='mt-3'>
            {renderForm()}
        </div>
    )
};

const renderSearchTextBox = ({ input, placeHolder, meta }) => {

    const renderError = () => {
        if (!meta.error || !meta.submitFailed) { return; }

        return (
            <span className={`text-danger ${styles.errorMessage}`}>{meta.error}</span>
        )
    }

    return (
        <>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <img src='./image/unit-search/truck.png' width='40px' height='13px' alt='unit-search-truck' />
                    </span>
                </div>
                <input
                    {...input}
                    className='form-control'
                    type='text'
                    placeholder={placeHolder}
                />
                <div className="input-group-append">
                    <button className='btn btn-primary' type='submit'>Search</button>
                </div>
            </div>
            {renderError()}
        </>
    )
}


const validate = (formValues, { emptyFieldErrorMessage }) => {
    const errors = {};

    if (!formValues.searchCriteria || formValues.searchCriteria.trim() === '') {
        errors.searchCriteria = emptyFieldErrorMessage;
    }

    return errors;
};

const decoratedForm = reduxForm({
    form: 'searchForm',
    validate
})(SearchForm);

export default decoratedForm;