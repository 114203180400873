import React from "react";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import { Button } from '@dfds-ui/react-components'

export const CommandListRenderer = (schema, props) => {
    if (!schema || !schema.Commands) {
        return;
    }

    const orderedCommands = schema.Commands.slice(0).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);

    return orderedCommands.map(command => {

        if (command.authorizationWrapper) {
            return AuthorizationWrapper((
                <Button className='float-right' onClick={() => executeHandler(command, props)}>{command.name}</Button>
            ), command.ordering + 'commandButton', command.authorizationWrapper);
        }

        return (
            <Button className='float-right' key={command.ordering + 'commandButton'} onClick={() => executeHandler(command, props)}>{command.name}</Button>
        )
    });
};

const executeHandler = (command, props) => {
    if (command.isConfirmationNeeded) {
        //TODO: Change with confirm modal when anatolia push it to master
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(`Do you want to proceed to ${command.name} action?`)) {
            return;
        }
    }

    let modelParameter = {

    };

    command.params.forEach(item => {
        modelParameter[item] = props.initialValues[item];
    });

    props[command.callback](modelParameter);
};
