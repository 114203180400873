/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import UnitDetailTransportRow from './UnitDetailTransportRow';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {Button} from 'react-bootstrap';
import history from '../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const UnitTransport = ({ unit}) => {
    return (
        <div className='card'>
            <div className='card-header' href="#collapseTransports" data-toggle="collapse" data-parent="#accordion">
                <h5 className='mb-0 pl-3'>
                    <div className="row">
                        <div>
                            <img src="/image/icon/down.svg" className="color-action-blue" width="20px" alt='' /> Transports
                        </div>

                        {/* {renderAddNewShipments(unit)} */}
                    </div>
                       
                </h5>
            </div>
            <div id="collapseTransports" className="collapse show">
                {renderTransportRows(unit)}
            </div>
            <LoadingIndicator id={'fetchFileList'} />
        </div>
    )

}

const newShipmentsClickedEvent=(unit)=>{  
    history.push(`/shipments/${unit.internalId}`);
}

const renderAddNewShipments=(unit)=>{

    if(!unit) {return};
    
    if(unit.type!=="Outbound"){return};

    return(
    
            <div className='ml-auto text-right mt-2'> 
                <Button variant="transparent"   title="Add New Shipments" onClick={()=>newShipmentsClickedEvent(unit)}>
                    <FontAwesomeIcon icon="pallet" /></Button>                             
            </div>
    );   

}
const renderTransportRows = unit => {

    if (!unit || !unit.transports) { return; }

    return unit.transports.map(transport => {
        return (
            <UnitDetailTransportRow
                key={transport.id}
                transport={transport}
            />
        )
    })
}

export default UnitTransport;