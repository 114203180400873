import React, {useEffect, useState} from 'react';


const ShipmentSummary = ({ shipment }) => {

    const [selectedShipment, setSelectedShipment] = useState({});

    useEffect(()=>{
        if(shipment){
            setSelectedShipment(shipment);
        }
    },[shipment])

    return (
        <>
            <div className='card'>
                <div className='card-body' mt={0}>
                    <div className='col px-0'>
                        <div className='row'>
                            {renderInfoField('Thus', selectedShipment.thuCount)}
                            {renderInfoField('Suffix', selectedShipment.suffix)}
                            {renderInfoField('Inbound Unit', selectedShipment.inboundUnitReference)}
                            {renderInfoField('Outbound Unit', selectedShipment.outboundUnitReference)}
                            {renderInfoField('Inbound Transport', selectedShipment.inboundTransportId)}
                            {renderInfoField('Outbound Transport', selectedShipment.outboundTransportId)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const renderInfoField = (label, value) => {

    const displayValue = value ? value : '';

    return (
        <div className='col-md-6'>
            <div className='form-group'>
                <label className="text-muted">{label}</label>
                <input type='text' className='form-control form-control-sm' disabled value={displayValue}></input>
            </div>
        </div>
    )
}

export default ShipmentSummary;