const fileControllerActionTypes = Object.freeze({
    CLEAR_FILELIST: Symbol('CLEAR_FILELIST'),
    ADD_FILE_TO_FILELIST: Symbol('ADD_FILE_TO_FILELIST'),
    REMOVE_FILE_FROM_FILELIST: Symbol('REMOVE_FILE_FROM_FILELIST'),
    FILE_UPLOAD_PERCENT_CHANGED: Symbol('FILE_UPLOAD_PERCENT_CHANGED'),
    CHANGE_FILE_ID: Symbol('CHANGE_FILE_ID'),
    SAVE_FILE_LIST: Symbol('SAVE_FILE_LIST'),
    RESET_FILE_DOWNLOAD_STATUS: Symbol('RESET_FILE_DOWNLOAD_STATUS'),
    SET_FILE_DOWNLOAD_STATUS_TO_DOWNLOADED: Symbol('SET_FILE_DOWNLOAD_STATUS_TO_DOWNLOADED'),
});

export default fileControllerActionTypes;