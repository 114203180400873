import httpClient from "../../core/httpclient";
import { spinnerService } from "@simply007org/react-spinners";
import companyActions from "../type/company";

const apiUrl = 'xms-identity-api/api/company';

export const getCompanyList = () => async dispatch => {
    const endPoint = `${apiUrl}/companies`;
    spinnerService.show('getCompanyList')
    httpClient.post(endPoint)
        .then(response => {
            dispatch({
                type: companyActions.COMPANY_GETCOMPANIES_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('getCompanyList')
        });
}

export const insertOrUpdateCompany = (name, code) => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    spinnerService.show('companyDetail')
    const insertOrUpdateCompanyRequest = {
        name: name.trim(),
        code: code.trim(),
        active: true
    }
    httpClient.post(endPoint, insertOrUpdateCompanyRequest)
        .then(response => {
            dispatch({
                type: companyActions.COMPANY_CLEARCOMPANYSTATE_SUCCESS
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('companyDetail');
        });
}

export const getCompany = (companyList, code) => async dispatch => {
    dispatch({
        type: companyActions.COMPANY_GETCOMPANY_SUCCESS,
        payload: companyList && companyList.length > 0 ? companyList.find(x => x.code === code) : null
    });
}

export const clearCompanyState = () => async dispatch => {
    dispatch({
        type: companyActions.COMPANY_CLEARCOMPANYSTATE_SUCCESS
    });
}