import unitTypes from '../type/unit';

const initialState = {
    fetchedUnit: null,
    fileList: null,
    docsMissingStateList: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case unitTypes.UNIT_FETCHED:
            return {
                ...state,
                fetchedUnit: action.payload
            }
        case unitTypes.SAVE_SHIPMENT_FILE_LIST:
            const newFileList = { ...state.fileList };
            for (const shipment of action.payload) {
                newFileList[shipment.relativePath] = shipment.files
            }
            return {
                ...state,
                fileList: newFileList
            }
        case unitTypes.SAVE_DOCS_MISSING_STATE_LIST:
            const newDocsMissingStateList = { ...state.docsMissingStateList };
            for (const docsMissingStateFlag of action.payload) {
                newDocsMissingStateList[docsMissingStateFlag.orderItemId] = docsMissingStateFlag.isMissingDocumentStateFlagSet
            }

            return {
                ...state,
                docsMissingStateList: newDocsMissingStateList
            }
        case unitTypes.RESET_UNIT_DATA:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}