import reservedSlotActions from "../type/reservedSlot";

const initialState = {
    rule: null,
    rules: null,
    formSchema: null,
    refreshRules: false,
    isSaveRuleSuccess: null,
    businessAreas: null,
    unitTypes: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case reservedSlotActions.RULES_RETRIEVED:
            return {
                ...state,
                refreshRules: false,
                rules: action.payload
            }
        case reservedSlotActions.RULE_RETRIEVED:
            return {
                ...state,
                rule: action.payload
            }

        case reservedSlotActions.RULE_SAVED:
            return {
                ...state,
                rule: null,
                refreshRules:true,
                isSaveRuleSuccess: true
            }
        case reservedSlotActions.RULE_DELETED:
            return {
                ...state,
                rule: null,
                refreshRules:true
            }
        case reservedSlotActions.FINALIZE_ADD_OR_UPDATE: {
            return {
                ...state,
                isSaveRuleSuccess: null
            }
        }
        case reservedSlotActions.FORM_LOADED: {
            return {
                ...state,
                formSchema: action.payload
            }
        }
        case reservedSlotActions.BUSINESS_AREAS_RETRIEVED:{
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case reservedSlotActions.UNIT_TYPES_RETRIEVED:{
            return {
                ...state,
                unitTypes: action.payload
            }
        }
        default:
            return state;
    }

}