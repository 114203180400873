import fileControllerTypes from '../type/fileController';
import _ from 'lodash';

const initialState = {
    fileList: null,
    addFileError: null,
    isFileDownloaded: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case fileControllerTypes.ADD_FILE_TO_FILELIST:
            return {
                ...state,
                fileList: {
                    ...state.fileList,
                    [action.payload.uid]: action.payload
                },
                addFileError: null
            }
        case fileControllerTypes.REMOVE_FILE_FROM_FILELIST:
            return {
                ...state,
                fileList: _.omit({ ...state.fileList }, [action.payload.uid]),
                addFileError: null
            }
        case fileControllerTypes.FILE_UPLOAD_PERCENT_CHANGED:
            return {
                ...state,
                fileList: {
                    ...state.fileList,
                    [action.payload.fileToUpload.uid]: action.payload.fileToUpload
                },
                addFileError: null
            }
        case fileControllerTypes.SAVE_FILE_LIST:
            const newSaveFileList = {};
            for (const file of action.payload) {
                newSaveFileList[file.uid] = file
            }
            return {
                ...state,
                fileList: newSaveFileList
            }
        case fileControllerTypes.CHANGE_FILE_ID:
            const oldFileData = state.fileList[action.payload.oldId];
            oldFileData.uid = action.payload.newId;
            const newFileList = _.omit({ ...state.fileList }, [action.payload.oldId]);
            return {
                ...state,
                fileList: {
                    ...newFileList,
                    [oldFileData.uid]: oldFileData
                },
                addFileError: null
            }
        case fileControllerTypes.SET_FILE_DOWNLOAD_STATUS_TO_DOWNLOADED:
            return {
                ...state,
                isFileDownloaded: true
            }
        case fileControllerTypes.CLEAR_FILELIST:
        case fileControllerTypes.RESET_FILE_DOWNLOAD_STATUS:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;

    }

}