/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PageHeader from '../component/PageHeader/PageHeader';
import { openAdditionalGates, getTimeSlots, clearState, getBusinessAreas } from '../store/action/openAdditionalGateActions';
import TimeSlotTable from '../component/TimeSlotTable/TimeSlotTable';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { Button, ButtonStack, NumberField, SelectField } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import history from '../core/history';
import Page from '../component/Page/Page';

const OpenAdditionalGate = (props) => {
    //Redux actions
    const { getTimeSlots, openAdditionalGates, clearState, getBusinessAreas } = props;

    //Redux store
    let { timeSlots, businessAreas, result } = props;

    const [additionalGateNumber, setAdditionalGateNumber] = useState(1);
    const [selectedBusinessArea, setSelectedBusinessArea] = useState(null);
    const [additionalGateNumberError, setAdditionalGateNumberError] = useState('');
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
    const [isAllTimeSlotsSlected, setIsAllTimeSlotsSelected] = useState(false);

    useEffect(() => {
        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }
    }, [businessAreas]);

    useEffect(() => {
        if (selectedBusinessArea) {
            getTimeSlots(selectedBusinessArea);
        }
    }, [selectedBusinessArea])

    useEffect(() => {
        if (isAllTimeSlotsSlected)
            setSelectedTimeSlots(timeSlots.map(timeSlot => timeSlot.startTime));
    }, [isAllTimeSlotsSlected]);

    useEffect(() => {
        if (result) {
            toast.success('Additional gates were added.');
            clearState();
            history.push('/booking/calendar-rule');
        }
    }, [result])
    const isAllTimeSlotsSlectedChanged = () => {
        setIsAllTimeSlotsSelected((prevState, props) => !prevState);
    }

    const additionalGateNumberChangedHander = (value) => {
        if (typeof value == 'number' || value <= 0) {
            setAdditionalGateNumberError('Please enter a positive number.');
        }
        else {
            setAdditionalGateNumberError('');
        }
        setAdditionalGateNumber(value);
    }
    const openAdditionalGatesClickedHandler = () => {
        openAdditionalGates(selectedTimeSlots, additionalGateNumber, selectedBusinessArea);
    }

    const timeSlotClickedHander = (date, time) => {
        setSelectedTimeSlots((prevState) => {
            let clonedSelectedTimeSlots = [...prevState];
            const indexOfSelectedTime = clonedSelectedTimeSlots.indexOf(time);
            if (~indexOfSelectedTime) {
                clonedSelectedTimeSlots.splice(indexOfSelectedTime, 1);
            } else {
                clonedSelectedTimeSlots.push(time);
            }

            setIsAllTimeSlotsSelected(clonedSelectedTimeSlots.length === timeSlots.length);

            return clonedSelectedTimeSlots;
        });
    }

    const businessAreaChangedHandler = (value) => {
        setSelectedBusinessArea(value);
        setSelectedTimeSlots([]);
        setIsAllTimeSlotsSelected(false);
    }

    return <Page backLink='/booking/calendar-rule' backToWhere='Rules'>
        <h4>
            Enter additional gate number and select the time slots.
        </h4><hr className='mt-0' />
        <Row className="mt-3">
            <Col md="12">
                <ButtonStack align="right">
                    <Button onClick={isAllTimeSlotsSlectedChanged} disabled={isAllTimeSlotsSlected}>
                        Select all<FontAwesomeIcon className="ml-2" icon={isAllTimeSlotsSlected ? 'check-square' : 'square'} />
                    </Button>
                    <Button onClick={openAdditionalGatesClickedHandler} disabled={!selectedTimeSlots || selectedTimeSlots.length === 0 || additionalGateNumber <= 0}>
                        Open gates<FontAwesomeIcon className="ml-2" icon='save' />
                    </Button>
                </ButtonStack>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <SelectField name="businessArea" label="Business Area" onChange={(e) => businessAreaChangedHandler(e.target.value)}>
                    <option>Choose..</option>
                    {businessAreas ? businessAreas.map(businessArea => <option value={businessArea.id}>{businessArea.name}</option>) : null}
                </SelectField>

            </Col>
            <Col>
                <NumberField name="additionalGateNumber" label="Additional Gate Number" value={additionalGateNumber} errorMessage={additionalGateNumberError} onChange={(e) => additionalGateNumberChangedHander(e.target.value)} />
            </Col>
        </Row>
        <Row className="mt-3">
            <TimeSlotTable
                timeSlots={timeSlots}
                selectedTimeSlots={selectedTimeSlots}
                timeSlotClickedEvent={timeSlotClickedHander}
                allowToSelectAllocatedTimeSlots={true} />
            <LoadingIndicator id='openAdditionalGates' />
        </Row>
    </Page>

}

const mapStateToProps = state => {
    return {
        timeSlots: state.additionalGate.timeSlots,
        result: state.additionalGate.openAdditionalGateResult,
        businessAreas: state.additionalGate.businessAreas
    }
}

const mapDispatchToProps = {
    getTimeSlots,
    openAdditionalGates,
    getBusinessAreas,
    clearState
}
export default connect(mapStateToProps, mapDispatchToProps)(OpenAdditionalGate);