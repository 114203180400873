import { get } from '../../core/httpClientSync';
import httpClient from "../../core/httpclient";
import { spinnerService } from "@simply007org/react-spinners";
import calendarActions from "../type/calendar";
import { utcToEpoch } from '../../core/utcToEpochConverter';


const apiUrl = 'xms-slotbooking-api/api/calendar';
const bookingApiUrl = 'xms-slotbooking-api/api/booking';
const timeSlotApiUrl = 'xms-slotbooking-api/api/timeslot';
const businessAreaApiUrl = 'xms-slotbooking-api/api/businessarea';


const getMinutes = value => (value.get('hours') * 60) + value.get('minutes')

export const getRules = () => async dispatch => {
    const endPoint = `${apiUrl}/getallactivecalendarrules`;
    spinnerService.show('getAllRules');
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: calendarActions.CALENDAR_GETALLRULES_SUCCESS,
                payload: response.data.result
            })
        })
        .catch(error => console.log(error))
        .finally(() => { spinnerService.hide('getAllRules'); });
}

export const getRule = (rules, id) => async dispatch => {
    dispatch({
        type: calendarActions.CALENDAR_GETRULE_SUCCESS,
        payload: rules && rules.length > 0 ? rules.find(x => x.id === id) : null
    })
}

export const saveRule = ruleModel => async dispatch => {
    const endPoint = `${apiUrl}/save`;

    ruleModel.gateQuota = ruleModel.gateQuota !== -1 ? ruleModel.gateQuota : null;

    let createRuleRequest = {
        StartMinutesSinceMidnight: getMinutes(ruleModel.startMinutesSinceMidnight),
        EndMinutesSinceMidnight: getMinutes(ruleModel.endMinutesSinceMidnight),
        RuleType: ruleModel.ruleType,
        RuleState: ruleModel.ruleState,
        GateQuota: ruleModel.gateQuota,
        BusinessAreaId: ruleModel.businessArea,
        SlotSize: ruleModel.slotSize
    }

    switch (ruleModel.ruleType) {
        case 'Weekday':
            createRuleRequest.Weekday = ruleModel.weekday;
            break;
        case 'SpecificDate':
            createRuleRequest.DateEpoch = utcToEpoch(ruleModel.dateEpoch);
            break;
        default:
            break;
    }
    spinnerService.show('createRule');
    httpClient.post(endPoint, createRuleRequest)
        .then(response => {
            ruleModel.id = response.data.result;
            ruleModel.startMinutesSinceMidnight = getMinutes(ruleModel.startMinutesSinceMidnight);
            ruleModel.endMinutesSinceMidnight = getMinutes(ruleModel.endMinutesSinceMidnight);
            ruleModel.businessAreaId = ruleModel.businessArea;
            ruleModel.date = createRuleRequest.DateEpoch ? createRuleRequest.DateEpoch : null;

            dispatch({
                type: calendarActions.CALENDAR_CREATERULE_SUCCESS,
                payload: ruleModel
            })
        })
        .catch(error => console.log(error))
        .finally(() => { spinnerService.hide('createRule'); });
}

export const getBusinessAreas = () => async dispatch => {
    const endPoint = `${businessAreaApiUrl}/getall`;
    const getBusinessAreasResponse = await get(endPoint, 'createRule');
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: calendarActions.BUSINESS_AREAS_RETRIEVED,
            payload: getBusinessAreasResponse.response.result
        });
    }
}

export const deleteRule = ruleId => async dispatch => {
    const endPoint = `${apiUrl}/delete?id=${ruleId}`;

    httpClient.post(endPoint)
        .then(response => {
            dispatch({
                type: calendarActions.CALENDAR_DELETERULE_SUCCESS,
                payload: ruleId
            })
        })
        .catch(error => console.log(error))
        .finally(() => { spinnerService.hide('createRule'); });
}

export const getTimeSlotSimulation = (date, businessAreaId) => async dispatch => {
    const dateEpoch = utcToEpoch(date);
    const endPoint = `${timeSlotApiUrl}/previewslotsfordate?date=${dateEpoch}&businessAreaId=${businessAreaId}`;
    const response = await get(endPoint, 'getTimeSlotSimulation');
    if (response.isSuccess) {
        dispatch({
            type: calendarActions.TIME_SLOT_SIMULATION_RETRIEVED,
            payload: response.response.result
        });
    }
}

export const getEffectingRulesFor = (date, businessAreaId) => async dispatch => {
    const dateEpoch = utcToEpoch(date);
    const endPoint = `${apiUrl}/getcalendarrulesforday?date=${dateEpoch}&businessAreaId=${businessAreaId}`;
    const response = await get(endPoint, 'getTimeSlotSimulation');
    if (response.isSuccess) {
        dispatch({
            type: calendarActions.TIME_SLOT_EFFECTING_RULES_RETRIEVED,
            payload: response.response.result
        });
    }
}

export const clearRules = () => async dispatch => {
    dispatch({ type: calendarActions.CLEAR_RULES });
}
