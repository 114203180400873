const splitActionTypes = Object.freeze({
    SPLIT_GET_THUMBNAILS_SUCCESS: Symbol('SPLIT_GET_THUMBNAILS_SUCCESS'),
    SPLIT_APPROVE_SUCCESS: Symbol('SPLIT_APPROVE_SUCCESS'),
    SPLIT_DELETE_SPLIT_FILE_SUCCESS: Symbol('SPLIT_DELETE_SPLIT_FILE_SUCCESS'),
    SPLIT_REMOVE_UNSAVED_SPLIT: Symbol('SPLIT_REMOVE_UNSAVED_SPLIT'),
    DO_SPLIT: Symbol('DO_SPLIT'),
    SPLIT_GET_SPLIT_FILE_SUCCESS: Symbol('SPLIT_GET_SPLIT_FILE_SUCCESS'),
    CLEAR_STATE: Symbol('CLEAR_STATE')
});

export default splitActionTypes;