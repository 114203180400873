/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './UnitDetail.module.css';
import history from '../../core/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnitDetailShipmentRow = (props) => {

    const {
        shipment,
        shipmentFileList,
        docsMissingState,
        changeNoDocumentsState,
        changeDocsMissingState,
        isFileListFetched,
        setIsFileFetched
    } = props;

    const [hasNoDocument, setHasNoDoucment] = useState(false);
    const [hasMissingDocument, setHasMissingDocument] = useState(false);

    useEffect(() => {
        if (isFileListFetched) {
            checkNoDocumentsState(shipmentFileList, setHasNoDoucment, changeNoDocumentsState);
            checkMissingDocumentsState(shipmentFileList, docsMissingState, setHasMissingDocument, changeDocsMissingState);
            setIsFileFetched(true);
        }
    }, [isFileListFetched, docsMissingState])

    return (
        <div className='card'>
            <ul className='list-group list-group-flush'>
                <li onClick={() => routeToShipmentDetail(shipment)}
                    className={`list-group-item ${styles['unit-transport-shipment-item']}`}>
                    <div className='row'>
                        {renderShipmentInfo(shipment)}
                        <div className={`ml-auto text-right mt-2 mr-1 row`}>
                            {renderNoDocumentsWarning(hasNoDocument)}
                            {renderDocsMissingWarning(hasMissingDocument)}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

const checkNoDocumentsState = (shipmentFileList, setHasNoDoucment, changeNoDocumentsState) => {
    if (!shipmentFileList) { return; }
    const hasDocuments = shipmentFileList.length;
    if (!hasDocuments) {
        setHasNoDoucment(true);
        changeNoDocumentsState(true);
    }
}

const checkMissingDocumentsState = (shipmentFileList, docsMissingState, setHasMissingDocument, changeDocsMissingState) => {
    let showDocsMissingIcon = docsMissingState ? true : false;

    if (!showDocsMissingIcon) {
        showDocsMissingIcon = shipmentFileList && shipmentFileList.length === 1 && shipmentFileList[0].pageCount === 1;
    }

    if (showDocsMissingIcon) {
        setHasMissingDocument(true);
        changeDocsMissingState(true);
    }

}

const renderShipmentInfo = shipment => {

    if (!shipment) { return; }

    return (
        <div className='mr-auto'>
            <div className='row'> {shipment.carrierName} -{shipment.suffix}</div>
            <div className='row'>{shipment.id}</div>
            <div className='row'>
                <span className='mr-5'>THUs: {shipment.thuCount} </span>
                <span className='mr-5'>{shipment.grossWeight} KG</span>
                <span>{shipment.volume} M3 </span>
            </div>
        </div>
    )
}

const routeToShipmentDetail = (shipment) => {
    const path = '/shipment/' + shipment.referenceNumber + '/' + shipment.id;
    history.push(path);
}


const renderNoDocumentsWarning = hasNoDocuments => {

    if (!hasNoDocuments) { return; }

    return (
        <div>
            <a className='text-warning mr-3'>
                <FontAwesomeIcon title="Shipment Found with No Document" icon="exclamation-triangle" />
            </a>
        </div>
    )
}

const renderDocsMissingWarning = hasDocsMissing => {

    if (!hasDocsMissing) { return; }

    return (
        <div>
            <img src='/image/icon/missing-image-icon.jpg' title='Documents Missing' alt='' className={`${styles.missingDocumentIcon}`} />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {

    if (!state.shipment) { return {} }

    return {
        isFileListFetched: state.unit.fileList === null ? false : true,
        shipmentFileList: state.unit.fileList ? state.unit.fileList[ownProps.shipment.referenceNumber] : [],
        docsMissingState: state.unit.docsMissingStateList ? state.unit.docsMissingStateList[ownProps.shipment.referenceNumber] : null
    }

}

export default connect(mapStateToProps)(UnitDetailShipmentRow);