import additionalGateActions from '../type/additionalGate';

const initialState = {
    timeSlots: null,
    openAdditionalGateResult: null,
    businessAreas: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case additionalGateActions.TIME_SLOTS_RETRIEVED:
            return {
                ...state,
                timeSlots: action.payload
            }
        case additionalGateActions.ADDITIONAL_GATES_SUCCESSFULLY_OPENED:
            return {
                ...state,
                openAdditionalGateResult: true,
            }
        case additionalGateActions.CLEAR_STATE:
            return initialState;
        case additionalGateActions.BUSINESS_AREAS_RETRIEVED: {
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case additionalGateActions.TIME_SLOTS_WERE_CLEARED: {
            return {
                ...state,
                timeSlots: null
            }
        }
        default:
            return state;
    }
};