import React from 'react';
import { connect } from 'react-redux';
import { saveUser } from '../store/action/userActions';
import ChangePasswordForm from '../component/ChangePasswordForm/ChangePasswordForm';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import Page from '../component/Page/Page';

const ChangePassword = ({ match, saveUser }) => {
    const submit = values => {
        saveUser(values);
        history.goBack();
    }
    return <Page backLink='/user' backToWhere='Users'>
          <h4>
            Change Password
        </h4><hr className='mt-0' />
        <ChangePasswordForm onSubmit={submit} userName={match.params.username} />
        <LoadingIndicator id="addOrEditUser" />
    </Page>
}

export default connect(null, { saveUser })(ChangePassword);


