import axios from 'axios';
import authenticationInterceptorHandler from './interceptors/authentication.interceptor';
//import MockAdapter from 'axios-mock-adapter';

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL
});

//If 'data' is null, then Content-Type is removed automatically by AXIOS
httpClient.defaults.headers.post['Content-Type'] = 'application/json';

httpClient.interceptors.request.use(
    request => authenticationInterceptorHandler(request)
);

////Lazım olunca kullanabilmek adına bu satırları burada bırakıyorum.
// var mock = new MockAdapter(httpClient, { delayResponse: 250 });
// mock.onGet('xms-booking-api/api/booking/getbookingdetail?code=99-1909060000-2').reply(200, {
//     "result":
//     {
//         unitNumber: 'test',
//         name: 'ttt',
//         surname: 'tttttt',
//         email: 'ttt@ttt.com',
//         thu: 222,
//         carrier: 'ahmet',
//         isUnLoading: true,
//         phoneNumber: '333',
//         transportNumbers: '333'
//     }
//     , "success": true, "errors": []
// })
// mock.onPost('xms-booking-api/api/booking/updatebooking').reply(200, { "result": true, "success": true, "errors": [] })
//     .onAny().passThrough();

export default httpClient;