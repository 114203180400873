import {formButtonBehaviour, formInputTypes} from "../FormComponentTypes";

export const CreateBookingSchema = {
    SubmitButton: {
        Name: 'Continue',
        Behaviour: formButtonBehaviour.BUTTON_NONE
    },
    ColumnCountSettings: {
        columnCount: 2,
        isAdaptiveColumn: true
    },
    FormDesign: [
        {
            groupName: 'Hidden',
            ordering: 1,
            isTagVisible: false
        },
        {
            groupName: 'BasicsTop',
            ordering: 2,
            isTagVisible: false
        },
        {
            groupName: 'BasicsMiddle',
            ordering: 3,
            isTagVisible: false
        },
        {
            groupName: 'BasicsBottom',
            ordering: 4,
            isTagVisible: false
        },
        {
            groupName: 'Details',
            ordering: 5,
            isTagVisible: true
        },
        {
            groupName: 'Details1',
            ordering: 6,
            isTagVisible: false
        },
        {
            groupName: 'Details2',
            ordering: 7,
            isTagVisible: false
        },
        {
            groupName: 'Details3',
            ordering: 8,
            isTagVisible: false
        },
        {
            groupName: 'Notes',
            ordering: 10,
            isTagVisible: true
        }
    ],
    Fields: [
        {
            name: 'Business Area',
            id: 'businessArea',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'businessAreas',
            firstItemObject: 'businessAreasFirstItem',
            isRequired: true,
            ordering: 5,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Unit No',
            id: 'unitNo',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 10,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Name',
            id: 'name',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 15,
            formDesign: 'BasicsMiddle',
            validations: []
        },
        {
            name: 'Surname',
            id: 'surname',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 20,
            formDesign: 'BasicsMiddle',
            validations: []
        },
        {
            name: 'Email',
            id: 'email',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 25,
            formDesign: 'BasicsBottom',
            validations: []
        },
        {
            name: 'Phone Number',
            id: 'phoneNumber',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            ordering: 30,
            formDesign: 'BasicsBottom',
            validations: []
        },
        {
            name: 'Operation Type',
            id: 'operationType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'operationTypes',
            firstItemObject: 'typeFirstItemObject',
            isRequired: true,
            ordering: 31,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Unit Type',
            id: 'unitType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'unitTypes',
            firstItemObject: 'unitTypeFirstItemObject',
            isRequired: true,
            ordering: 35,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'THU',
            id: 'thuAmount',
            type: formInputTypes.NUMBER_INPUT,
            step: 1,
            minValue: 0,
            isRequired: true,
            ordering: 36,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Ref/Order Numbers/Transports',
            id: 'transportNumbers',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 40,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Contains Dangerous Goods',
            id: 'hasDangerousGoods',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            ordering: 50,
            formDesign: 'Details2',
            validations: []
        },
        {
            name: 'Customs Clearance Needed',
            id: 'hasCustoms',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            ordering: 55,
            formDesign: 'Details2',
            validations: []
        },
        {
            name: 'Carrier',
            id: 'carrier',
            type: formInputTypes.STRING_INPUT,
            authorizationWrapper: 'AdministratorHoc',
            isRequired: false,
            ordering: 56,
            formDesign: 'Details3',
            validations: []
        },
        {
            name: 'Admin Notes',
            id: 'administratorNote',
            type: formInputTypes.TEXTAREA_INPUT,
            authorizationWrapper: 'AdministratorHoc',
            rowCount: 5,
            ordering: 60,
            formDesign: 'Notes',
        },
        {
            name: 'Notes',
            id: 'userNote',
            type: formInputTypes.TEXTAREA_INPUT,
            rowCount: 5,
            ordering: 65,
            formDesign: 'Notes',
        }
    ]
};
