import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Col } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};

const checkName = value => value && value === 'new' ? `Suffix name cannot be "new".` : undefined;
const checkExistance = (suffixList, onUpdateMode) => value => value && suffixList && !onUpdateMode && suffixList.some(x => x.id === value) ? `Suffix already exists.` : undefined;
const required = value => value ? undefined : 'Required';
let checkExistanceOfSuffix = undefined;
let SuffixForm = props => {
    const { handleSubmit, countries, initialValues, suffixList } = props
    const onUpdateMode = initialValues ? true : false;
    useEffect(() => {
        checkExistanceOfSuffix = checkExistance(suffixList, onUpdateMode);
    }, [suffixList, onUpdateMode])

    return (<>
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Name</Form.Label>
                    <Field
                        readOnly={onUpdateMode}
                        name="suffixName"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Name"
                        validate={[required, checkName, checkExistanceOfSuffix]}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>City</Form.Label>
                    <Field
                        name="city"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="City"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Short Description</Form.Label>
                    <Field
                        name="shortDescription"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Description"
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Long Description</Form.Label>
                    <Field
                        name="longDescription"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Long Description"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Country</Form.Label>
                    <Field as="select" name="countryCode" component={ReduxFormControl}>
                        {countries && countries.map((country) => (
                            <option value={country.code} key={country.code}>
                                {country.code + ' - ' + country.name}
                            </option>
                        ))}
                    </Field>
                </Form.Group>
            </Form.Row >
            <Button type="submit" className="float-right">Save</Button>
        </Form >
    </>
    )
}

SuffixForm = reduxForm({
    form: 'insertOrUpdateSuffixForm', // a unique identifier for this form
})(SuffixForm);
SuffixForm = connect(
    state => ({
        initialValues: state.notification.suffixDetail // pull initial values from notification reducer
    }),
)(SuffixForm);

export default SuffixForm;
