import thuDetailTypes from '../type/thuDetailType';
import post from '../../core/httpClientSync';
import history from '../../core/history';

export const getThuDetails = (shipmentReferenceNumber, spinnerId = null) => async dispatch => {

    const url = 'xms-cargo-api/api/cargo/get-cargo';
    const getShipmentDetailWithOrderItemIdRequest = { shipmentId: shipmentReferenceNumber };

    const getShipmentDetailWithOrderItemIdReponse = await post(url, getShipmentDetailWithOrderItemIdRequest, spinnerId);

    if (getShipmentDetailWithOrderItemIdReponse.isSuccess) {
        dispatch({
            type: thuDetailTypes.ADD_THU_DETAIL_INFO_LIST,
            payload: getShipmentDetailWithOrderItemIdReponse.response.cargoList
        })
    }
}

export const deleteThuDetail = (shipmentReferenceNumber, thuDetailUid, spinnerId = null) => async dispatch => {

    const url = 'xms-cargo-api/api/cargo/delete-cargo';
    const deleteThuDetailRequest = {
        shipmentId: shipmentReferenceNumber,
        uid: thuDetailUid
    }

    const deleteThuDetailResponse = await post(url, deleteThuDetailRequest, spinnerId);
    if (deleteThuDetailResponse.isSuccess) {
        dispatch({
            type: thuDetailTypes.REMOVE_THU_DETAIL_INFO,
            payload: thuDetailUid
        })
    }
}

export const createThuDetail = (thuDetailInfo, redirectInfo, spinnerId = null) => async dispatch => {

    const url = 'xms-cargo-api/api/cargo/create-cargo';
    const createThuDetailRequest = { ...thuDetailInfo };

    const createThuDrtailResponse = await post(url, createThuDetailRequest, spinnerId);

    if (createThuDrtailResponse.isSuccess) {
        dispatch({
            type: thuDetailTypes.ADD_THU_DETAIL_INFO_ITEM,
            payload: createThuDrtailResponse.response.cargoDet
        })

        if (redirectInfo && redirectInfo.redirectToListPage) {
            history.push(`/shipment/${redirectInfo.shipmentReferenceNumber}/${redirectInfo.shipmentId}`);
        }
    }

}

export const clearThuDetailInfo = () => async dispatch => {
    dispatch({
        type: thuDetailTypes.CLEAR_THU_DETAIL_DATA
    })
}