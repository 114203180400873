import React, { useState, useEffect } from 'react';
import { ReactSelect, TextField } from '@dfds-ui/react-components';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import classes from './Filters.module.css';
import { MultiSelect } from '../BaseComponents/MultiSelect';

const Filters = (props) => {
    //Selected options
    const { selectedDate, selectedType, selectedStatus, selectedBusinessAreas, } = props;

    //Event handlers
    const { onTypeChanged, onTaskIdChanged, onDateChanged, onStatusChanged, onSelectedBusinessAreasChanged } = props;

    //Other
    const { businessAreas } = props;

    const [businessAreasForMultiSelect, setBusinessAreasForMultiSelect] = useState([]);

    const statusOptions = [
        { value: '', label: 'Select Status' },
        { value: 'Initiated', label: 'Initiated' },
        { value: 'Assigned', label: 'Assigned' },
        { value: 'InProgress', label: 'InProgress' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Cancelled', label: 'Cancelled' },
    ]

    const options = [
        { value: '', label: 'Select Type' },
        { value: 'Loading', label: 'Loading' },
        { value: 'Unloading', label: 'Unloading' },
        { value: 'Putaway', label: 'Put away' },
    ]

    const onDatePickerChangedHandler = value => {
        onDateChanged(value);
    }

    useEffect(() => {
        if (businessAreas) {
            setBusinessAreasForMultiSelect(businessAreas.map(i => { return { label: i.text, value: i.value } }));
        }
    }, [businessAreas]);

    return <Row>
        <Col lg='6' md='6'>
            <MultiSelect
                disableSearch={true}
                placeholder='Select Business Area'
                options={businessAreasForMultiSelect}
                selected={selectedBusinessAreas}
                onSelectedChanged={onSelectedBusinessAreasChanged}
            />
        </Col>
        <Col lg='6' md='6'>
            <DatePicker
                placeholderText='Select Date'
                selected={selectedDate}
                type='text'
                className={[classes['operation-date-picker'], 'form-control'].join(' ')}
                format="MM/DD/YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={onDatePickerChangedHandler}
            />
        </Col>
        <Col lg='6' md='6'>
            <TextField name='taskId' placeholder='Enter Task ID' className='mb-0' onBlur={e => onTaskIdChanged(e)} />
        </Col>
        <Col lg='3' md='3'>
            <ReactSelect name='status' placeholder='Select Status' options={statusOptions} className='mt-1' value={selectedStatus} onChange={selectedStatus => onStatusChanged(selectedStatus)} />
        </Col>
        <Col lg='3' md='3'>
            <ReactSelect name='type' placeholder='Select Type' options={options} className='mt-1' value={selectedType} onChange={selectedOption => onTypeChanged(selectedOption)} />
        </Col>
    </Row>
}

export default Filters;