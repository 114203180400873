import imageControllerTypes from '../type/imageControllerTypes';
import _ from 'lodash';

const initialState = {
    imageList: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case imageControllerTypes.ADD_IMAGE_TO_IMAGE_LIST:
            return {
                ...state,
                imageList: {
                    ...state.imageList,
                    [action.payload.uid]: action.payload
                }
            }
        case imageControllerTypes.REMOVE_IMAGE_FROM_IMAGE_LIST:
            return {
                ...state,
                imageList: _.omit({ ...state.imageList }, [action.payload.uid]),
            }
        case imageControllerTypes.UPDATE_IMAGE_INFO:
            const imageToUpdate = state.imageList[action.payload.oldUid];
            imageToUpdate.uid = action.payload.newUid;
            imageToUpdate.status = action.payload.status;
            const tempImageList = _.omit({ ...state.imageList }, [action.payload.oldUid]);

            return {
                ...state,
                imageList: {
                    ...tempImageList,
                    [imageToUpdate.uid]: imageToUpdate
                }
            }
        case imageControllerTypes.UPDATE_IMAGE_COMMENT:
            const imageBeforeCommentUpdate = state.imageList[action.payload.uid];
            imageBeforeCommentUpdate.comment = action.payload.comment;
            return {
                ...state,
                imageList: {
                    ...state.imageList,
                    [imageBeforeCommentUpdate.uid]: imageBeforeCommentUpdate
                }
            }
        case imageControllerTypes.CLEAR_IMAGE_VALUES:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}