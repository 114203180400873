import React, {useContext} from 'react';
import {checkForAuthorization} from "../../core/authentication";
import AppContext from "../../context/AppContext";
import AuthorizeContext from "../../context/AuthorizeContext";

const PolicyGroupAccessHoc = ({componentName, children}) => {

    const appContext = useContext(AppContext);
    const authContext = useContext(AuthorizeContext);

    if(checkForAuthorization(componentName, appContext, authContext, false)) {
        return children;
    } else {
        return(
            <>
            </>
        );
    }

};


export default PolicyGroupAccessHoc;