import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from '../Split/Split.module.css';
import { connect } from 'react-redux';
import { getThumbnails } from '../../store/action/splitActions';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const SplitRow = ({
    getThumbnails,
    thumbnails,
    split = { key: '', startPage: -1, endPage: -1, isFound: false, existingDocumentUid: null },
    splitFilePath,
    deleteSplitClicked }) => {

    //[{ index: 0, bytes: null, isInPreview: false }]
    const [thumbnailsVM, setThumbnailsVM] = useState(null);

    const splitClickedHandler = () => {

        if (split.isFound && (!thumbnailsVM || thumbnails.length === 0)) {
            getThumbnails(splitFilePath, split.key, split.startPage, split.endPage, 'loading_' + split.key);
        }
    }

    const thumbnailClickedHandler = (thumbnailIndex) => {
        const clickedThumbnail = thumbnailsVM[thumbnailIndex];
        const oldValue = clickedThumbnail.isInPreview;

        const newThumbnailSet = [...thumbnailsVM];
        newThumbnailSet.forEach(t => t.isInPreview = false);
        clickedThumbnail.isInPreview = !oldValue;
        setThumbnailsVM(newThumbnailSet);
    }

    useEffect(() => {
        if (thumbnails) {
            setThumbnailsVM(thumbnails.map((thumbnailBytes, index) => ({
                index: index,
                bytes: thumbnailBytes,
                isInPreview: false
            })))
        }
    }, [thumbnails]);


    const splitCardHeaderClasses = [classes.splitCardHeader];
    if (!split.isFound) {
        splitCardHeaderClasses.push('bg-warning');
    } else if (split.existingDocumentUid !== null) {
        splitCardHeaderClasses.push('bg-success')
        splitCardHeaderClasses.push('text-white');
    }
    return (
        <div>
            <Card className={classes.splitCard}>
                <LoadingIndicator id={'loading_' + split.key} />
                <Card.Header
                    onClick={splitClickedHandler}
                    className={splitCardHeaderClasses.join(' ')}
                    data-target={'#collapseSplit_' + split.key}
                    data-toggle="collapse"
                    data-parent="#splitAccordion" >
                    <Row>
                        <Col>
                            <span>{split.key}</span>
                        </Col>
                        <Col>
                            {(split.isFound) ?
                                <span>{split.startPage} to {split.endPage} ({split.endPage - split.startPage + 1} Pages)</span> : null
                            }
                            <Button
                                onClick={deleteSplitClicked}
                                variant={"btn-no-red"}
                                className="float-right background-color-no-red text-white">
                                <FontAwesomeIcon icon="trash" />
                            </Button>
                        </Col>
                    </Row>
                </Card.Header>
                <div id={'collapseSplit_' + split.key} className="collapse">
                    <Card.Body>
                        <div className={classes.thumbnails}>
                            {thumbnailsVM ? thumbnailsVM.map((thumbnail, index) =>
                                <img
                                    key={index}
                                    alt={index}
                                    className={thumbnail.isInPreview ? classes.preview : null}
                                    src={'data:image/jpeg;base64,' + thumbnail.bytes}
                                    width="120px"
                                    onClick={(i) => thumbnailClickedHandler(index)} />)
                                : null}
                        </div>
                    </Card.Body>
                </div>
            </Card>
        </div>
    );
};


const mapStateToProps = (state, ownProps) => {
    const thumbListOfCurrentSplit = state.split.thumbnailList.find(x => x.key === ownProps.split.key);
    if (thumbListOfCurrentSplit) {
        return {
            thumbnails: thumbListOfCurrentSplit.thumbs
        }
    }
    return {
        thumbnails: null
    }

}
export default connect(mapStateToProps, { getThumbnails })(SplitRow);