import MultiSelectComponent from "@khanacademy/react-multi-select";
import * as PropTypes from "prop-types";
import { Checkbox } from '@dfds-ui/react-components'

import React from "react";


export const MultiSelect = ({ placeholder, options, selected, onSelectedChanged, disableSearch }) => {
    const itemRenderer = ({ option, checked, onClick }) => {
        return <><Checkbox checked={checked} onClick={(e) => onClick(e)}>{option.label}</Checkbox></>
    }
    return <>
        <MultiSelectComponent
            overrideStrings={{
                selectSomeItems: placeholder,
            }}
            ItemRenderer={itemRenderer}
            disableSearch={disableSearch}
            options={options}
            selected={selected}
            onSelectedChanged={(selected) => onSelectedChanged(selected)}
        />

    </>
};


MultiSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })),
    // selected: PropTypes.arrayOf(PropTypes.shape({
    //     label: PropTypes.string.isRequired,
    //     value: PropTypes.string.isRequired
    // }))
};