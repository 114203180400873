import React from "react";
import { Redirect } from "react-router-dom";
import {isForkliftUser, isOnlyExternalUser} from "../../core/authentication";

// Dashboard ihtiyacı olduğunda kullanabiliriz.
const HomePage = () => {
  if(isForkliftUser()) {
    return <Redirect to="/task" />;
  }
  else if (isOnlyExternalUser()) {
    return <Redirect to="/booking/carrier-booking-list/" />;
  } else {
    return <Redirect to="/unit" />;
  }
};

export default HomePage;
