import companyTypes from '../type/company';

const initialState = {
    company: null,
    companyList: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case companyTypes.COMPANY_GETCOMPANIES_SUCCESS:
            return {
                ...state,
                companyList: action.payload
            }
        case companyTypes.COMPANY_GETCOMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload
            }
        case companyTypes.COMPANY_CLEARCOMPANYSTATE_SUCCESS:
            return { ...initialState };
        default:
            return state;
    }

}