/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUser, clearSelectedUser, getRegions, getRoles, saveUser } from '../store/action/userActions';
import { getCompanyList } from '../store/action/companyActions';
import history from '../core/history';

import UserForm from '../component/UserForm/UserForm';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import Page from '../component/Page/Page';

const AddOrEditUser = ({ match, user, userList, getUser, getRegions, getRoles, getCompanyList, clearSelectedUser, saveUser, regions, roles, companies }) => {
    useEffect(() => {
        if (!userList) {
            history.push('/user');
        }

        if (!match.params.username || match.params.username === 'new') {
            return;
        }

        getUser(userList, match.params.username);


        return () => {
            clearSelectedUser();
        }
    }, [userList, getUser]);

    useEffect(() => {
        if (!regions) {
            getRegions();
        }
    }, [regions, getRegions]);

    useEffect(() => {
        if (!roles) {
            getRoles();
        }
    }, [roles, getRoles]);

    useEffect(() => {
        if (!companies) {
            getCompanyList();
        }
    }, [companies, getCompanyList]);

    const submit = (userModel) => {
        saveUser(userModel);
        history.goBack();
    }
    return <Page backLink='/user/' backToWhere='Users'>
        <h4>{user ? [user.surname, user.name].join(' ') : 'New User'}</h4><hr className='mt-0' />
        <UserForm onSubmit={submit} user={user} regions={regions} roles={roles} companies={companies} />
        <LoadingIndicator id="addOrEditUser" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        userList: state.user.userListResponse ? state.user.userListResponse.items : null,
        regions: state.user.regions,
        roles: state.user.roles,
        companies: state.company.companyList
    }
}
export default connect(mapStateToProps, { getUser, clearSelectedUser, getRegions, saveUser, getRoles, getCompanyList })(AddOrEditUser);
