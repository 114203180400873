const userActions = Object.freeze({
    USER_SAVEUSER_SUCCESS: Symbol('USER_SAVEUSER_SUCCESS'),
    USER_GETUSER_SUCCESS: Symbol('USER_GETUSER_SUCCESS'),
    USER_GETUSERS_SUCCESS: Symbol('USER_GETUSERS_SUCCESS'),
    USER_CLEARSELECTEDUSER_SUCCESS: Symbol('USER_CLEARSELECTEDUSER_SUCCESS'),
    USER_GETCOMPANIES_SUCCESS: Symbol('USER_GETCOMPANIES_SUCCESS'),
    USER_GETREGIONS_SUCCESS: Symbol('USER_GETREGIONS_SUCCESS'),
    USER_GETROLES_SUCCESS: Symbol('USER_GETROLES_SUCCESS'),
});

export default userActions;