import types from '../type/authentication';

const initialState = {
    isAuthenticated: false,
    authenticatedUser: null,
    authenticationError: null,
    isUserFetched: null,
    accessToken: null
}

export default (state = initialState, action) => {

    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                authenticatedUser: action.payload,
                authenticationError: null,
                isUserFetched: true
            }
        case types.RESTORE_USER:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                authenticatedUser: action.payload.authenticatedUser,
                accessToken: action.payload.accessToken,
                authenticationError: null,
                isUserFetched: true
            }
        case types.LOG_OUT:
            return {
                ...state,
                isAuthenticated: false,
                authenticatedUser: null,
                authenticationError: null,
                isUserFetched: true
            }
        default:
            return state;
    }

}