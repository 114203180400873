const transportActionTypes = Object.freeze({
    TRANSPORT_GET_UNIT_DETAIL: Symbol('GET_UNIT_DETAIL'),
    TRANSPORT_GET_UNIT_DETAIL_ERROR: Symbol('GET_UNIT_DETAIL_ERROR'),
    TRANSPORT_GET_UNIT_DETAIL_INIT: Symbol('GET_UNIT_DETAIL_INIT'),

    TRANSPORT_GET_SHIPMENT_DETAIL: Symbol('GET_SHIPMENT_DETAIL'),
    TRANSPORT_GET_SHIPMENT_DETAIL_ERROR: Symbol('GET_SHIPMENT_DETAIL_ERROR'),
    TRANSPORT_GET_SHIPMENT_DETAIL_INIT: Symbol('GET_SHIPMENT_DETAIL_INIT'),
});

export default transportActionTypes;