import React from 'react';
import { connect } from 'react-redux';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import { Field, reduxForm } from 'redux-form';
import { Form, Col } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};

const ReduxFormCheck = ({ input, meta, ...props }) => {
    return <Form.Check {...props} {...input} />
};

const checkName = value => value && value === 'new' ? `Username cannot be "new".` : undefined;
const required = value => value ? undefined : 'Required';

let UserForm = props => {
    const { handleSubmit, regions, roles, companies, initialValues, roleError } = props
    const onUpdateMode = initialValues ? true : false;
    return (<>
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="4">
                    <Form.Label>User name</Form.Label>
                    <Field
                        name="username"
                        disabled={onUpdateMode}
                        component={ReduxFormControl}
                        type="text"
                        placeholder="User name"
                        validate={[required, checkName]}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Name</Form.Label>
                    <Field
                        name="name"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Name"
                    />
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Surname</Form.Label>
                    <Field
                        name="surname"
                        required
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Surname"
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="4">
                    <Form.Label>E-mail Address</Form.Label>
                    <Field
                        name="email"
                        required
                        component={ReduxFormControl}
                        type="email"
                        placeholder="E-mail Address"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid e-mail address.
              </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="4">
                    <Form.Label>Company</Form.Label>
                    <Field as="select" name="companyCode" component={ReduxFormControl} required>
                        <option value="">Choose...</option>
                        <option selected>DFDS</option>
                        {companies && companies.map((company) => (
                            <option value={company.code} key={company.code}>
                                {company.name}
                            </option>
                        ))}
                    </Field>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Region</Form.Label>
                    <Field as="select" name="region" component={ReduxFormControl} required>
                        <option value="" selected>Choose...</option>
                        {regions && regions.map((region) => (
                            <option value={region.code} key={region.code}>
                                {region.name}
                            </option>
                        ))}
                    </Field>
                </Form.Group>
            </Form.Row>
            {initialValues ? <Field
                name="password"
                hidden
                component="input"
                type="password"
            /> : <>
                    <Form.Row>
                        <Form.Group as={Col} md="4">
                            <Form.Label>Password</Form.Label>
                            <Field
                                name="password"
                                required
                                component={ReduxFormControl}
                                type="password"
                                placeholder="Password"
                            /></Form.Group>
                    </Form.Row>
                </>}
            <Form.Row>
                {initialValues ? <Form.Group as={Col} md="4">
                    <Form.Label>Status</Form.Label>
                    <Field
                        type="radio"
                        name="status"
                        component={ReduxFormCheck}
                        value="1"
                        label="Active"
                    />
                    <Field
                        type="radio"
                        name="status"
                        component={ReduxFormCheck}
                        value="0"
                        label="Passive"
                    />
                </Form.Group> : null}


                <Form.Group as={Col} md="4">
                    <Form.Label>Roles</Form.Label >
                    {roles ?
                        <Field name="roles" validate={[required]} options={roles.map((role, i) => ({ id: i, name: role.role }))} component={CheckboxGroup} /> : null}
                    {roleError ? <span className="text-danger">{roleError}</span> : null}
                </Form.Group >
            </Form.Row >
            <Form.Row>

            </Form.Row>
            <Button type="submit" className="float-right">Save</Button>
        </Form >
    </>
    )
}
const validate = values => {
    const errors = {}
    if (!values.roles || values.roles.length === 0) {
        errors.roles = 'User must have at least one role.';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
        initialValues: state.user.user, // pull initial values from user reducer
        roleError: state.form && state.form.insertOrUpdateUserForm && state.form.insertOrUpdateUserForm.syncErrors ? state.form.insertOrUpdateUserForm.syncErrors.roles : null,
    }
}

UserForm = reduxForm({
    form: 'insertOrUpdateUserForm', // a unique identifier for this form
    validate
})(UserForm);
UserForm = connect(mapStateToProps,
)(UserForm);

export default UserForm;
