import types from '../type/modal';

const initialState = {
    showModal: false,
    modalType: null,
    modalData: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                modalType: action.payload.modalType,
                modalData: action.payload.modalData
            }
        case types.HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                modalType: null,
                modalData: null
            }
        default:
            return state;

    }

}