import {formButtonBehaviour, formInputTypes} from "../FormComponentTypes";

export const CreateOrUpdateServiceSchema = {
    SubmitButton: {
        Name: 'Save',
        Behaviour: formButtonBehaviour.BUTTON_NONE
    },
    
    ColumnCountSettings: {
        columnCount: 2,
    },
    FormDesign: [
        {
            groupName: 'Hidden',
            ordering: 1,
            isTagVisible: false
        },
        {
            groupName: 'Default',
            ordering: 2,
            isTagVisible: false
        }
    ],
    Fields: [
        {
            name: '',
            id: 'id',
            type: formInputTypes.HIDDEN_INPUT,
            ordering: 1,
            formDesign: 'Hidden'
        },
        {
            name: 'Service Code',
            id: 'code',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 2,
            formDesign: 'Default',
            validations: []
        },
        {
            name: 'Name',
            id: 'name',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 3,
            formDesign: 'Default',
            validations: []
        },
        {
            name: 'Description',
            id: 'description',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 4,
            formDesign: 'Default',
            validations: []
        },
        {
            name: 'Type',
            id: 'operationType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'operationTypes',
            firstItemObject: 'typeFirstItemObject',
            isRequired: true,
            ordering: 5,
            formDesign: 'Default',
            validations: []
        }
    ]
};