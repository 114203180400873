import post, { get } from '../../core/httpClientSync';
import actionTypes from '../type/task';
import { toast } from 'react-toastify';
import modalActionTypes from '../type/modal';
import { utcToEpoch } from '../../core/utcToEpochConverter';
import moment from 'moment';
import { toastSuccess } from '.';
import DownloadFile from '../../core/downloadFile';
import { utcToEpochWithTime } from '../../core/utcToEpochConverter';
const apiUrl = 'xms-workflow-api/api/operation';
//const apiUrl = 'http://localhost:5000/api/operation';

const states = new Map();
states.set(actionTypes.TASK_COMPLETED, `${apiUrl}/complete`);
states.set(actionTypes.TASK_ACCEPTED, `${apiUrl}/accept`);
states.set(actionTypes.TASK_REJECTED, `${apiUrl}/reject`);
states.set(actionTypes.TASK_CLAIMED, `${apiUrl}/claim`);
states.set(actionTypes.TASK_ASSIGNED, `${apiUrl}/assign`);
states.set(actionTypes.TASK_UPDATED, `${apiUrl}/update`);
states.set(actionTypes.DETAIL_PUT, `${apiUrl}/put-detail`);
states.set(actionTypes.TASK_CANCELLED, `${apiUrl}/cancel`);
states.set(actionTypes.TASK_RECEIVED_DETAILS, `${apiUrl}/get-detail`);
states.set(actionTypes.UNIT_TYPE_RECEIVED, 'xms-slotbooking-api/api/booking/getunittypes');
states.set(actionTypes.BUSINESS_AREAS_RECEIVED, 'xms-slotbooking-api/api/businessarea/getall');



export const downloadKpiReport = () => async dispatch => {
    const endPoint = `${process.env.REACT_APP_BASE_API_URL}/xms-report-api/api/report/getexcelreport`;
    DownloadFile(endPoint, null, 'kpi_report.xlsx', 'GET', null, null, 'tasks');
}

export const putDetail = (taskId, detailObject, successMessage) => async dispatch => {
    const endPoint = `${apiUrl}/put-detail`;
    const request = {
        OperationId: taskId,
        Details: detailObject
    };

    const response = await post(endPoint, request, 'tasks');
    if (response.isSuccess) {
        toastSuccess(successMessage);
        dispatch({ type: actionTypes.DETAIL_PUT });
    }
}

export const reopenTask = (taskId, reopenUserId, reopenUserNameSurname) => async dispatch => {

    const endPoint = `${apiUrl}/reopen`;

    const response = await post(endPoint, {
        operationId: taskId,
        userId: reopenUserId,
        userNameSurname: reopenUserNameSurname
    }, 'tasks');

    if (response.isSuccess) {
        dispatch({ type: actionTypes.TASKS_CLEARED });
        toastSuccess('The task has reopened.');
    }
}


export const assign = (taskId, assignedUserId, assignedUserNameSurname) => async dispatch => {
    const endPoint = `${apiUrl}/assign`;

    const response = await post(endPoint, {
        operationId: taskId,
        userId: assignedUserId,
        userNameSurname: assignedUserNameSurname
    }, 'tasks');

    if (response.isSuccess) {
        dispatch({ type: actionTypes.TASKS_CLEARED });
        toastSuccess('Task state has been updated.');
    }
};

export const cancel = (taskId, performerUserId) => async dispatch => {
    //TODO: Change with confirm modal when anatolia push it to master
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Task will be cancelled. Confirm to continue.')) {
        const endPoint = `${apiUrl}/cancel`;

        const response = await post(endPoint, {
            id: taskId,
            performerId: performerUserId
        }, 'tasks');

        if (response.isSuccess) {
            dispatch({ type: actionTypes.TASKS_CLEARED });
            toastSuccess('Task has been cancelled.');
        }
    }

}

export const setTaskStateTo = (state, taskId) => async dispatch => {
    const endPoint = states.get(state);

    const response = await post(endPoint, { id: taskId }, 'tasks');
    if (response.isSuccess) {
        dispatch({ type: actionTypes.TASKS_CLEARED });
        toastSuccess('Task state has been updated.');
    }
}

export const filterTasks = (tasks, type) => async dispatch => {
    let filtered = tasks;
    if (type && type !== '') {
        filtered = tasks.filter(x => x.type === type)
    }

    dispatch({
        type: actionTypes.TASKS_FILTERED,
        payload: filtered
    })
}

export const getTasks = (businessAreas, quickFilter, taskId, date, status, userId, selectedBusinessAreas) => async dispatch => {

    if(taskId) {
        const endPoint = states.get(actionTypes.TASK_RECEIVED_DETAILS);
        const response = await post(endPoint, { taskId: taskId }, 'tasks');

        if(response.isSuccess) {
            dispatch({
                type: actionTypes.TASKS_RECEIVED,
                payload: [response.response].map(t => ({ ...t, businessAreaName: businessAreas.find(x => x.value === t.businessAreaId).text }))
            })
        }

        return;
    }

    let endPoint = `${apiUrl}/getAll`;

    const getAllRequest = {
        QuickFilter: quickFilter,
        OperationId: taskId,
        OperationDateTime: date ? utcToEpoch(date) : 0,
        Status: status,
        UserId: userId,
        businessAreaIds: selectedBusinessAreas
    }

    const response = await post(endPoint, getAllRequest, 'tasks');
    if (response.isSuccess) {
        dispatch({
            type: actionTypes.TASKS_RECEIVED,
            payload: response.response.map(t => ({ ...t, businessAreaName: businessAreas.find(x => x.value === t.businessAreaId).text }))
        })
    }
}

export const saveTask = (taskModel) => async dispatch => {
    const endPoint = `${apiUrl}/save`;

    const postTaskModel = {
        SubjectId: taskModel.subjectId,
        OperationDateTimeEpoch: taskModel.valueEpoch,
        OperationType: taskModel.operationType,
        ChannelType: taskModel.channelType,
        BusinessAreaId: taskModel.businessAreaId,
        Details: taskModel.Details
    };

    const response = await post(endPoint, postTaskModel, 'createOrUpdateTask');
    if (response.isSuccess) {
        toastSuccess('Task has been created.');
        dispatch({ type: actionTypes.TASK_CREATED });
    }
};

export const updateTask = (taskModel) => async dispatch => {
    const endPoint = states.get(actionTypes.TASK_UPDATED);

    const updateTaskModel = {
        OperationId: taskModel.operationId,
        SubjectId: taskModel.subjectId,
        OperationDateTimeEpoch: taskModel.valueEpoch,
        OperationType: taskModel.operationType,
        ChannelType: taskModel.channelType,
        BusinessAreaId: taskModel.businessAreaId,
        Details: taskModel.Details
    };

    const response = await post(endPoint, updateTaskModel, 'createOrUpdateTask');
    if (response.isSuccess) {
        toastSuccess('Task has been updated.');
        dispatch({ type: actionTypes.TASK_UPDATED });
    }
};

export const cancelTask = (taskId, performerUserId) => async dispatch => {
    const endPoint = states.get(actionTypes.TASK_CANCELLED);

    const response = await post(endPoint, {
        id: taskId,
        performerId: performerUserId
    }, 'createOrUpdateTask');

    if (response.isSuccess) {
        toastSuccess('Task has been cancelled.');
        dispatch({ type: actionTypes.TASK_CANCELLED });
    }
};

export const showEnterGateNumberToOperationModal = (assignGateClickedHander) => async dispatch => {
    dispatch({
        type: modalActionTypes.SHOW_MODAL,
        payload: {
            modalType: 'show-assign-gate-to-operation',
            modalData: { onAssignGateClickedHandler: assignGateClickedHander }
        }
    });
}

export const showUsersForAssignment = (users, userSelectedForAssignmentHandler) => async dispatch => {
    const endPoint = `xms-identity-api/api/user/users`;

    const getUserRequest = { token: null, pageSize: 100000 };

    if (!users) {
        var response = await post(endPoint, getUserRequest, 'tasks');
        if (response.isSuccess) {
            users = response.response.items;
            dispatch({
                type: actionTypes.USERS_RECEIVED,
                payload: users
            });
        }
    }

    dispatch({
        type: modalActionTypes.SHOW_MODAL,
        payload: {
            modalType: 'show-users-for-assignment',
            modalData: { users: users, onAssignClickHandler: userSelectedForAssignmentHandler }
        }
    });
}

export const getUnitTypes = () => async dispatch => {
    const endPoint = states.get(actionTypes.UNIT_TYPE_RECEIVED);
    const getUnitTypesResponse = await get(endPoint, 'createOrUpdateTask');

    const unitTypeItems = getUnitTypesResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getUnitTypesResponse.isSuccess) {
        dispatch({
            type: actionTypes.UNIT_TYPE_RECEIVED,
            payload: unitTypeItems
        });
    }
};

export const getBusinessAreas = () => async dispatch => {
    const endPoint = states.get(actionTypes.BUSINESS_AREAS_RECEIVED);
    const getBusinessAreasResponse = await get(endPoint, 'createOrUpdateTask');
    const businessAreasItems = getBusinessAreasResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: actionTypes.BUSINESS_AREAS_RECEIVED,
            payload: businessAreasItems
        });
    }
};

export const getDefaultBusinessAreas = (businessAreas) => {
    let selectedBusinessAreas = [];
    if (businessAreas) {
        selectedBusinessAreas = JSON.parse(localStorage.getItem('TASK:SelectedBusinessAreas'));
        if (!selectedBusinessAreas || selectedBusinessAreas.length === 0) {
            selectedBusinessAreas = [];
            const VolvoXDock = businessAreas.find(x => x.text === 'Volvo X-dock');
            if (VolvoXDock) {
                selectedBusinessAreas.push(VolvoXDock.value);
            } else {
                selectedBusinessAreas.push(businessAreas[0]);
            }
        }
    }

    localStorage.setItem('TASK:SelectedBusinessAreas', JSON.stringify(selectedBusinessAreas));
    return selectedBusinessAreas;
};

export const setDefaultBusinessAreas = (businessAreas) => {
    localStorage.setItem('TASK:SelectedBusinessAreas', JSON.stringify(businessAreas));
}

export const completeTask = (taskId) => async dispatch => {

    // if task state is completed and if UnitLeft detail property not defined, add UnitLeft into details.
    // it can be checked on taskItem but had to be sure latest value.
    const endPoint = `${apiUrl}/complete`;
    const getDetailEndPoint = states.get(actionTypes.TASK_RECEIVED_DETAILS);
    const getDetailResponse = await post(getDetailEndPoint, { taskId: taskId }, null);
    if (getDetailResponse.response.details['unitLeftDateTime'] == undefined) {
        const putDetailEndPoint = `${apiUrl}/put-detail`;
        const request = {
            OperationId: taskId,
            Details: { unitLeftDateTime: utcToEpochWithTime(new Date()) }
        };
        await post(putDetailEndPoint, request, 'tasks');
    }
    const response = await post(endPoint, { id: taskId }, 'tasks');
    if (response.isSuccess) {
        dispatch({ type: actionTypes.TASKS_CLEARED });
        toastSuccess('The task has completed.');
    }
}

export const getTaskItem = (taskId) => async dispatch => {

    if (taskId && taskId !== 'new') {
        const endPoint = states.get(actionTypes.TASK_RECEIVED_DETAILS);
        const response = await post(endPoint, { taskId: taskId }, 'createOrUpdateTask');
        if (response.isSuccess) {
            dispatch({
                type: actionTypes.TASK_ITEM_MODEL,
                payload: {
                    operationId: response.response.id,
                    businessAreaId: response.response.businessAreaId,
                    subjectId: response.response.subjectId,
                    operationDate: moment.unix(response.response.operationDateTime).utc().format('DD/MM/YYYY HH:mm'),
                    operationType: response.response.type,
                    unitType: response.response.details.unitType,
                    carrier: response.response.details.carrier,
                    rushRequest: response.response.details.rushRequest,
                    nonAdvised: response.response.details.nonAdvised,
                    adminNotes: response.response.details.adminNotes,
                    externalNotes: response.response.details.externalNotes
                }
            });
        }
        return;
    }

    dispatch({
        type: actionTypes.TASK_ITEM_MODEL,
        payload: {
            nonAdvised: 'false',
            rushRequest: 'false'
        }
    });
};

export const clearTasks = () => async dispatch => dispatch({ type: actionTypes.TASKS_CLEARED });
export const clearState = () => async dispatch => dispatch({ type: actionTypes.CLEAR_STATE });
