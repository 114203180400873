import thuDetailTypes from '../type/thuDetailType';
import _ from 'lodash';

const initialState = {
    thuDetailList: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case thuDetailTypes.ADD_THU_DETAIL_INFO_LIST:
            const currentThuDetailList = { ...state.thuDetailList };
            for (const thuDetail of action.payload) {
                currentThuDetailList[thuDetail.uid] = thuDetail;
            }
            return {
                ...state,
                thuDetailList: currentThuDetailList
            }
        case thuDetailTypes.ADD_THU_DETAIL_INFO_ITEM:
            return {
                ...state,
                thuDetailList: { ...state.thuDetailList, [action.payload.uid]: action.payload }
            }
        case thuDetailTypes.REMOVE_THU_DETAIL_INFO:
            return {
                ...state,
                thuDetailList: _.omit({ ...state.thuDetailList }, action.payload)
            }
        case thuDetailTypes.CLEAR_THU_DETAIL_DATA:
            return {
                ...initialState
            }
        default:
            return state;
    }
}