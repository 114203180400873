import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHeader from "../component/PageHeader/PageHeader";
import PolicyGroupAccessHoc from "./Routes/PolicyGroupAccessHoc";

const Booking = ({ authenticatedUser }) => {
  const renderAllBookingLink = () => {
    return (
      <Link to={"/booking/list"}>
        <Button variant={"gray"} style={{ width: "100%", height: "80px" }}>
          All Bookings
        </Button>
      </Link>
    );
  };
  const renderMyBookingLink = () => {
    return (
      <Link to={"/booking/carrier-booking-list"}>
        <Button variant={"gray"} style={{ width: "100%", height: "80px" }}>
          My Bookings
        </Button>
      </Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <PageHeader
            text={authenticatedUser.location}
            backButtonEnabled={false}
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center justify-content-sm-center   mt-3">
        <Col lg={4} sm={6} xs={12}>
          <Link to={"/booking/new"}>
            <Button variant={"gray"} style={{ width: "100%", height: "80px" }}>
              New Booking
            </Button>
          </Link>
          <hr />
          <PolicyGroupAccessHoc componentName="AllBookingHoc">
            {renderAllBookingLink()}
          </PolicyGroupAccessHoc>
          <PolicyGroupAccessHoc componentName="MyBookingHoc">
            {renderMyBookingLink()}
          </PolicyGroupAccessHoc>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticatedUser: state.auth.authenticatedUser,
  };
};
export default connect(mapStateToProps)(Booking);
