/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getThuDetails,
    clearThuDetailInfo,
    deleteThuDetail
} from '../../store/action/thuDetailAction';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThuDetailList = (props) => {

    //parent inputs
    const {
        shipmentReferenceNumber,
        allowDelete
    } = props;

    //redux elements
    const {
        thuDetailList,
        getThuDetails,
        clearThuDetailInfo,
        deleteThuDetail
    } = props;

    useEffect(() => {
        if (!thuDetailList) { return; }
        clearThuDetailInfo();
    }, []);

    useEffect(() => {
        if (thuDetailList) { return; }
        if (!shipmentReferenceNumber) { return; }

        getThuDetails(shipmentReferenceNumber, 'getThuDetails');
    }, [thuDetailList, shipmentReferenceNumber])

    const onDeleteThuDetail = (thuDetailUid) => {
        if (window.confirm('Thu Detail will be deleted. Are you sure?')) {
            deleteThuDetail(shipmentReferenceNumber, thuDetailUid, 'getThuDetails');
        }
    }

    return (
        <>
            <div className='table-responsive mt-1'>
                <table className='table'>
                    {renderTableHeader(allowDelete)}
                    <tbody>
                        {(renderThuDetailList(thuDetailList, onDeleteThuDetail, allowDelete))}
                    </tbody>
                </table>
            </div>
            <LoadingIndicator id='getThuDetails' />
        </>
    )
}

const renderTableHeader = (allowDelete) => {

    const deleteColumn = allowDelete ? <th scope='col'></th> : <th></th>;

    return (
        <thead>
            <tr>
                <th scope='col'>Serial Number</th>
                <th scope='col'>Supplier No</th>
                <th scope='col'>Advice Note</th>
                <th scope='col'>Batch No</th>
                <th scope='col'>Part Number</th>
                <th scope='col' className='d-none d-md-block'>Package Ref No</th>
                <th scope='col'>Quantity</th>
                {deleteColumn}
            </tr>
        </thead>
    )
}

const renderThuDetailList = (thuDetailList, onDeleteThuDetail, allowDelete) => {

    if (!thuDetailList) {
        return (
            <tr>
                <td colSpan='8' className='text-center'>
                    No Cargo registered for this shipment
                    </td>
            </tr>
        )
    }

    const thuDetailListAsArray = Object.values(thuDetailList);

    return thuDetailListAsArray.map(thuDetail => {
        return (
            <tr key={thuDetail.uid}>
                <td>{thuDetail.serialNo}</td>
                <td>{thuDetail.supplierNo}</td>
                <td>{thuDetail.adviceNote}</td>
                <td>{thuDetail.batchNo}</td>
                <td>{thuDetail.partNo}</td>
                <td className='d-none d-md-block'>{thuDetail.packageRefNo}</td>
                <td>{thuDetail.quantity}</td>
                {renderDeleteButton(allowDelete, onDeleteThuDetail, thuDetail.uid)}
            </tr>
        )
    })
}

const renderDeleteButton = (allowDelete, onDeleteThuDetail, thuDetailUid) => {
    if (!allowDelete) { return <td></td> }

    return (
        <td>
            <button className='btn btn-danger btn-sm' onClick={() => onDeleteThuDetail(thuDetailUid)}>
                <FontAwesomeIcon icon="trash" />
            </button>
        </td>
    )
}

const mapStateToProps = ({ thuDetails }) => {
    return {
        thuDetailList: thuDetails.thuDetailList
    }
}

const mapActionToProps = {
    getThuDetails,
    clearThuDetailInfo,
    deleteThuDetail
}

export default connect(mapStateToProps, mapActionToProps)(ThuDetailList);