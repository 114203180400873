import history from '../../core/history';
import roleActions from "../type/role";
import postSync from '../../core/httpClientSync';


export const getRoles = () => async dispatch => {
    const url = 'xms-authorize-api/api/role/roles';
    
    var response= await postSync(url,null,'getRoles');
    if(response.isSuccess){
        dispatch({
            type: roleActions.ROLE_GETROLES_SUCCESS,
            payload: response.response
        });
    }
}
export const getPolicies = () => async dispatch => {
    const url = 'xms-authorize-api/api/policy/policies';
    var result=await postSync(url,'getPolicies')
    if(result.isSuccess){
        dispatch({
            type: roleActions.ROLE_GETPOLICIES_SUCCESS,
            payload: result.response
        })
    }
}

export const createRole = (role, policies) => async dispatch => {
    const url = 'xms-authorize-api/api/role/create-role';
    const createRequest = {
        role: role,
        policies: policies
    };
    var response=await postSync(url,createRequest,'createRole');
    if(response.isSuccess){
        dispatch({
            type:roleActions.ROLE_CLEARSTATE_SUCCESS
        });
        history.push('/role');
    }
}

export const updateRole = (role, policies) => async dispatch => {
    const url = 'xms-authorize-api/api/role/update-role';
    const updateRequest = {
        role: role,
        policies: policies
    };
    var response=await postSync(url,updateRequest,'updateRole')
    if(response.isSuccess){
        dispatch({
            type:roleActions.ROLE_CLEARSTATE_SUCCESS
        });
        history.push('/role');

    }
}


export const clearRoleState = () => async dispatch => {
        dispatch({
        type: roleActions.ROLE_CLEARSTATE_SUCCESS
    })
}

export const getRole = (role) => async dispatch => {
    const url = 'xms-authorize-api/api/role/role';
    const getRoleRequest = {
        role: role
    };
    var response=await postSync(url,getRoleRequest);
    if(response.isSuccess){
        dispatch({
            type: roleActions.ROLE_GETROLE_SUCCESS,
            payload: response.response
        })
    }
}