/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCountries, getSuffix, createSuffix, updateSuffix, clearState, getSuffixList } from '../store/action/notificationActions';
import history from '../core/history';

import SuffixForm from '../component/SuffixForm/SuffixForm';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import Page from '../component/Page/Page';

const AddOrEditSuffix = ({ match, suffix, getSuffix, getSuffixList, createSuffix, updateSuffix, suffixList, countries, getCountries, clearState }) => {
    useEffect(() => {
        if (!suffixList) {
            getSuffixList();
        }

        if (!match.params.suffix || match.params.suffix === 'new') {
            return;
        }
        getSuffix(match.params.suffix);

        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (!countries) {
            getCountries();
        }
    }, [countries]);

    const submit = (suffixModel) => {
        if (!match.params.suffix || match.params.suffix === 'new') {
            createSuffix(suffixModel);
        } else {
            updateSuffix(suffixModel);
        }
        history.goBack();
    }


    return <Page backLink='/notification' backToWhere='Suffixes' >
        <h4>{suffix ? suffix.longDescription : 'New Suffix'}</h4><hr className='mt-0' />
        <SuffixForm onSubmit={submit} suffix={suffix} suffixList={suffixList} countries={countries} />
        <LoadingIndicator id="notificationGetSuffix" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        suffixList: state.notification.suffixList,
        suffix: state.notification.suffixDetail,
        countries: state.notification.countries
    }
}
export default connect(mapStateToProps, { getCountries, getSuffix, getSuffixList, createSuffix, updateSuffix, clearState })(AddOrEditSuffix);
