import types from '../type/transport';
import { updateObject } from '../../core/updateObject';

const initialState = {
    unit: null,
    unitError: null
};


export default (state = initialState, action) => {

    switch (action.type) {
        case types.TRANSPORT_GET_UNIT_DETAIL:
            return updateObject(state, { unit: action.payload.unit, unitError: null});
        case types.TRANSPORT_GET_UNIT_DETAIL_ERROR:
            return updateObject(state, { unit: null, unitError: action.payload});
        default:
            return state;
    }
};