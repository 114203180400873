import React from 'react';
import Badge from '../../BaseComponents/Badge';
import { epochToShortDateTime } from '../../../core/epochToDateConverter';

const variants = {
    Create: 'success',
    Update: 'warning',
    Delete: 'danger'
}

const EntityHeader = ({ diff }) => {
    const { action, timestamp, user, userId } = diff;
    return <><Badge style={{width:'55px'}} variant={variants[action]} className='p-2 mr-2'>{action}</Badge> at <strong> {epochToShortDateTime(timestamp, 'MMM D YYYY, HH:mm:ss', true)} </strong>  by <strong>{`${user} (${userId})`}</strong></>
}

export default EntityHeader;