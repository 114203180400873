/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Table, Row, Col } from 'react-bootstrap';
import { convertEpochToUtcTime } from '../../core/convertEpochToUtcTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { isOnlyExternalUser } from "../../core/authentication";
import { Button } from '@dfds-ui/react-components/button';
import { Search } from "@dfds-ui/icons";

import classes from './BookingsTable.module.css';
import { getBusinessAreas } from '../../store/action/calendarActions';
import { showInlineInformationModal, getUnitTypes } from '../../store/action/bookingActions';
import { connect } from "react-redux";
import { TextField } from '@dfds-ui/react-components';
import { MultiSelect } from '../BaseComponents/MultiSelect';

//Bu canMarkAsArrived ve canEditBooking sonradan permission yapısı ile bağlanacağı için üzerinde çok düşünmekdim.
const BookingsTable = ({ data, getBusinessAreas, businessAreas, getUnitTypes, unitTypes, showInlineInformationModal, canMarkAsArrived = false, canEditBooking = false, canSeeAdminNotes = false, markAsArrivedClickedEvent = null, editBookingClickedEvent = null, cancelBookingClickedHandler = null, markAsUnArrivedClickedEvent = null, arrivalReceiptHandler = null }) => {
    const [selectedBusinessAreas, setSelectedBusinessAreas] = useState([]);
    const [allBusinessAreas, setAllBusinessAreas] = useState([]);
    const [textFilter, setTextFilter] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }

        if (!unitTypes) {
            getUnitTypes();
        }
    }, []);

    useEffect(() => {
        //alert(businessAreas);
        if (businessAreas !== null) {
            const businessAreasTemp = businessAreas.map(item => {
                return {
                    label: item.name,
                    value: item.name
                }
            });

            setAllBusinessAreas(businessAreasTemp);
        }
    }, [businessAreas]);

    useEffect(() => {
        if (data) {
            setSelectedBusinessAreas([]);
            setFilteredData(data);
        }
    }, [data]);

    const showActions = () => {
        return canMarkAsArrived || canEditBooking;
    };
    const renderIsArrivedButton = (row) => {
        return canMarkAsArrived && !row.isArrived ?
            <Button variation="outlined" size="small" className="mr-2" title="Mark as arrived"
                onClick={() => markAsArrivedClickedEvent(row.bookingCode)} variant="transparent">
                <FontAwesomeIcon icon="calendar-check" />
            </Button> : null;
    };

    const renderReceiptButton = (row) => {
        return canMarkAsArrived && row.isArrived && markAsUnArrivedClickedEvent ?
            <Button variation="outlined" size="small" className="mr-2" title="Arrival Receipt"
                onClick={() => arrivalReceiptHandler(row.bookingCode)} variant="transparent">
                <FontAwesomeIcon icon="file-invoice" />
            </Button> : null;
    };

    const renderMarkAsUnArrivedButton = (row) => {
        return canMarkAsArrived && row.isArrived && markAsUnArrivedClickedEvent ?
            <Button variation="outlined" size="small" className="mr-2" title="Mark as unarrived"
                onClick={() => markAsUnArrivedClickedEvent(row.bookingCode)} variant="transparent">
                <FontAwesomeIcon icon="undo-alt" />
            </Button> : null;
    }
    const renderEditBookingButton = (row) => {
        return canEditBooking && !row.isArrived ?
            <Button variation="outlined" size="small" className="mr-2" title="Edit Booking"
                onClick={() => editBookingClickedEvent(row.bookingCode)} variant="transparent">
                <FontAwesomeIcon icon="edit" />
            </Button> : null;
    };

    const renderCancelBookingButton = (row) => {
        return canEditBooking && !row.isArrived && row.isCancellable ?
            <Button variation="outlined" size="small" title="Cancel Booking"
                onClick={() => cancelBookingClickedHandler(row.bookingCode)} variant="transparent">
                <FontAwesomeIcon icon="calendar-times" />
            </Button> : null;
    };

    const multiSelectResultHandler = (selectedItems) => {
        bookingFilterResultHandler(textFilter, selectedItems);
        setSelectedBusinessAreas(selectedItems);
    };

    const textResultHandler = (searchTerm) => {
        bookingFilterResultHandler(searchTerm, selectedBusinessAreas);
        setTextFilter(searchTerm);
    };

    const bookingFilterResultHandler = (searchTerm, selectedBusinessAreas) => {
        const loweredSearchTerm = searchTerm.trim().toLowerCase();

        if (!loweredSearchTerm && selectedBusinessAreas.length === 0) {
            setFilteredData(data);
            return;
        }

        if (!data || data.length === 0) {
            return;
        }

        const newFilteredBookings = [];
        for (const d of data) {
            const loweredBookingRow = lowerBookingItem(d);
            if (isTextFilterApplied(loweredSearchTerm, loweredBookingRow) && isBusinessAreaExistIn(selectedBusinessAreas, loweredBookingRow.BusinessArea)) {
                newFilteredBookings.push(d);
            }
        }

        setFilteredData(newFilteredBookings);
    };

    const isTextFilterApplied = (loweredSearchTerm, loweredBookingRow) => {
        if (!loweredSearchTerm) {
            return true;
        }

        if (loweredBookingRow.DateTimeEpoch.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.BookingCode.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.UnitNo.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.Name.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.Surname.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.Carrier.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.OperationType.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.ThuAmount.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.PhoneNumber.indexOf(loweredSearchTerm) > -1 ||
            loweredBookingRow.TransportNumbers.indexOf(loweredSearchTerm) > -1) {
            return true;
        }

        return false;
    };

    const isBusinessAreaExistIn = (selectedBusinessAreas, rowBusinessArea) => {
        if (!selectedBusinessAreas || selectedBusinessAreas.length === 0) {
            return true;
        }

        const existedBusinessArea = selectedBusinessAreas.find(e => e === rowBusinessArea);

        if (existedBusinessArea)
            return true;
        return false;
    };

    const lowerBookingItem = (bookingItem) => {
        const loweredBookingItem = {};

        loweredBookingItem.DateTimeEpoch = getLoweredValueOrEmptyStringOf(getUtcOf(bookingItem.dateTime));
        loweredBookingItem.BookingCode = getLoweredValueOrEmptyStringOf(bookingItem.bookingCode);
        loweredBookingItem.UnitNo = getLoweredValueOrEmptyStringOf(bookingItem.unitNo);
        loweredBookingItem.Name = getLoweredValueOrEmptyStringOf(bookingItem.name);
        loweredBookingItem.Surname = getLoweredValueOrEmptyStringOf(bookingItem.surname);
        loweredBookingItem.Carrier = getLoweredValueOrEmptyStringOf(bookingItem.carrier);
        loweredBookingItem.OperationType = getLoweredValueOrEmptyStringOf(bookingItem.operationType);
        loweredBookingItem.ThuAmount = getLoweredValueOrEmptyStringOf(bookingItem.thuAmount);
        loweredBookingItem.PhoneNumber = getLoweredValueOrEmptyStringOf(bookingItem.phoneNumber);
        loweredBookingItem.TransportNumbers = getLoweredValueOrEmptyStringOf(bookingItem.transportNumbers);
        loweredBookingItem.BusinessArea = getBusinessAreaName(bookingItem.businessAreaId);

        return loweredBookingItem;
    };

    const getLoweredValueOrEmptyStringOf = (bookingItemProperty) => {
        const loweredItem = '';

        if (!checkIfExistThe(bookingItemProperty)) {
            return loweredItem;
        }

        if (Array.isArray(bookingItemProperty)) {
            return bookingItemProperty[0].toLowerCase();
        }

        if (typeof bookingItemProperty !== 'string') {
            return bookingItemProperty.toString().toLowerCase();
        }

        return bookingItemProperty.toLowerCase();
    };

    const getUtcOf = (epochDate) => {
        return convertEpochToUtcTime(epochDate)
    };

    const checkIfExistThe = (bookingItemProp) => {
        if (Array.isArray(bookingItemProp)) {
            return !!(bookingItemProp && bookingItemProp[0])
        }
        return !!bookingItemProp;
    };

    const getBusinessAreaName = (businessAreaId) => {
        if (!businessAreas || businessAreas.length === 0)
            return '';
        const businessArea = businessAreas.find(e => e.id === businessAreaId);
        if (businessArea)
            return businessArea.name;

        return '';
    };

    const getUnitTypeName = (unitTypeId) => {
        if (!unitTypes || unitTypes.length === 0)
            return '';
        const unitType = unitTypes.find(e => e.id === unitTypeId);
        if (unitType)
            return unitType.name;

        return '';
    };

    const detail = (row) => {
        return (
            <div className={classes.divTable}>
                <div className={classes.divTableBody}>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Business Area:</div>
                        <div className={classes.divTableCellRight}>{getBusinessAreaName(row.businessAreaId)}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Name:</div>
                        <div className={classes.divTableCellRight}>{row.name}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Surname:</div>
                        <div className={classes.divTableCellRight}>{row.surname}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Email:</div>
                        <div className={classes.divTableCellRight}>{row.email}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Unit Type:</div>
                        <div className={classes.divTableCellRight}>{getUnitTypeName(row.unitTypeId)}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Thu Amount:</div>
                        <div className={classes.divTableCellRight}>{row.thuAmount}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Customs Needed:</div>
                        <div className={classes.divTableCellRight}>{row.hasCustoms ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Dangerous Goods:</div>
                        <div className={classes.divTableCellRight}>{row.hasDangerousGoods ? 'Yes' : 'No'}</div>
                    </div>
                    <div className={classes.divTableRow}>
                        <div className={classes.divTableCellLeft}>Notes:</div>
                        <div className={classes.divTableCellRight}>{row.userNote}</div>
                    </div>
                    {
                        canSeeAdminNotes ?
                            <div className={classes.divTableRow}>
                                <div className={classes.divTableCellLeft}>Admin Notes:</div>
                                <div className={classes.divTableCellRight}>{row.administratorNote}</div>
                            </div> : null
                    }
                </div>
            </div>
        );
    };

    const showRowInformation = (row) => {
        showInlineInformationModal(row.bookingCode, row, canSeeAdminNotes, detail);
    };

    const calculateNoRowSpan = () => {
        let baseColumnCount = 9;

        if (showActions()) {
            baseColumnCount++;
        }

        if (canSeeAdminNotes) {
            baseColumnCount++;
        }

        return baseColumnCount;
    };

    return (<>
        <LoadingIndicator id="bookingGetAll" show />
        <Row>
            <Col lg={4} md={5} sm={6}>
                <div className={classes.bookIt}>
                    <MultiSelect
                        placeholder="Select Business Area"
                        disableSearch={true}
                        options={allBusinessAreas}
                        selected={selectedBusinessAreas}
                        onSelectedChanged={(selected) => multiSelectResultHandler(selected)}
                    />
                </div>

            </Col>
            <Col lg={4} md={5} sm={6}>
                <div className={classes.searchText} style={{ width: '100%' }}>
                    <TextField icon={<Search />} type="text" placeholder="Enter Ref No / Carrier / Operation Type / ..." onChange={(e) => textResultHandler(e.target.value)} />
                </div>
            </Col>
        </Row>
        <Row>
            <Col style={{ overflowY: "hidden", paddingRight: "0px", minHeight: '300px' }}>
                <Table bordered>
                    <thead>
                        <tr>
                            <th style={{ whiteSpace: 'nowrap' }}>Business Area</th>
                            <th>Time</th>
                            <th>Ref No</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Unit Number</th>
                            <th>Carrier</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Operation Type</th>
                            <th>THU'S</th>
                            <th>Notes</th>
                            {canSeeAdminNotes ? <th style={{ whiteSpace: 'nowrap' }}>Admin Notes</th> : null}
                            <th>Transports</th>
                            {showActions() ? <th>Actions</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData && filteredData.length > 0 ? filteredData.map((row, i) => <tr key={i}
                            className={row.isArrived ? 'arrived-booking' : ''}>
                            <td style={{ whiteSpace: 'nowrap' }}>{getBusinessAreaName(row.businessAreaId)}</td>
                            <td>{convertEpochToUtcTime(row.dateTime)}</td>
                            <td style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}><a hef="#"
                                onClick={() => showRowInformation(row)}>{row.bookingCode}</a>
                            </td>
                            <td>{row.unitNo}</td>
                            <td>{row.carrier}</td>
                            <td>{row.operationType}</td>
                            <td>{row.thuAmount}</td>
                            <td>{row.userNote}</td>
                            {canSeeAdminNotes ? <td>{row.administratorNote}</td> : null}
                            <td>{row.transportNumbers ? row.transportNumbers[0] : null}</td>
                            {showActions() ? <td style={{ whiteSpace: 'nowrap' }}>
                                {renderIsArrivedButton(row)}
                                {renderMarkAsUnArrivedButton(row)}
                                {renderEditBookingButton(row)}
                                {renderCancelBookingButton(row)}
                                {/*{renderReceiptButton(row)}*/}
                            </td> : null}
                        </tr>)
                            : <tr>
                                <td colSpan={calculateNoRowSpan()} className="text-center"> There are not any bookings for selected
                                date
                    </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>

    </>)
};

const mapStateToProps = state => {
    return {
        businessAreas: state.calendar.businessAreas,
        unitTypes: state.booking.unitTypes
    }
};

export default connect(mapStateToProps, { getBusinessAreas, getUnitTypes, showInlineInformationModal })(BookingsTable);
