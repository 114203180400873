import bookingActions from '../type/booking';

const initialState = {
    schemaJson: null,
    formData: null,
    bookingResult: false,
    isRefreshData: false,
    selectedDate: null,
    selectedTime: null,
    bookings: null,
    carrierBookings: null,
    unitTypes: null,
    unitTypesForBooking:null,
    businessAreas: null,
    isRedirectionNeeded: null,
    taskDetail: null,
    availableDates: null,
    isBookingRetrieving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case bookingActions.BOOKING_GETREQUIREDATTR_SUCCESS:
            return {
                ...state,
                schemaJson: action.payload
            }
        case bookingActions.BOOKING_FILL_FORM_DATA_SUCCESS:
            return {
                ...state,
                formData: action.payload
            }
        case bookingActions.UPDATE_BOOKING:
            return {
                ...state,
                bookingResult: action.payload
            }
        case bookingActions.FILL_FORM_DATA_BY_ID:
            return {
                ...state,
                formData: action.payload
            }
        case bookingActions.BOOKING_CREATEBOOKING_SUCCESS:
            return {
                ...state,
                bookingResult: action.payload
            }
        case bookingActions.BOOKING_GETTIMESLOTS_SUCCESS:
            return {
                ...state,
                timeSlots: action.payload
            }
        case bookingActions.BOOKING_SELECTTIMESLOT_SUCCESS:
            return {
                ...state,
                selectedDate: action.payload.date,
                selectedTime: action.payload.time
            }
        case bookingActions.BOOKING_GETBOOKINGS_SUCCESS:
            return {
                ...state,
                bookings: action.payload,
                isRefreshData: false
            }
        case bookingActions.BOOKING_GETBOOKINGSFORCARRIER_SUCCESS:
            return {
                ...state,
                carrierBookings: action.payload
            }
        case bookingActions.BOOKING_CANCEL_BOOKING:
            return {
                ...state,
                bookings: state.bookings ? state.bookings.filter(x => x.bookingCode !== action.payload) : null,
                carrierBookings: state.carrierBookings? state.carrierBookings.filter(x => x.bookingCode !== action.payload) : null
            }
        case bookingActions.BOOKING_MARK_AS_ARRIVED_SUCCESS:
            const bookingsCloned = [...state.bookings];
            const arrivedBookingIndex = bookingsCloned.findIndex(x => x.bookingCode === action.payload);
            bookingsCloned[arrivedBookingIndex].isArrived = true;

            return {
                ...state,
                bookings: bookingsCloned
            }
        case bookingActions.UNIT_TYPE_RECEIVED: {
            return {
                ...state,
                unitTypes: action.payload
            }
        }
        case bookingActions.TIME_SLOT_NOT_AVAILABLE: {
            return {
                ...state,
                isRedirectionNeeded: true
            }
        }
        case bookingActions.BOOKING_MARKED_AS_UNARRIVED: {
            return {
                ...state,
                isRefreshData: true
            }
        }
        case bookingActions.BUSINESS_AREA_RECEIVED: {
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case bookingActions.UNIT_TYPES_FOR_BOOKING: {
            return {
                ...state,
                unitTypesForBooking: action.payload
            }
        }
        case bookingActions.TASK_DETAIL_RECEIVED: {
            return {
                ...state,
                taskDetail: action.payload
            }
        }
        case bookingActions.AVAILABLE_DATES_RECEIVED: {
            return {
                ...state,
                availableDates: action.payload
            }
        }
        case bookingActions.TIME_SLOTS_CLEARED: {
            return {
                ...state,
                timeSlots: null
            }
        }
        case bookingActions.BOOKING_RETRIEVING: {
            return {
                ...state,
                isBookingRetrieving: action.payload
            }
        }
        case bookingActions.BOOKING_CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
};
