import notificationActions from '../type/notification';

const initialState = {
    suffixList: null,
    suffixDetail: null,
    suffixEmails: null,
    countries: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case notificationActions.NOTIFICATION_GETSUFFIXLIST_SUCCESS:
            return {
                ...state,
                suffixList: action.payload
            };
        case notificationActions.NOTIFICATION_GETSUFFIX_SUCCESS:
            const suffixModel = {
                ...action.payload,
                id: action.payload.suffixName
            }
            return {
                ...state,
                suffixDetail: suffixModel
            }
        case notificationActions.NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS:
            return {
                ...state,
                suffixEmails: action.payload
            }
        case notificationActions.NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS:
            return {
                ...state,
                suffixEmails: action.payload
            }
        case notificationActions.NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS:
            return {
                ...state,
                suffixEmails: action.payload
            }
        case notificationActions.GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case notificationActions.CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}