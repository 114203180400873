/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Split from '../component/Split/Split';
import PageHeader from '../component/PageHeader/PageHeader';
import { searchUnit } from '../store/action/unitActions';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

const UnitSplitContainer = ({ match, unit, searchUnit }) => {
    useEffect(() => {
        if (!unit) {
            searchUnit(match.params.unitId, false);
        }
    }, []);
 
    const unitId = match.params.unitId;
    const splitFilePath = `Unit/${unitId}/FileToSplit`;
    return (
        <>
            <PageHeader text={`Upload documents to ${unitId}`} />
            {unit ? <Split
                shipments={unit.shipmentIdAndReferenceList}
                splitFilePath={splitFilePath}
                splitters={unit.shipmentIdAndReferenceList.map(e => e.shipmentId)} /> : <LoadingIndicator id="unitSplitContainer" show />}
        </>
    );
};
const mapStateToProp = state => {
    return {
        unit: state.unit.fetchedUnit
    }
}
export default connect(mapStateToProp, { searchUnit })(UnitSplitContainer);