const reservedSlotActions = Object.freeze({
    RULES_RETRIEVED: Symbol('RULES_RETRIEVED'),
    RULE_RETRIEVED: Symbol('RULE_RETRIEVED'),
    RULE_DELETED: Symbol('RULE_DELETED'),
    RULE_SAVED: Symbol('RULE_SAVED'),
    FINALIZE_ADD_OR_UPDATE: Symbol('FINALIZE_ADD_OR_UPDATE'),
    FORM_LOADED: Symbol('FORM_LOADED'),
    BUSINESS_AREAS_RETRIEVED: Symbol('BUSINESS_AREAS_RETRIEVED'),
    UNIT_TYPES_RETRIEVED: Symbol('UNIT_TYPES_RETRIEVED')
});

export default reservedSlotActions;