/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Counter } from '@dfds-ui/react-components';
import { createThuDetail } from '../../store/action/thuDetailAction';

const CreateThuDetail = (props) => {

    //redux elements
    const {
        thuDetailList,
        createThuDetail
    } = props;

    //parent elements
    const {
        shipmentReferenceNumber,
        shipmentId
    } = props;

    const [isTabletSelected, setIsTabletSelected] = useState(false);
    const [isRightHanded, setIsRighttHanded] = useState(true);
    const [waitTime, setWaitTime] = useState(1);

    const [inputValue, setInputValue] = useState('');
    const [inputValueElement, setInputValueElement] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [supplierNumber, setSupplierNumber] = useState('');
    const [adviceNote, setAdviceNote] = useState('');
    const [batchNumber, setBatchNumber] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [packageRefNumber, setPackageRefNumber] = useState('');
    const [quantity, setQuantity] = useState('');

    const [myTimeOut, setMyTimeOut] = useState();

    useEffect(() => {
        if (!isTabletSelected) {
            setIsRighttHanded(true);
        }
    }, [isTabletSelected])

    useEffect(() => {
        if (myTimeOut) { clearTimeout(myTimeOut) };

        const newTimeOut = setTimeout(() => {
            evaluateNewValue(
                inputValue,
                setInputValue,
                setSerialNumber,
                setSupplierNumber,
                setAdviceNote,
                setBatchNumber,
                setPartNumber,
                setPackageRefNumber,
                setQuantity
            );
        }, waitTime * 1000);

        setMyTimeOut(newTimeOut);
    }, [inputValue])

    useEffect(() => {
        if (inputValueElement) {
            inputValueElement.focus();
        }
    }, [waitTime])

    useEffect(() => {
        setInputValue('');
        setSerialNumber('');
        setSupplierNumber('');
        setAdviceNote('');
        setBatchNumber('');
        setPartNumber('');
        setPackageRefNumber('');
        setQuantity('');
    }, [thuDetailList])

    const onCreateAndContinue = () => { createThuDetailWithInputs(false); }

    const onCreateAndFinish = () => { createThuDetailWithInputs(true); }

    const createThuDetailWithInputs = (redirectToList) => {
        const newThuDetail = {
            shipmentId: shipmentReferenceNumber,
            partNo: partNumber,
            batchNo: batchNumber,
            serialNo: serialNumber,
            quantity: quantity,
            packageRefNo: packageRefNumber,
            supplierNo: supplierNumber,
            adviceNote: adviceNote
        }

        const redirectInfo = { redirectToListPage: false };

        if (redirectToList) {
            redirectInfo.redirectToListPage = true;
            redirectInfo.shipmentId = shipmentId;
            redirectInfo.shipmentReferenceNumber = shipmentReferenceNumber;
        }

        createThuDetail(newThuDetail, redirectInfo, 'getThuDetails');
    }

    const renderOptions = () => {
        return (
            <div className='col d-flex'>
                {renderDeviceOptions(isTabletSelected, setIsTabletSelected)}
                {renderWaitTime(waitTime, setWaitTime)}
                {renderHandOptions(isRightHanded, setIsRighttHanded, isTabletSelected)}
            </div>
        )
    }

    const renderValueFields = () => {
        return (
            <>
                {renderInputField(inputValue, setInputValue, setInputValueElement)}
                <div className="row">
                        {renderSelectedField('(S) Serial number', serialNumber)}
                        {renderSelectedField('(V) Supplier No', supplierNumber)}
                        {renderSelectedField('(N) Advice Note', adviceNote)}
                        {renderSelectedField('(H) Batch no', batchNumber)}
                        {renderSelectedField('(P) Part number', partNumber)}
                        {renderSelectedField('(B) Package ref no', packageRefNumber)}
                        {renderSelectedField('(Q) Quantity', quantity)}
                </div>
            </>
        )
    }

    const renderActionButtons = () => {
        return (
            <div className='row'>
                <div className="col">
                    {renderSaveAndClose(onCreateAndFinish)}
                    {renderSaveAndContinue(onCreateAndContinue)}
                </div>
            </div>
        )
    }

    return (
        <div className='card card-body'>
            {/* {renderOptions()} */}
            {renderValueFields()}
            {renderActionButtons()}
        </div>
    )
}

const renderDeviceOptions = (isTabletSelected, setIsTabletSelected) => {
    return (
        <div className='col-4 my-auto'>
            {renderSwitch(
                'isTabletSelected',
                isTabletSelected,
                setIsTabletSelected,
                'Computer',
                'Tablet'
            )}
        </div>
    )
}

const renderWaitTime = (waitTime, setWaitTime) => {
    return (
        <div className='col-4 mb-1 px-0'>
            <span className='mr-2'>Wait Time</span>
            <Counter
                currentVal={waitTime}
                minVal={1}
                maxVal={9}
                executeOnChange={e => setWaitTime(e)}
            />
        </div>
    )
}

const renderHandOptions = (isRightHanded, setIsRighttHanded, isTabletSelected) => {
    return (
        <div className='col-4 my-auto'>
            {renderSwitch(
                'isHandSelected',
                isRightHanded,
                setIsRighttHanded,
                'Left Handed',
                'Right Handed',
                !isTabletSelected
            )}
        </div>
    )
}

const renderInputField = (inputValue, setInputValue, setInputValueElement) => {
    return (
        <div className='row'>
            <div className="col col-md-6 mx-auto">
                <div className='form-group'>
                    <label>Input Value</label>
                    <input
                        ref={input => setInputValueElement(input)}
                        type='text'
                        className='form-control'
                        onKeyUp={e => setInputValue(e.target.value)}
                        onChange={e => setInputValue(e.target.value)}
                        value={inputValue}
                        autoFocus
                    ></input>
                </div>
            </div>
        </div>
    )
}

const renderSelectedField = (label, value) => {
    return (
        <div className='col-md-6'>
            <div className='form-group'>
                <label>{label}</label>
                <input type='text' className='form-control' disabled value={value}></input>
            </div>
        </div>
    )
}

const renderSwitch = (name, isChecked, setIsChecked, leftString, rightString, isDisabled) => {
    return (
        <>
            <div className='row'>
                <div>
                    <span>{leftString}</span>
                </div>
                <div>
                    <Switch
                        name={name}
                        onChange={e => setIsChecked(e.target.checked)}
                        checked={isChecked}
                        className='mx-1 mb-0'
                        disabled={isDisabled}
                    ></Switch>
                </div>
                <div>
                    <span>{rightString}</span>
                </div>
            </div>
        </>
    )
}

const evaluateNewValue = (
    newValue,
    setInputValue,
    setSerialNumber,
    setSupplierNumber,
    setAdviceNote,
    setBatchNumber,
    setPartNumber,
    setPackageRefNumber,
    setQuantity
) => {
    if (!newValue) { return; }

    const upperCaseValue = newValue.toUpperCase();

    const startString = upperCaseValue.slice(0, 1);
    switch (startString) {
        case 'S':
            setSerialNumber(upperCaseValue);
            setInputValue('');
            break;
        case 'V':
            setSupplierNumber(upperCaseValue);
            setInputValue('');
            break;
        case 'N':
            setAdviceNote(upperCaseValue);
            setInputValue('');
            break;
        case 'H':
            setBatchNumber(upperCaseValue);
            setInputValue('');
            break;
        case 'P':
            setPartNumber(upperCaseValue);
            setInputValue('');
            break;
        case 'B':
            setPackageRefNumber(upperCaseValue);
            setInputValue('');
            break;
        case 'Q':
            setQuantity(upperCaseValue);
            setInputValue('');
            break;
        default:
            return;
    }
}

const renderSaveAndClose = (onCreateAndFinish) => {
    return (
        <button
            className='btn btn-primary btn-lg mr-auto'
            onClick={onCreateAndFinish}
        >Finish</button>
    )
}

const renderSaveAndContinue = (onCreateAndContinue) => {
    return (
        <button
            className='btn btn-success btn-lg ml-auto float-right'
            onClick={onCreateAndContinue}
        >Scan New</button>
    )
}

const mapStateToProps = ({ thuDetails }) => {
    return {
        thuDetailList: thuDetails.thuDetailList
    }
}

const mapActionToProps = {
    createThuDetail
}

export default connect(mapStateToProps, mapActionToProps)(CreateThuDetail);