import notificationActions from "../type/notification";
import { spinnerService } from "@simply007org/react-spinners";
import post from '../../core/httpClientSync';

const apiUrl = 'xms-definition-api/api/suffix';
const apiUrlForSuffixEmail = 'xms-definition-api/api/suffixEmail';

export const getSuffixList = () => async dispatch => {
    const endPoint = `${apiUrl}/get-suffix-list`;
    const getSuffixListResponse = await post(endPoint, null, 'notificationGetSuffixList');
    if (getSuffixListResponse.isSuccess) {
        dispatch({
            type: notificationActions.NOTIFICATION_GETSUFFIXLIST_SUCCESS,
            payload: getSuffixListResponse.response.suffixList
        })
    }
}

export const getSuffix = (suffixId) => async dispatch => {
    const endPoint = `${apiUrl}/get-suffix`;
    spinnerService.show('notificationGetSuffix');
    const getSuffixResponse = await post(endPoint, { suffixId }, 'notificationGetSuffix');
    if (getSuffixResponse.isSuccess) {
        dispatch({
            type: notificationActions.NOTIFICATION_GETSUFFIX_SUCCESS,
            payload: getSuffixResponse.response.suffix
        })
    }
}

export const getEmailsForSuffix = (suffixId) => async dispatch => {
    const endPoint = `${apiUrlForSuffixEmail}/get-email-for-suffix`;
    spinnerService.show('notificationGetEmailsForSuffix');
    const getEmailsForSuffixResponse = await post(endPoint, { suffixId }, 'notificationGetEmailsForSuffix');
    if (getEmailsForSuffixResponse.isSuccess) {
        dispatch({
            type: notificationActions.NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS,
            payload: getEmailsForSuffixResponse.response.emailList
        });
    }
}

export const addEmailForSuffix = (suffixId, email) => async dispatch => {
    const endPoint = `${apiUrlForSuffixEmail}/add-email-to-suffix`;
    const addEmailForSuffixResponse = await post(endPoint, { suffixId, email });
    if (addEmailForSuffixResponse.isSuccess) {
        dispatch({
            type: notificationActions.NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS,
            payload: addEmailForSuffixResponse.response.emailList
        });
    }
}

export const removeEmailForSuffix = (suffixId, email) => async dispatch => {
    const endPoint = `${apiUrlForSuffixEmail}/remove-email-from-suffix`;
    const removeEmailForSuffixResponse = await post(endPoint, { suffixId, email });
    if (removeEmailForSuffixResponse.isSuccess) {
        dispatch({
            type: notificationActions.NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS,
            payload: removeEmailForSuffixResponse.response.emailList
        });
    }
}

export const getCountries = () => async dispatch => {
    dispatch({
        type: notificationActions.GET_COUNTRIES,
        payload: countryList
    });
}

export const createSuffix = (suffix) => async dispatch => {
    dispatch(suffixOperation(suffix, 'create-suffix'))
}

export const updateSuffix = (suffix) => async dispatch => {
    dispatch(suffixOperation(suffix, 'update-suffix'));
}

export const deleteSuffix = (suffix) => async dispatch => {
    dispatch(suffixOperation(suffix, 'delete-suffix'));
}

const suffixOperation = (suffix, operation) => async dispatch => {
    suffix = { ...suffix, id: suffix.suffixName, countryName: countryList.find(x => x.code === suffix.countryCode).name };
    const suffixOperationResponse = await post(`${apiUrl}/${operation}`, { suffix });
    if (suffixOperationResponse.isSuccess) {
        dispatch({ type: notificationActions.CLEAR_STATE });
    }
}

export const clearState = () => async dispatch => {
    dispatch({ type: notificationActions.CLEAR_STATE });
}

const countryList = [
    { 'code': '', 'name': 'Select Country...' },
    { 'code': 'AF', 'name': 'Afghanistan' },
    { 'code': 'AX', 'name': 'Aland Islands' },
    { 'code': 'AL', 'name': 'Albania' },
    { 'code': 'DZ', 'name': 'Algeria' },
    { 'code': 'AS', 'name': 'American Samoa' },
    { 'code': 'AD', 'name': 'Andorra' },
    { 'code': 'AO', 'name': 'Angola' },
    { 'code': 'AI', 'name': 'Anguilla' },
    { 'code': 'AQ', 'name': 'Antarctica' },
    { 'code': 'AG', 'name': 'Antigua and Barbuda' },
    { 'code': 'AR', 'name': 'Argentina' },
    { 'code': 'AM', 'name': 'Armenia' },
    { 'code': 'AW', 'name': 'Aruba' },
    { 'code': 'AU', 'name': 'Australia' },
    { 'code': 'AT', 'name': 'Austria' },
    { 'code': 'AZ', 'name': 'Azerbaijan' },
    { 'code': 'BS', 'name': 'Bahamas' },
    { 'code': 'BH', 'name': 'Bahrain' },
    { 'code': 'BD', 'name': 'Bangladesh' },
    { 'code': 'BB', 'name': 'Barbados' },
    { 'code': 'BY', 'name': 'Belarus' },
    { 'code': 'BE', 'name': 'Belgium' },
    { 'code': 'BZ', 'name': 'Belize' },
    { 'code': 'BJ', 'name': 'Benin' },
    { 'code': 'BM', 'name': 'Bermuda' },
    { 'code': 'BT', 'name': 'Bhutan' },
    { 'code': 'BO', 'name': 'Bolivia' },
    { 'code': 'BA', 'name': 'Bosnia and Herzegovina' },
    { 'code': 'BW', 'name': 'Botswana' },
    { 'code': 'BV', 'name': 'Bouvet Island' },
    { 'code': 'BR', 'name': 'Brazil' },
    { 'code': 'VG', 'name': 'British Virgin Islands' },
    { 'code': 'IO', 'name': 'British Indian Ocean Territory' },
    { 'code': 'BN', 'name': 'Brunei Darussalam' },
    { 'code': 'BG', 'name': 'Bulgaria' },
    { 'code': 'BF', 'name': 'Burkina Faso' },
    { 'code': 'BI', 'name': 'Burundi' },
    { 'code': 'KH', 'name': 'Cambodia' },
    { 'code': 'CM', 'name': 'Cameroon' },
    { 'code': 'CA', 'name': 'Canada' },
    { 'code': 'CV', 'name': 'Cape Verde' },
    { 'code': 'KY', 'name': 'Cayman Islands' },
    { 'code': 'CF', 'name': 'Central African Republic' },
    { 'code': 'TD', 'name': 'Chad' },
    { 'code': 'CL', 'name': 'Chile' },
    { 'code': 'CN', 'name': 'China' },
    { 'code': 'HK', 'name': 'Hong Kong, SAR China' },
    { 'code': 'MO', 'name': 'Macao, SAR China' },
    { 'code': 'CX', 'name': 'Christmas Island' },
    { 'code': 'CC', 'name': 'Cocos (Keeling) Islands' },
    { 'code': 'CO', 'name': 'Colombia' },
    { 'code': 'KM', 'name': 'Comoros' },
    { 'code': 'CG', 'name': 'Congo (Brazzaville)' },
    { 'code': 'CD', 'name': 'Congo, (Kinshasa)' },
    { 'code': 'CK', 'name': 'Cook Islands' },
    { 'code': 'CR', 'name': 'Costa Rica' },
    { 'code': 'CI', 'name': 'Côte d\'Ivoire' },
    { 'code': 'HR', 'name': 'Croatia' },
    { 'code': 'CU', 'name': 'Cuba' },
    { 'code': 'CY', 'name': 'Cyprus' },
    { 'code': 'CZ', 'name': 'Czech Republic' },
    { 'code': 'DK', 'name': 'Denmark' },
    { 'code': 'DJ', 'name': 'Djibouti' },
    { 'code': 'DM', 'name': 'Dominica' },
    { 'code': 'DO', 'name': 'Dominican Republic' },
    { 'code': 'EC', 'name': 'Ecuador' },
    { 'code': 'EG', 'name': 'Egypt' },
    { 'code': 'SV', 'name': 'El Salvador' },
    { 'code': 'GQ', 'name': 'Equatorial Guinea' },
    { 'code': 'ER', 'name': 'Eritrea' },
    { 'code': 'EE', 'name': 'Estonia' },
    { 'code': 'ET', 'name': 'Ethiopia' },
    { 'code': 'FK', 'name': 'Falkland Islands (Malvinas)' },
    { 'code': 'FO', 'name': 'Faroe Islands' },
    { 'code': 'FJ', 'name': 'Fiji' },
    { 'code': 'FI', 'name': 'Finland' },
    { 'code': 'FR', 'name': 'France' },
    { 'code': 'GF', 'name': 'French Guiana' },
    { 'code': 'PF', 'name': 'French Polynesia' },
    { 'code': 'TF', 'name': 'French Southern Territories' },
    { 'code': 'GA', 'name': 'Gabon' },
    { 'code': 'GM', 'name': 'Gambia' },
    { 'code': 'GE', 'name': 'Georgia' },
    { 'code': 'DE', 'name': 'Germany' },
    { 'code': 'GH', 'name': 'Ghana' },
    { 'code': 'GI', 'name': 'Gibraltar' },
    { 'code': 'GR', 'name': 'Greece' },
    { 'code': 'GL', 'name': 'Greenland' },
    { 'code': 'GD', 'name': 'Grenada' },
    { 'code': 'GP', 'name': 'Guadeloupe' },
    { 'code': 'GU', 'name': 'Guam' },
    { 'code': 'GT', 'name': 'Guatemala' },
    { 'code': 'GG', 'name': 'Guernsey' },
    { 'code': 'GN', 'name': 'Guinea' },
    { 'code': 'GW', 'name': 'Guinea-Bissau' },
    { 'code': 'GY', 'name': 'Guyana' },
    { 'code': 'HT', 'name': 'Haiti' },
    { 'code': 'HM', 'name': 'Heard and Mcdonald Islands' },
    { 'code': 'VA', 'name': 'Holy See (Vatican City State)' },
    { 'code': 'HN', 'name': 'Honduras' },
    { 'code': 'HU', 'name': 'Hungary' },
    { 'code': 'IS', 'name': 'Iceland' },
    { 'code': 'IN', 'name': 'India' },
    { 'code': 'ID', 'name': 'Indonesia' },
    { 'code': 'IR', 'name': 'Iran, Islamic Republic of' },
    { 'code': 'IQ', 'name': 'Iraq' },
    { 'code': 'IE', 'name': 'Ireland' },
    { 'code': 'IM', 'name': 'Isle of Man' },
    { 'code': 'IL', 'name': 'Israel' },
    { 'code': 'IT', 'name': 'Italy' },
    { 'code': 'JM', 'name': 'Jamaica' },
    { 'code': 'JP', 'name': 'Japan' },
    { 'code': 'JE', 'name': 'Jersey' },
    { 'code': 'JO', 'name': 'Jordan' },
    { 'code': 'KZ', 'name': 'Kazakhstan' },
    { 'code': 'KE', 'name': 'Kenya' },
    { 'code': 'KI', 'name': 'Kiribati' },
    { 'code': 'KP', 'name': 'Korea (North)' },
    { 'code': 'KR', 'name': 'Korea (South)' },
    { 'code': 'KW', 'name': 'Kuwait' },
    { 'code': 'KG', 'name': 'Kyrgyzstan' },
    { 'code': 'LA', 'name': 'Lao PDR' },
    { 'code': 'LV', 'name': 'Latvia' },
    { 'code': 'LB', 'name': 'Lebanon' },
    { 'code': 'LS', 'name': 'Lesotho' },
    { 'code': 'LR', 'name': 'Liberia' },
    { 'code': 'LY', 'name': 'Libya' },
    { 'code': 'LI', 'name': 'Liechtenstein' },
    { 'code': 'LT', 'name': 'Lithuania' },
    { 'code': 'LU', 'name': 'Luxembourg' },
    { 'code': 'MK', 'name': 'Macedonia, Republic of' },
    { 'code': 'MG', 'name': 'Madagascar' },
    { 'code': 'MW', 'name': 'Malawi' },
    { 'code': 'MY', 'name': 'Malaysia' },
    { 'code': 'MV', 'name': 'Maldives' },
    { 'code': 'ML', 'name': 'Mali' },
    { 'code': 'MT', 'name': 'Malta' },
    { 'code': 'MH', 'name': 'Marshall Islands' },
    { 'code': 'MQ', 'name': 'Martinique' },
    { 'code': 'MR', 'name': 'Mauritania' },
    { 'code': 'MU', 'name': 'Mauritius' },
    { 'code': 'YT', 'name': 'Mayotte' },
    { 'code': 'MX', 'name': 'Mexico' },
    { 'code': 'FM', 'name': 'Micronesia, Federated States of' },
    { 'code': 'MD', 'name': 'Moldova' },
    { 'code': 'MC', 'name': 'Monaco' },
    { 'code': 'MN', 'name': 'Mongolia' },
    { 'code': 'ME', 'name': 'Montenegro' },
    { 'code': 'MS', 'name': 'Montserrat' },
    { 'code': 'MA', 'name': 'Morocco' },
    { 'code': 'MZ', 'name': 'Mozambique' },
    { 'code': 'MM', 'name': 'Myanmar' },
    { 'code': 'NA', 'name': 'Namibia' },
    { 'code': 'NR', 'name': 'Nauru' },
    { 'code': 'NP', 'name': 'Nepal' },
    { 'code': 'NL', 'name': 'Netherlands' },
    { 'code': 'AN', 'name': 'Netherlands Antilles' },
    { 'code': 'NC', 'name': 'New Caledonia' },
    { 'code': 'NZ', 'name': 'New Zealand' },
    { 'code': 'NI', 'name': 'Nicaragua' },
    { 'code': 'NE', 'name': 'Niger' },
    { 'code': 'NG', 'name': 'Nigeria' },
    { 'code': 'NU', 'name': 'Niue' },
    { 'code': 'NF', 'name': 'Norfolk Island' },
    { 'code': 'MP', 'name': 'Northern Mariana Islands' },
    { 'code': 'NO', 'name': 'Norway' },
    { 'code': 'OM', 'name': 'Oman' },
    { 'code': 'PK', 'name': 'Pakistan' },
    { 'code': 'PW', 'name': 'Palau' },
    { 'code': 'PS', 'name': 'Palestinian Territory' },
    { 'code': 'PA', 'name': 'Panama' },
    { 'code': 'PG', 'name': 'Papua New Guinea' },
    { 'code': 'PY', 'name': 'Paraguay' },
    { 'code': 'PE', 'name': 'Peru' },
    { 'code': 'PH', 'name': 'Philippines' },
    { 'code': 'PN', 'name': 'Pitcairn' },
    { 'code': 'PL', 'name': 'Poland' },
    { 'code': 'PT', 'name': 'Portugal' },
    { 'code': 'PR', 'name': 'Puerto Rico' },
    { 'code': 'QA', 'name': 'Qatar' },
    { 'code': 'RE', 'name': 'Réunion' },
    { 'code': 'RO', 'name': 'Romania' },
    { 'code': 'RU', 'name': 'Russian Federation' },
    { 'code': 'RW', 'name': 'Rwanda' },
    { 'code': 'BL', 'name': 'Saint-Barthélemy' },
    { 'code': 'SH', 'name': 'Saint Helena' },
    { 'code': 'KN', 'name': 'Saint Kitts and Nevis' },
    { 'code': 'LC', 'name': 'Saint Lucia' },
    { 'code': 'MF', 'name': 'Saint-Martin (French part)' },
    { 'code': 'PM', 'name': 'Saint Pierre and Miquelon' },
    { 'code': 'VC', 'name': 'Saint Vincent and Grenadines' },
    { 'code': 'WS', 'name': 'Samoa' },
    { 'code': 'SM', 'name': 'San Marino' },
    { 'code': 'ST', 'name': 'Sao Tome and Principe' },
    { 'code': 'SA', 'name': 'Saudi Arabia' },
    { 'code': 'SN', 'name': 'Senegal' },
    { 'code': 'RS', 'name': 'Serbia' },
    { 'code': 'SC', 'name': 'Seychelles' },
    { 'code': 'SL', 'name': 'Sierra Leone' },
    { 'code': 'SG', 'name': 'Singapore' },
    { 'code': 'SK', 'name': 'Slovakia' },
    { 'code': 'SI', 'name': 'Slovenia' },
    { 'code': 'SB', 'name': 'Solomon Islands' },
    { 'code': 'SO', 'name': 'Somalia' },
    { 'code': 'ZA', 'name': 'South Africa' },
    { 'code': 'GS', 'name': 'South Georgia and the South Sandwich Islands' },
    { 'code': 'SS', 'name': 'South Sudan' },
    { 'code': 'ES', 'name': 'Spain' },
    { 'code': 'LK', 'name': 'Sri Lanka' },
    { 'code': 'SD', 'name': 'Sudan' },
    { 'code': 'SR', 'name': 'Suriname' },
    { 'code': 'SJ', 'name': 'Svalbard and Jan Mayen Islands' },
    { 'code': 'SZ', 'name': 'Swaziland' },
    { 'code': 'SE', 'name': 'Sweden' },
    { 'code': 'CH', 'name': 'Switzerland' },
    { 'code': 'SY', 'name': 'Syrian Arab Republic (Syria)' },
    { 'code': 'TW', 'name': 'Taiwan, Republic of China' },
    { 'code': 'TJ', 'name': 'Tajikistan' },
    { 'code': 'TZ', 'name': 'Tanzania, United Republic of' },
    { 'code': 'TH', 'name': 'Thailand' },
    { 'code': 'TL', 'name': 'Timor-Leste' },
    { 'code': 'TG', 'name': 'Togo' },
    { 'code': 'TK', 'name': 'Tokelau' },
    { 'code': 'TO', 'name': 'Tonga' },
    { 'code': 'TT', 'name': 'Trinidad and Tobago' },
    { 'code': 'TN', 'name': 'Tunisia' },
    { 'code': 'TR', 'name': 'Turkey' },
    { 'code': 'TM', 'name': 'Turkmenistan' },
    { 'code': 'TC', 'name': 'Turks and Caicos Islands' },
    { 'code': 'TV', 'name': 'Tuvalu' },
    { 'code': 'UG', 'name': 'Uganda' },
    { 'code': 'UA', 'name': 'Ukraine' },
    { 'code': 'AE', 'name': 'United Arab Emirates' },
    { 'code': 'GB', 'name': 'United Kingdom' },
    { 'code': 'US', 'name': 'United States of America' },
    { 'code': 'UM', 'name': 'US Minor Outlying Islands' },
    { 'code': 'UY', 'name': 'Uruguay' },
    { 'code': 'UZ', 'name': 'Uzbekistan' },
    { 'code': 'VU', 'name': 'Vanuatu' },
    { 'code': 'VE', 'name': 'Venezuela (Bolivarian Republic)' },
    { 'code': 'VN', 'name': 'Viet Nam' },
    { 'code': 'VI', 'name': 'Virgin Islands, US' },
    { 'code': 'WF', 'name': 'Wallis and Futuna Islands' },
    { 'code': 'EH', 'name': 'Western Sahara' },
    { 'code': 'YE', 'name': 'Yemen' },
    { 'code': 'ZM', 'name': 'Zambia' },
    { 'code': 'ZW', 'name': 'Zimbabwe' }
];