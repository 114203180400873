import React from 'react';
import { connect } from 'react-redux';
import { closeModals } from '../../store/action/modalActions';

import ModalShareDocs from './ModalShareDocs';
import ModalShowImage from './ModalShowImage';
import ModalShowFile from './ModalShowFile';
import ModalInlineInformation from "./ModalInlineInformation";
import ModalShowUsersForAssignment from './ModalShowUsersForAssignment';
import ModalEnterGateForOperation from './ModalEnterGateForOperation';

const modals = {
    'share-document': ModalShareDocs,
    'show-image': ModalShowImage,
    'show-file': ModalShowFile,
    'inline-information': ModalInlineInformation,
    'show-users-for-assignment': ModalShowUsersForAssignment,
    'show-assign-gate-to-operation': ModalEnterGateForOperation,
}
const Modal = ({ modal, closeModals }) => {
    const renderModal = () => {
        if (!modal.showModal) { return; }
        const PopUpModal = modals[modal.modalType];
        return <PopUpModal modalData={modal.modalData} onHide={closeModals} />
    }

    return <>{renderModal()}</>
}

const mapStateToProps = ({ modal }) => { return { modal } }

export default connect(mapStateToProps, { closeModals })(Modal);