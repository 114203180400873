import historyTypes from "../type/historyTypes";

const initialState = {
    histories: [],
    objectTypes: [],
    jsonModel: {}
};


export default (state = initialState, action) => {
    switch (action.type) {
        case historyTypes.HISTORY_LIST_RECEIVED:
            return {
                ...state,
                histories: action.payload
            };
        case historyTypes.ENTITY_TYPES_RECEIVED:
            return {
                ...state,
                objectTypes: action.payload
            };
        case historyTypes.UI_MODEL_RECEIVED:
            return {
                ...state,
                jsonModel: action.payload
            };
        default:
            return state;
    }
}
