import React from 'react';
import { connect } from 'react-redux';
import ImageListItem from './ImageListItem';

const ImageList = (props) => {

    // from parent component
    const {
        imageControllerOptions
    } = props;

    //from store
    const {
        imageList
    } = props;

    return (
        <div>
            {renderImageList(imageList, imageControllerOptions)}
        </div>
    )

}

const renderImageList = (imageList, imageControllerOptions) => {

    if (!imageList) { return renderNoImagesMessage() }

    const imageListAsArray = Object.values(imageList);

    if (!imageListAsArray.length) { return renderNoImagesMessage() }

    const renderedItemList = imageListAsArray.map(image => {
        return (
            <div className='col-md-6' key={image.uid}>
                <ImageListItem
                    image={image}
                    imageControllerOptions={imageControllerOptions}
                />
            </div>
        )
    });

    return (
        <div className='col'>
            <div className='row'>
                {renderedItemList}
            </div>
        </div>
    )
}

const renderNoImagesMessage = () => {
    return (
        <div>
            <h6 className='text-center'>
                No Images To Show
            </h6>
        </div>
    )
}

const mapStateToProps = ({ imageController }) => {
    return {
        imageList: imageController.imageList
    }
}

export default connect(mapStateToProps)(ImageList);