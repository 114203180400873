import FileDownload from 'js-file-download'
import { spinnerService } from "@simply007org/react-spinners";

const DownloadFile = (ApiUrl, Body, FileName, Method, FinallyFunction, ResponseWait, spinnerElementId = null) => {
    if (spinnerElementId) {
        spinnerService.show(spinnerElementId);
    }
    var promise = new Promise(function (resolve, reject) {

        const AccessToken = localStorage.getItem('access_token');
        fetch(ApiUrl,
            {
                method: Method,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + AccessToken,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Accept",

                },
                body: Body
            }).then(response => {
                const hasError = !response.ok;
                if (response.status === 404) {
                    reject({
                        ErrorCode: 404,
                        ErrorDesc: "Cannot reach to endpoint."
                    })
                    return
                }
                if (hasError) {
                    response.json().then(data => {
                        reject({
                            ErrorCode: data.errors[0].code,
                            ErrorDesc: data.errors[0].description
                        })
                    })

                } else {
                    response.blob().then(response => {
                        FileDownload(response, FileName)

                        resolve("OK")
                    })
                }
            }).finally(() => {
                if (typeof (FinallyFunction) === "function")
                    setTimeout(() => {
                        FinallyFunction()
                    }, ResponseWait)

                if (spinnerElementId) {
                    spinnerService.hide(spinnerElementId);
                }
            })
    })
    return promise;
}
export default DownloadFile;