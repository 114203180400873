/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRules, deleteRule, getBusinessAreas, getTimeSlotSimulation, getEffectingRulesFor } from '../store/action/calendarActions';
import history from '../core/history';
import DefaultQuota from '../component/DefaultQuota/DefaultQuota';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import { Row, Col, FormLabel, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import TimeSlotTable from '../component/TimeSlotTable/TimeSlotTable';
import Page from '../component/Page/Page';

const CalendarRuleList = (props) => {
    //Redux actions
    const { getRules, getTimeSlotSimulation, getBusinessAreas, deleteRule, getEffectingRulesFor } = props;

    //Redux store
    let { rules, businessAreas, simulatedTimeSlots, defaultQuota, effectingRules } = props;

    const [calendarRuleListState, setCalendarRuleListState] = useState({
        businessAreaId: null,
        dateEpoch: null,
    });

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }
    }, [businessAreas]);

    useEffect(() => {
        if (!rules) {
            getRules();
        }
    }, [rules]);



    const newRuleClickedHandler = () => {
        history.push('/booking/calendar-rule/new');
    }

    const openAdditionalGateClickedHandler = () => {
        history.push('/booking/calendar-rule/open-additional-gate');
    }

    const deleteRuleClickedHandler = rule => {
        deleteRule(rule.id);
    }

    const reservedSlotsClickedHandler = () => {
        history.push('/booking/reserved-slot-rule');
    }

    const businessAreaChangedHandler = value => {
        setCalendarRuleListState((previousState, props) => ({
            ...previousState,
            businessAreaId: value
        }));

        if (calendarRuleListState.dateEpoch && value) {
            getTimeSlotSimulation(calendarRuleListState.dateEpoch, value);
            getEffectingRulesFor(calendarRuleListState.dateEpoch, value);
        }
    }

    const renderValidTimeColumn = rule => {
        return moment.utc().startOf('day').add(rule.startMinutesSinceMidnight, 'minutes').format('HH:mm') + ' - ' + moment.utc().startOf('day').add(rule.endMinutesSinceMidnight, 'minutes').format('HH:mm')
    }

    const renderBusinessAreaColumn = rule => {
        if (!rule || !businessAreas || !rule.businessAreaId) return '';
        const businessArea = businessAreas.find(x => x.id === rule.businessAreaId);
        return businessArea ? businessArea.name : '';
    }

    const dateChangedHandler = value => {
        setCalendarRuleListState((previousState, props) => ({
            ...previousState,
            dateEpoch: value
        }));

        if (value && calendarRuleListState.businessAreaId) {
            getTimeSlotSimulation(value, calendarRuleListState.businessAreaId);
            getEffectingRulesFor(value, calendarRuleListState.businessAreaId);
        }
    }

    const renderSimulationHeader = () => {
        return businessAreas ? <>
            <Row md='6'>
                <Col md="2">
                    <FormLabel>Business Area: </FormLabel>
                </Col>
                <Col md="4">
                    <Form.Control value={calendarRuleListState.businessArea} onChange={(e) => businessAreaChangedHandler(e.target.value)} md="3" as="select">
                        <option>Choose...</option>
                        {businessAreas.map(businessArea => (<option key={businessArea.id} value={businessArea.id}>{businessArea.name}</option>))}
                    </Form.Control>
                </Col>
                <Col md="2">
                    <FormLabel>Date: </FormLabel>
                </Col>
                <Col md="4">
                    <DatePicker className="form-control" selected={calendarRuleListState.dateEpoch} onChange={dateChangedHandler} />
                </Col>
            </Row>
            <br />
        </> : null;
    }

    const renderSimulatedTimeSlots = () => {
        if (!simulatedTimeSlots) return null;
        return <Row className="mt-3">
            <TimeSlotTable
                readOnly
                timeSlots={simulatedTimeSlots} />
        </Row>
    }


    const renderRowClasses = rule => {
        if (rule
            && simulatedTimeSlots
            && effectingRules
            && effectingRules.some(x => x.id === rule.id)) {
            return 'bg-success text-white';
        }
    }

    const tableConfigurations = {
        title: 'Calendar Rules',
        noRowText: 'There are not any rules to show.',
        hasFilter: false,
        entities: rules,
        columns: [
            { alias: 'Business Area', name: 'businessAreaId', type: 'custom', customRender: renderBusinessAreaColumn },
            { alias: 'Type', name: 'ruleType', type: 'string' },
            { alias: 'Number of Gates', name: 'gateQuota', type: 'number', defaultValue: defaultQuota ? defaultQuota.quota : null },
            { alias: 'Valid Time', name: null, type: 'custom', customRender: renderValidTimeColumn },
            { alias: 'Specific Date', name: 'date', type: 'epoch', format: 'shortDate', style: { whiteSpace: 'nowrap' } },
            { alias: 'Weekday', name: 'weekday', type: 'string' },
            { alias: 'State', name: 'ruleState', type: 'string' }
        ],
        tableActions: [
            { clickEvent: newRuleClickedHandler, buttonText: 'Create rule', icon: 'plus' },
            { clickEvent: openAdditionalGateClickedHandler, buttonText: 'Open additional gate', icon: 'angle-double-up' },
            { clickEvent: reservedSlotsClickedHandler, buttonText: 'Reserved Slots', icon: 'calendar-check' }
        ],
        rowActions: [{ title: 'Delete rule', clickEvent: deleteRuleClickedHandler, icon: 'trash', hasConfirm: true, confirmText: 'Confirm to delete' }],
        renderRowClasses: renderRowClasses
    }

    return <Page>
        <DefaultQuota />
        <XmsTable config={tableConfigurations} />
        <br />
        <h4>Booking System Simulation</h4>
        <hr />
        {renderSimulationHeader()}
        <LoadingIndicator id='getTimeSlotSimulation' />
        {renderSimulatedTimeSlots()}
        <LoadingIndicator id='getAllRules' />
    </Page>
}

const mapStateToProps = state => {
    return {
        defaultQuota: state.gateQuota.defaultConfig,
        businessAreas: state.calendar.businessAreas,
        rules: state.calendar.rules,
        simulatedTimeSlots: state.calendar.simulatedTimeSlots,
        effectingRules: state.calendar.effectingRules
    }
}
export default connect(mapStateToProps, { getRules, getBusinessAreas, deleteRule, getTimeSlotSimulation, getEffectingRulesFor })(CalendarRuleList);