import React from 'react';

const UnitSummary = ({ unit }) => {
    return (
            <>
                <div className='card'>
                    <div className='card-body' mt={0}>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className="text-muted">Unit</label>
                                        <input type='text' className='form-control' disabled value={unit.externalId}></input>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className="text-muted">Type</label>
                                        <input type='text' className='form-control' disabled value={unit.type}></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default UnitSummary;