import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Col } from 'react-bootstrap';
import { Button } from '@dfds-ui/react-components';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};
const checkName = value => value && value === 'new' ? `Code cannot be "new".` : undefined;
const required = value => value ? undefined : 'Required';

let CompanyForm = ({ isNewCompany, handleSubmit, pristine, submitting }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Company code</Form.Label>
                    <Field
                        name="code"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Company code"
                        disabled={!isNewCompany}
                        validate={[required, checkName]}
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Name</Form.Label>
                    <Field
                        name="name"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Name"
                        required
                    />
                </Form.Group>
            </Form.Row>
            <div>
                <Button type="submit" disabled={pristine || submitting} className='float-right'>Save</Button>
            </div>
        </Form>
    );
};


CompanyForm = reduxForm({
    form: 'insertOrUpdateCompanyForm', // a unique identifier for this form
})(CompanyForm);
CompanyForm = connect(
    state => ({
        initialValues: state.company.company // pull initial values from company reducer
    }),
)(CompanyForm);

export default CompanyForm;
