import serviceTypes from '../type/service';

const initialState = {
    service: null,
    serviceList: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case serviceTypes.SERVICE_GETSERVICES_SUCCESS:
            return {
                ...state,
                serviceList: action.payload
            }
        case serviceTypes.SERVICE_GETSERVICE_SUCCESS:
            return {
                ...state,
                service: action.payload
            }
        case serviceTypes.SERVICE_CLEARSERVICESTATE_SUCCESS:
            return { ...initialState };
        default:
            return state;
    }

}