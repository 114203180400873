/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchShipment, clearError } from '../store/action/shipmentActions';
import SearchForm from '../component/SearchForm/SearchForm';
import PageHeader from '../component/PageHeader/PageHeader';
import ShipmentList from '../component/SearchShipment/ShipmentList';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

const ShipmentSearch = ({ searchShipment, clearError, shipmentList }) => {

    const [showShipmentList, setShipmentList] = useState(false);

    useEffect(() => {
        clearError();
    }, []);

    useEffect(() => {
        if (!shipmentList) { return; }

        const shipmentListAsArray = Object.values(shipmentList);

        if (shipmentListAsArray.length === 1) {
            history.push(`/shipment/${shipmentListAsArray[0].referenceNumber}/${shipmentListAsArray[0].id}`)
            clearError();
        } else {
            setShipmentList(true);
        }
    }, [shipmentList])

    const onSearchHandler = (formValues) => {
        searchShipment(formValues.searchCriteria.trim(), 'searchUnit');
    }

    const renderShipmentList = () => {

        if (!showShipmentList) { return; }
        if (!shipmentList) { return; }

        const shipmentListAsArray = Object.values(shipmentList);

        return <ShipmentList shipmentList={shipmentListAsArray} />
    }

    return (
        <div>
            <PageHeader text={'Shipment Search'} backButtonEnabled={false} />
            <SearchForm
                formSubmit={onSearchHandler}
                placeHolder="Enter Shipment ID"
                emptyFieldErrorMessage='Please Enter Shipment ID'
            />
            {renderShipmentList()}
            <LoadingIndicator id={'searchUnit'} />
        </div>
    )
}

const mapStateToProps = ({ shipment }) => {
    return {
        shipmentList: shipment.shipmentList
    }
}

export default connect(mapStateToProps, { searchShipment, clearError })(ShipmentSearch);