import types from '../type/split';
const initialState = {
    splits: [],
    thumbnailList: [],
    splitFileUid: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SPLIT_GET_THUMBNAILS_SUCCESS:
            const newTumbnailList = [...state.thumbnailList];
            if (!state.thumbnailList.some(x => x.key === action.payload.key)) {
                newTumbnailList.push({ key: action.payload.key, thumbs: action.payload.thumbs });
            }
            return {
                ...state,
                thumbnailList: newTumbnailList
            }
        case types.SPLIT_REMOVE_UNSAVED_SPLIT:
            return {
                ...state,
                splits: state.splits.filter(s => s.key !== action.payload)
            }
        case types.DO_SPLIT:
            return {
                ...state,
                splitFileUid: action.payload.splitFileUid,
                splits: action.payload.splits
            }
        case types.SPLIT_GET_SPLIT_FILE_SUCCESS:
            return {
                ...state,
                splitFileUid: action.payload
            }
        case types.SPLIT_DELETE_SPLIT_FILE_SUCCESS:
        case types.SPLIT_APPROVE_SUCCESS:
        case types.CLEAR_STATE:
            return initialState
        default:
            return state;
    }
}