import postSync, { get } from '../../core/httpClientSync';
import { spinnerService } from "@simply007org/react-spinners";
import reservedSlotActions from "../type/reservedSlot";
import moment from 'moment';
import { toast } from 'react-toastify';
import { epochToShortDate } from '../../core/epochToDateConverter';
import { utcToEpoch } from '../../core/utcToEpochConverter';

const apiUrl = 'xms-slotbooking-api/api/reservation';
const businessAreaApiUrl = 'xms-slotbooking-api/api/businessarea';
const unitTypeApiUrl = 'xms-slotbooking-api/api/booking/getunittypes';

const getMinutes = value => {
    return (value.get('hours') * 60) + value.get('minutes');
}

export const getRules = () => async dispatch => {
    const endPoint = `${apiUrl}/getall`;
    spinnerService.show('getRules');
    const getRulesResponse = await get(endPoint, 'getRules');
    if (getRulesResponse.isSuccess) {
        const rules = getRulesResponse.response.result.map(x => {
            return {
                ...x,
                carrier: x.company
            }
        });

        dispatch({
            type: reservedSlotActions.RULES_RETRIEVED,
            payload: rules
        })
    }
}

export const getRule = (rules, id) => async dispatch => {
    dispatch({
        type: reservedSlotActions.RULE_RETRIEVED,
        payload: rules && rules.length > 0 ? rules.find(x => x.id === id) : null
    })
}

export const saveRule = ruleModel => async dispatch => {
    const endPoint = `${apiUrl}/save`;

    let createRuleRequest = {
        Id: ruleModel.id,
        StartDate: utcToEpoch(ruleModel.startDate),
        EndDate: utcToEpoch(ruleModel.endDate),
        StartMinutesSinceMidnight: getMinutes(ruleModel.startMinutesSinceMidnight),
        EndMinutesSinceMidnight: getMinutes(ruleModel.endMinutesSinceMidnight),
        UnitNo: ruleModel.unitNo,
        Name: ruleModel.name,
        Surname: ruleModel.surname,
        Email: ruleModel.email,
        ThuAmount: ruleModel.thuAmount,
        Company: ruleModel.company,
        OperationType: ruleModel.operationType ? 'Unloading' : 'Loading',
        PhoneNumber: ruleModel.phoneNumber,
        TransportNumbers: ruleModel.transportNumbers,
        DayOfWeek: ruleModel.dayOfWeek,
        BusinessAreaId: ruleModel.selectedBusinessAreaId,
        HasDangerousGoods: ruleModel.hasDangerousGoods ? 1 : 0,
        HasCustoms: ruleModel.hasCustoms ? 1 : 0,
        UnitTypeId: ruleModel.unitTypeId
    }

    const createRuleResponse = await postSync(endPoint, createRuleRequest, 'createRule');

    if (createRuleResponse.isSuccess) {
        dispatch({
            type: reservedSlotActions.RULE_SAVED
        });

        toast.success(`Rule has been ${!createRuleRequest.Id ? 'created' : 'updated'} successfully.`);
        const resultObject = createRuleResponse.response;
        if (resultObject.warningDates && resultObject.warningDates.length > 0) {
            toast.warn(`But there are not available slots in the following dates: ${resultObject.warningDates.map(x => epochToShortDate(x)).join(', ')}`);
        }
    }
}

export const deleteRule = ruleId => async dispatch => {
    const endPoint = `${apiUrl}/delete?id=${ruleId}`;
    const deleteRuleResponse = await postSync(endPoint);
    if (deleteRuleResponse.isSuccess) {
        dispatch({
            type: reservedSlotActions.RULE_DELETED,
        })
    }
}

export const finalizeAddOrUpdate = () => async dispatch => {
    dispatch({
        type: reservedSlotActions.FINALIZE_ADD_OR_UPDATE,
    })
}

export const fillBookingSchema = (unitTypes) => async dispatch => {

    const itemsOfUnitTypeForBooking = [];

    unitTypes.forEach(item => {
        const newValueItem = {
            type: "string",
            title: item.name,
            enum: [
                item.id
            ]
        };

        itemsOfUnitTypeForBooking.push(newValueItem);
    });

    const formSchema = {
        "schema": {
            "type": "object",
            "required": ["carrier", "email", "unitTypeId"],
            "definitions":{
                "UnitType":{
                    "title": "Unit Type",
                    "type": "string",
                    "anyOf": itemsOfUnitTypeForBooking
                }

            },
            "properties": {
                "unitNo": { "type": ["string", "null"], "title": "Unit No", "default": "" },
                "name": { "type": ["string", "null"], "title": "Name", "default": "" },
                "surname": { "type": ["string", "null"], "title": "Surname", "default": "" },
                "email": { "type": ["string", "null"], "title": "E-mail", "format": "email" },
                "thuAmount": { "type": ["number", "null"], "title": "THU", "minimum": 0 },
                "carrier": { "type": ["string", "null"], "title": "Carrier" },
                // "unitTypeId": { "type": ["string", "null"], "title": "Unit Type", "enum": unitTypes.map(x => x.id) },
                "unitTypeId": {
                    "$ref": "#/definitions/UnitType",
                    "title": "Unit Type"
                  },
                "isUnloading": {
                    "type": ["boolean", "null"], "title": "Operation type", "default": true, "oneOf": [
                        { "title": "Unloading", "const": true },
                        { "title": "Loading", "const": false }
                    ]
                },
                "hasDangerousGoods": {
                    "type": "boolean", "title": "Contains Dangerous Goods", "default": false, "oneOf": [
                        { "title": "Yes", "const": true },
                        { "title": "No", "const": false },
                    ]
                },
                "hasCustoms": {
                    "type": "boolean", "title": "Custom Clearance Needed", "default": false, "oneOf": [
                        { "title": "Yes", "const": true },
                        { "title": "No", "const": false },
                    ]
                },
                "phoneNumber": { "type": ["string", "null"], "title": "Phone Number" },
                "transportNumbers": { "type": ["string", "null"], "title": "Ref/Order number/Transports" }
            }
        },

        "uiSchema": {
            "isUnloading": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            },
            "hasDangerousGoods": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            },
            "hasCustoms": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            }
        }
    }

    dispatch({
        type: reservedSlotActions.FORM_LOADED,
        payload: formSchema
    });
}

export const getBusinessAreas = () => async dispatch => {
    const endPoint = `${businessAreaApiUrl}/getall`;
    const response = await get(endPoint, 'openAdditionalGates');
    if (response.isSuccess) {
        dispatch({
            type: reservedSlotActions.BUSINESS_AREAS_RETRIEVED,
            payload: response.response.result
        });
    }
}

export const getUnitTypes = () => async dispatch => {
    const endPoint = unitTypeApiUrl;
    const getUnitTypesResponse = await get(endPoint, '');
    if (getUnitTypesResponse.isSuccess) {
        dispatch({
            type: reservedSlotActions.UNIT_TYPES_RETRIEVED,
            payload: getUnitTypesResponse.response.result
        });
    }
}

