import postSync from '../../core/httpClientSync';
import unitTypes from '../type/unit';
import history from '../../core/history';
import { toast } from 'react-toastify';

const apiUrl = 'xms-crawler-api/api/unit/getDetails';

export const searchUnit = (unitId, redirectToDetailsPage, spinnerElementId = null) => async dispatch => {
    const getUnitDetailsRequest = { unitId };
    const unitSearchResult = await postSync(apiUrl, getUnitDetailsRequest, spinnerElementId);

    if (!unitSearchResult.isSuccess) { return; }
    if (!unitSearchResult.response.unitInfo) {
        toast.error(`No Details for Unit ${unitId}`);
        return;
    }

    const unit = generateRelativePathsForUnit(unitSearchResult.response.unitInfo);

    dispatch({
        type: unitTypes.UNIT_FETCHED,
        payload: unit
    })

    if (redirectToDetailsPage) {
        history.push(`/unit/${unitId}`);
    }
}

export const findUnitDataForActionUrl = async (unitId, spinnerElementId = null) => {
    const getUnitDetailsRequest = { unitId };
    const unitSearchResult = await postSync(apiUrl, getUnitDetailsRequest, spinnerElementId);

    if (!unitSearchResult.isSuccess) { return; }
    if (!unitSearchResult.response.unitInfo) {
        toast.error(`No Details for Unit ${unitId}`);
        return;
    }

    return generateRelativePathsForUnit(unitSearchResult.response.unitInfo);
}

const generateRelativePathsForUnit = (unitToGenerate) => {
    const unit = { ...unitToGenerate };
    const shipmentIdAndReferences = [];
    const shipmentReferenceList = [];
    const shipmentRelativePathList = [];

    for (const transport of unit.transports) {
        for (const shipment of transport.shipments) {
            shipmentIdAndReferences.push({ shipmentId: shipment.id, referenceNumber: shipment.referenceNumber });
            shipmentReferenceList.push(shipment.referenceNumber);
            shipmentRelativePathList.push(concatShipment(shipment.referenceNumber));
        }
    }

    unit.shipmentIdAndReferenceList = shipmentIdAndReferences;
    unit.shipmentReferenceList = shipmentReferenceList;
    unit.shipmentRelativePathList = shipmentRelativePathList;

    unit.relativePath = concatUnit(unit.internalId);
    return unit;
}

export const resetUnitData = () => async dispatch => {
    dispatch({
        type: unitTypes.RESET_UNIT_DATA
    });
}

export const getShipmentFilesOfUnit = (unit, spinnerId) => async dispatch => {

    const RelativePaths = unit.shipmentRelativePathList;

    const getFileListRequest = { RelativePaths };
    const url = 'xms-document-api/api/document/getFileList';
    const fileListResult = await postSync(url, getFileListRequest, spinnerId);

    if (fileListResult.isSuccess) {
        const responseFileList = fileListResult.response.map(shipmentFileInfo => {
            return {
                relativePath: shipmentFileInfo.relativePath.replace('Shipment/', ''),
                files: shipmentFileInfo.files
            }
        })

        dispatch({
            type: unitTypes.SAVE_SHIPMENT_FILE_LIST,
            payload: responseFileList
        })
    }
}

export const getDocsMissingStates = (unit, spinnerId) => async dispatch => {

    const orderItemIdList = unit.shipmentReferenceList;

    const getMissingDocumentStateForListRequest = { orderItemIdList };
    const url = 'xms-shipment-api/api/missingdocument/get-missing-document-state-for-list';
    const getDocsMissingStatesResult = await postSync(url, getMissingDocumentStateForListRequest, spinnerId);

    if (getDocsMissingStatesResult.isSuccess) {
        dispatch({
            type: unitTypes.SAVE_DOCS_MISSING_STATE_LIST,
            payload: getDocsMissingStatesResult.response.shipmentMissingDocumentStateFlagValuePairList
        })
    }
}


export const concatShipment = relativePath => relativePath ? `Shipment/${relativePath}` : 'Shipment';

export const concatUnit = relativePath => `Unit/${relativePath}`;



