import userTypes from '../type/user';

const initialState = {
    user: null,
    userListResponse: null,
    roles: null,
    regions: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case userTypes.USER_GETUSERS_SUCCESS:
            let newUserListResponse = null;
            if (state.userListResponse) {
                newUserListResponse = {
                    ...action.payload,
                    items: [...state.userListResponse.items, ...action.payload.items]
                }
            } else {
                newUserListResponse = action.payload;
            }
            return {
                ...state,
                userListResponse: newUserListResponse
            }
        case userTypes.USER_SAVEUSER_SUCCESS:
            return {
                ...state,
                userListResponse: null
            }
        case userTypes.USER_GETUSER_SUCCESS:
            return {
                ...state,
                user: {
                    ...action.payload,
                    status: action.payload ? '' + action.payload.status : null,
                    repassword: action.payload ? action.payload.password : null
                }
            }
        case userTypes.USER_CLEARSELECTEDUSER_SUCCESS:
            return {
                ...state,
                user: null
            }
        case userTypes.USER_GETROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload
            }
        case userTypes.USER_GETREGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload
            }
        default:
            return state;
    }

}