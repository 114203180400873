import React from 'react';

import { Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const ShipmentList = ({ shipmentList }) => {

    const renderTableHeader = () => {
        return (
            <thead>
                <tr>
                    <th scope='col'>Shipment ID</th>
                    <th scope='col'>Order Item ID</th>
                    <th scope='col'>Suffix</th>
                    <th scope='col'>THU Count</th>
                    <th scope='col'>Inbound Unit</th>
                    <th scope='col'>Outbound Unit</th>
                </tr>
            </thead>
        )
    }

    const renderShipmentLines = () => {
        return (
            shipmentList.map(shipment => {
                return (
                    <tr key={shipment.referenceNumber}>
                        <td>
                            <Link to={`/shipment/${shipment.referenceNumber}/${shipment.id}`} >{shipment.id}</Link>
                        </td>
                        <td>
                            <Link to={`/shipment/${shipment.referenceNumber}/${shipment.id}`} >{shipment.referenceNumber}</Link>
                        </td>
                        <td>
                            {shipment.suffix}
                        </td>
                        <td>
                            {shipment.thuCount}
                        </td>
                        <td>
                            <Link to={`/unit/${shipment.inboundUnitReference}`} >{shipment.inboundUnitReference}</Link>
                        </td>
                        <td>
                            <Link to={`/unit/${shipment.outboundUnitReference}`} >{shipment.outboundUnitReference}</Link>
                        </td>
                    </tr>
                )
            })
        )
    }

    return (
        <>
            <Table bordered size='sm'>
                {renderTableHeader()}
                <tbody>
                    {renderShipmentLines()}
                </tbody>
            </Table>
        </>
    )

};

export default ShipmentList;