import React, { useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { PDFReader } from 'reactjs-pdf-reader';
import { downloadBlobData } from '../../store/action/fileControllerAction'
import { Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToPrint from 'react-to-print';

const ModalShowFile = ({ modalData, onHide }) => {

    const componentRef = useRef();

    const file = modalData.file;
    const height = window.innerHeight * 2 / 3;

    const renderHeader = () => {
        return (
            <Modal.Title>
                {file.name}
            </Modal.Title>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <ReactToPrint
                    trigger={() => <Button size="small" variation="primary" >Print <FontAwesomeIcon icon="print" /></Button>}
                    content={() => componentRef.current}
                />

                <Button size="small" variation="secondary" disabled={file.status === 'not-uploaded'} onClick={() => downloadBlobData(file, file.name)}>
                    Download <FontAwesomeIcon icon="download" />
                </Button>
            </>
        )
    }

    const hide = () => {
        URL.revokeObjectURL(file.url);
        onHide();
    }

    return (
        <Modal
            show={true}
            onHide={hide}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                {renderHeader()}
            </Modal.Header>

            <Modal.Body style={{ overflow: "auto", height: height }}>
                <div className='print-content' ref={componentRef}>
                    <PDFReader url={file.url} showAllPage="true" width="1080"/>
                </div>
            </Modal.Body>

            <Modal.Footer>
                {renderFooter()}
            </Modal.Footer>
        </Modal>
    )

}

export default ModalShowFile;