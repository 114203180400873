import shipmentTypes from '../type/shipment';
import postSync from '../../core/httpClientSync';

export const searchShipment = (shipmentId, spinnerElementId = null) => async dispatch => {

    const getShipmentDetailsRequest = { shipmentId };
    const url = 'xms-crawler-api/api/shipment/getDetails';
    const shipmentSearchResult = await postSync(url, getShipmentDetailsRequest, spinnerElementId);

    if (shipmentSearchResult.isSuccess) {
        dispatch({
            type: shipmentTypes.ADD_SHIPMENTS_TO_LIST,
            payload: shipmentSearchResult.response.shipmentList
        })
    }

}

export const getShipmentDetailWithOrderItemId = (referenceNumber, spinnerElementId) => async dispatch => {

    dispatch({
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    });

    const getShipmentDetailWithOrderItemIdRequest = { referenceNumber };
    const url = 'xms-crawler-api/api/shipment/get-shipment-detail-with-order-item-id';

    const getShipmentDetailWithOrderItemIdResponse = await postSync(url, getShipmentDetailWithOrderItemIdRequest, spinnerElementId);

    if (getShipmentDetailWithOrderItemIdResponse.isSuccess) {
        dispatch({
            type: shipmentTypes.UPDATE_SEARCHED_SHIPMENT,
            payload: getShipmentDetailWithOrderItemIdResponse.response.shipment
        })
    }
}

export const getFileList = (RelativePaths, spinnerId) => async dispatch => {

    dispatch({
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    });

    const getFileListResult = await retrieveFileList(RelativePaths, spinnerId);

    if (getFileListResult.isSuccess) {
        const responseFileList = getFileListResult.response.map(shipmentFileInfo => {
            return {
                relativePath: shipmentFileInfo.relativePath.replace('Shipment/', ''),
                files: shipmentFileInfo.files
            }
        })

        dispatch({
            type: shipmentTypes.SAVE_FILE_LIST,
            payload: responseFileList
        })
    }
}

export const getDocsMissingStates = (orderItemIdList, spinnerId) => async dispatch => {

    const getDocsMissingStatesResult = await retireveDocsMissingStates(orderItemIdList, spinnerId);

    if (getDocsMissingStatesResult.isSuccess) {
        dispatch({
            type: shipmentTypes.SAVE_DOCS_MISSING_STATE_LIST,
            payload: getDocsMissingStatesResult.response.shipmentMissingDocumentStateFlagValuePairList
        })
    }
}

export const changeMissingDocsState = (orderItemId, missingDocumentFlag, user, spinnerId) => async dispatch => {

    const url = 'xms-shipment-api/api/missingdocument/change-missing-document-state';
    const changeMissingDocsStateRequest = { orderItemId, missingDocumentFlag, user };
    const changeMissingDocsStateResponse = await postSync(url, changeMissingDocsStateRequest, spinnerId);
    if (changeMissingDocsStateResponse.isSuccess) {
        const changeMissingDocumentStateResponse = {
            orderItemId,
            isMissingDocumentStateFlagSet: changeMissingDocsStateResponse.response.shipmentMissingDocumentState.missingDocumentFlag
        };
        dispatch({
            type: shipmentTypes.SAVE_DOCS_MISSING_STATE,
            payload: changeMissingDocumentStateResponse
        })
    }
}

export const clearError = () => {
    return {
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    };
}

export const initializeShipmentData = () => {
    return {
        type: shipmentTypes.INITALIZE_SHIPMENT_DATA
    };
}

export const retireveDocsMissingStates = async (orderItemIdList, spinnerId) => {
    const getMissingDocumentStateForListRequest = { orderItemIdList };
    const url = 'xms-shipment-api/api/missingdocument/get-missing-document-state-for-list';
    const getDocsMissingStatesResult = await postSync(url, getMissingDocumentStateForListRequest, spinnerId);
    return getDocsMissingStatesResult;
}

export const retrieveFileList = async (RelativePaths, spinnerId) => {
    const getFileListRequest = { RelativePaths };
    const url = 'xms-document-api/api/document/getFileList';
    const getFileListResult = await postSync(url, getFileListRequest, spinnerId);
    return getFileListResult;
}

export const concatShipment = relativePath => relativePath ? `Shipment/${relativePath}` : 'Shipment';