import React from 'react';
import { Row, Form } from "react-bootstrap";
import useTranslation from '../../../../core/useTranslation';
import EntityChangeValue from './EntityChangeValue';

const EntityChange = ({ change, objectType, action }) => {
    const { t } = useTranslation();

    return <Row>
        <Form.Label column sm={2} xs={4}>
            <strong>{t(`History.${objectType}.${change.propertyName}`) + ':'}</strong>
        </Form.Label>
        <Form.Label column sm={10} xs={8}>
            <EntityChangeValue change={change} action={action} objectType={objectType} />
        </Form.Label>
    </Row>
}

export default EntityChange;
