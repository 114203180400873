import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ListItem, Input } from '@dfds-ui/react-components';
import { YesTick } from '@dfds-ui/icons'

const ModalShowUsersForAssignment = ({ modalData, onHide }) => {
    const allUsers = modalData ? modalData.users : [];
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedUserNameSurname, setSelectedUserNameSurname] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(allUsers);

    const onAssignClickHandler = () => {
        modalData.onAssignClickHandler(selectedUserId, selectedUserNameSurname);
        onHide();
    }
    const onUserFilterChanged = value => {
        value = value.toLowerCase();
        const newFilteredUsers = [];
        for (const user of allUsers) {
            const loweredUser = { ...user, name: user.name.toLowerCase(), surname: user.surname.toLowerCase() }
            if (loweredUser.name.indexOf(value) > -1 ||
                loweredUser.surname.indexOf(value) > -1) {
                newFilteredUsers.push(user);
            }
        }

        setFilteredUsers(newFilteredUsers);
    }
    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Select an user to assign the task'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Input name="userFilter" onKeyUp={e => onUserFilterChanged(e.target.value)} placeholder="Search user" />
                <ul style={{ padding: '5px 0' }}>
                    {filteredUsers.map(user =>
                        <ListItem
                            style={{ margin: '0px', padding: '0px 20px' }}
                            key={user.username}
                            selected={selectedUserId === user.username}
                            clickable
                            onClick={() => { setSelectedUserId(user.username); setSelectedUserNameSurname(`${user.name} ${user.surname}`) }}>
                            {selectedUserId === user.username ? <YesTick style={{ marginRight: '20px' }} /> : ''}
                            {`${user.name} ${user.surname}`}
                        </ListItem>
                    )}
                </ul>
            </Modal.Body>

            <Modal.Footer>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-right">
                            <Button variant='primary' className='ml-3' disabled={!selectedUserId} onClick={onAssignClickHandler}>Assign</Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    )

}

export default ModalShowUsersForAssignment;