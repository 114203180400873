import bookingActions from '../type/booking';
import httpClient from '../../core/httpclient';
import moment from 'moment';
import { spinnerService } from '@simply007org/react-spinners';
import { toast } from 'react-toastify';
import post, { get } from "../../core/httpClientSync";
import modalTypes from "../type/modal";
import { utcToEpoch } from '../../core/utcToEpochConverter';
const apiUrl = 'xms-slotbooking-api/api/booking';
const businessAreasUrl = 'xms-slotbooking-api/api/businessarea';
const taskApiUrl = 'xms-workflow-api/api/operation';

export const getAvailableDates = (businessAreaId) => async dispatch => {
    let endPoint = `${apiUrl}/getnextworkingdays?businessAreaId=${businessAreaId}`;
    const response = await get(endPoint, 'bookingTimeSlot');
    if (response.isSuccess) {
        dispatch({
            type: bookingActions.AVAILABLE_DATES_RECEIVED,
            payload: response.response.result.availableDates
        });
    } else {
        dispatch({
            type: bookingActions.TIME_SLOT_NOT_AVAILABLE
        });
    }
}

export const selectTimeSlot = (date, time) => async dispatch => {
    dispatch({
        type: bookingActions.BOOKING_SELECTTIMESLOT_SUCCESS,
        payload: { date: date, time: time }
    });
}

export const createBooking = (selectedTime, formData, isInternalUser) => async dispatch => {
    const endPoint = `${apiUrl}/save`;

    const createBookingRequest = {
        DateTime: selectedTime,
        BusinessAreaId: formData.businessArea,
        UnitTypeId: formData.unitType,
        HasDangerousGoods: formData.hasDangerousGoods === 'true' ? 1 : 0,
        HasCustoms: formData.hasCustoms === 'true' ? 1 : 0,
        UnitNo: formData.unitNo,
        Name: formData.name,
        Surname: formData.surname,
        Email: formData.email,
        THUAmount: formData.thuAmount,
        Carrier: formData.carrier,
        OperationType: formData.operationType === 'true' ? 1 : 0,
        PhoneNumber: formData.phoneNumber,
        TransportNumbers: [formData.transportNumbers],
        AdministratorNote: formData.administratorNote,
        UserNote: formData.userNote
    }

    spinnerService.show('createOrUpdateBooking');
    httpClient.post(endPoint, createBookingRequest)
        .then(response => {
            dispatch({
                type: bookingActions.BOOKING_CREATEBOOKING_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log('Error occured: ', error);
        })
        .finally(() => { spinnerService.hide('createOrUpdateBooking'); });
}

export const updateBooking = (bookingCode, formData, isInternalUser, selectedDate, selectedTime) => async dispatch => {
    const endPoint = isInternalUser ? `${apiUrl}/Save` : `${apiUrl}/save`;
    const updateBookingRequest = {
        Date: selectedDate,
        DateTime: selectedTime,
        BookingCode: bookingCode,
        BusinessAreaId: formData.businessArea,
        UnitTypeId: formData.unitType,
        HasDangerousGoods: formData.hasDangerousGoods === 'true' ? 1 : 0,
        HasCustoms: formData.hasCustoms === 'true' ? 1 : 0,
        UnitNo: formData.unitNo,
        Name: formData.name,
        Surname: formData.surname,
        Email: formData.email,
        THUAmount: formData.thuAmount,
        Carrier: formData.carrier,
        OperationType: formData.operationType === 'true' ? 1 : 0,
        PhoneNumber: formData.phoneNumber,
        TransportNumbers: [formData.transportNumbers],
        AdministratorNote: formData.administratorNote,
        UserNote: formData.userNote
    }

    spinnerService.show('createOrUpdateBooking');
    httpClient.post(endPoint, updateBookingRequest)
        .then(response => {
            dispatch({
                type: bookingActions.UPDATE_BOOKING,
                payload: bookingCode
            });
        })
        .catch(error => {
            console.log('Error occured: ', error);
            const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0].description : error.message;
            if (errorMessage) {
                toast.error(`${errorMessage}`);
            }
        })
        .finally(() => { spinnerService.hide('createOrUpdateBooking'); });
}

export const fillBookingFormData = (bookingFormData) => async dispatch => {
    dispatch({
        type: bookingActions.BOOKING_FILL_FORM_DATA_SUCCESS,
        payload: bookingFormData
    });
}

export const fillBookingFormById = (bookingId) => async dispatch => {
    let endPoint = `${apiUrl}/getbyid`;
    endPoint += '?id=' + bookingId;
    spinnerService.show('createOrUpdateBooking');
    dispatch({
        type: bookingActions.BOOKING_RETRIEVING,
        payload: true
    });
    httpClient.get(endPoint)
        .then(response => {
            response.data.result = {
                ...response.data.result,
                businessArea: !response.data.result.businessAreaId ? '' : response.data.result.businessAreaId,
                unitType: !response.data.result.unitTypeId ? '' : response.data.result.unitTypeId,
                name: !response.data.result.name ? '' : response.data.result.name,
                surname: !response.data.result.surname ? '' : response.data.result.surname,
                userNote: !response.data.result.userNote ? '' : response.data.result.userNote,
                administratorNote: !response.data.result.administratorNote ? '' : response.data.result.administratorNote,
                transportNumbers: response.data.result.transportNumbers.join(', '),
                operationType: response.data.result.operationType === 'Unloading' ? 'true' : 'false',
                hasDangerousGoods: response.data.result.hasDangerousGoods ? 'true' : 'false',
                hasCustoms: response.data.result.hasCustoms ? 'true' : 'false'
            }
            dispatch({
                type: bookingActions.FILL_FORM_DATA_BY_ID,
                payload: response.data.result
            })
        })
        .catch(error => {
            const errorMessage = error.response && error.response.data && error.response.data.errors && error.response.data.errors.length ? error.response.data.errors[0].description : error.message;
            if (errorMessage) {
                toast.error(`${errorMessage}`);
            }
        })
        .finally(() => {
            dispatch({
                type: bookingActions.BOOKING_RETRIEVING,
                payload: false
            });
            spinnerService.hide('createOrUpdateBooking');
        });
}

export const getRequiredAttributesForBooking = (isInternalUser, businessAreas, unitTypes, isUpdateAction) => async dispatch => {

    const itemsOfBusinessAreaForBooking = [];

    businessAreas.forEach(item => {
        const newValueItem = {
            type: "string",
            title: item.name,
            enum: [
                item.id
            ]
        };

        itemsOfBusinessAreaForBooking.push(newValueItem);
    });

    const itemsOfUnitTypeForBooking = [];

    unitTypes.forEach(item => {
        const newValueItem = {
            type: "string",
            title: item.name,
            enum: [
                item.id
            ]
        };

        itemsOfUnitTypeForBooking.push(newValueItem);
    });



    const formSchema = isInternalUser ? {
        "schema": {
            "type": "object",
            "required": ["unitNo", "email", "thuAmount", "carrier", "phoneNumber", "transportNumbers", "businessArea", "unitType"],
            "properties": {
                "businessArea": { "type": "string", "title": "Business Area", oneOf: itemsOfBusinessAreaForBooking },
                "unitNo": { "type": "string", "title": "Unit No" },
                "name": { "type": "string", "title": "Name", "default": "" },
                "surname": { "type": "string", "title": "Surname", "default": "" },
                "email": { "type": "string", "title": "E-mail", "format": "email" },
                "thuAmount": { "type": "number", "title": "THU", "minimum": 0 },
                "carrier": { "type": "string", "title": "Carrier" },
                "unitType": { "type": "string", "title": "Unit Type", oneOf: itemsOfUnitTypeForBooking },
                "isUnloading": {
                    "type": "boolean", "title": "Operation type", "default": true, "oneOf": [
                        { "title": "Unloading", "const": true },
                        { "title": "Loading", "const": false }
                    ]
                },
                "hasDangerousGoods": {
                    "type": "boolean", "title": "Contains Dangerous Goods", "default": false, "oneOf": [
                        { "title": "Yes", "const": true },
                        { "title": "No", "const": false },
                    ]
                },
                "hasCustoms": {
                    "type": "boolean", "title": "Custom Clearance Needed", "default": false, "oneOf": [
                        { "title": "Yes", "const": true },
                        { "title": "No", "const": false },
                    ]
                },
                "phoneNumber": { "type": "string", "title": "Phone Number" },
                "transportNumbers": { "type": "string", "title": "Ref/Order number/Transports" },
                "userNote": { "type": "string", "title": "Notes" },
                "administratorNote": { "type": "string", "title": "Admin Notes" }
            }
        },

        "uiSchema": isUpdateAction ? {
            "isUnloading": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            },
            "hasDangerousGoods": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            },
            "hasCustoms": {
                "ui:widget": "radio",
                "ui:options": {
                    "inline": true
                }
            },
            "userNote": {
                "ui:readonly": true
            },
            "businessArea": {
                "ui:readonly": true
            }
        } : {
                "isUnloading": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "hasDangerousGoods": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "hasCustoms": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "userNote": {
                    "ui:readonly": true
                }
            }
    } : {
            "schema": {
                "type": "object",
                "required": ["unitNo", "email", "thuAmount", "phoneNumber", "transportNumbers", "businessArea", "unitType"],
                "properties": {
                    "businessArea": { "type": "string", "title": "Business Area", oneOf: itemsOfBusinessAreaForBooking },
                    "unitNo": { "type": "string", "title": "Unit No" },
                    "name": { "type": "string", "title": "Name", "default": "" },
                    "surname": { "type": "string", "title": "Surname", "default": "" },
                    "email": { "type": "string", "title": "E-mail", "format": "email" },
                    "thuAmount": { "type": "number", "title": "THU", "minimum": 0 },
                    "unitType": { "type": "string", "title": "Unit Type", oneOf: itemsOfUnitTypeForBooking },
                    "isUnloading": {
                        "type": "boolean", "title": "Operation type", "oneOf": [
                            { "title": "Unloading", "const": true },
                            { "title": "Loading", "const": false }
                        ]
                    },
                    "hasDangerousGoods": {
                        "type": "boolean", "title": "Contains Dangerous Goods", "default": false, "oneOf": [
                            { "title": "Yes", "const": true },
                            { "title": "No", "const": false },
                        ]
                    },
                    "hasCustoms": {
                        "type": "boolean", "title": "Custom Clearance Needed", "default": false, "oneOf": [
                            { "title": "Yes", "const": true },
                            { "title": "No", "const": false },
                        ]
                    },
                    "phoneNumber": { "type": "string", "title": "Phone Number" },
                    "transportNumbers": { "type": "string", "title": "Ref/Order number/Transports" },
                    "userNote": { "type": "string", "title": "Notes" }
                }
            },

            "uiSchema": isUpdateAction ? {
                "isUnloading": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "hasDangerousGoods": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "hasCustoms": {
                    "ui:widget": "radio",
                    "ui:options": {
                        "inline": true
                    }
                },
                "businessArea": {
                    "ui:readonly": true
                }
            } : {
                    "isUnloading": {
                        "ui:widget": "radio",
                        "ui:options": {
                            "inline": true
                        }
                    },
                    "hasDangerousGoods": {
                        "ui:widget": "radio",
                        "ui:options": {
                            "inline": true
                        }
                    },
                    "hasCustoms": {
                        "ui:widget": "radio",
                        "ui:options": {
                            "inline": true
                        }
                    }
                }
        }

    dispatch({
        type: bookingActions.BOOKING_GETREQUIREDATTR_SUCCESS,
        payload: formSchema
    });
}

export const getTimeSlots = (businessAreaId, dateEpoch) => async dispatch => {
    let endPoint = `${apiUrl}/getslotsfordate?businessAreaId=${businessAreaId}&date=${dateEpoch}`;

    const response = await get(endPoint, 'bookingTimeSlot');
    if (response.isSuccess) {
        dispatch({
            type: bookingActions.BOOKING_GETTIMESLOTS_SUCCESS,
            payload: response.response.result
        });
    } else {
        dispatch({
            type: bookingActions.TIME_SLOT_NOT_AVAILABLE
        });
    }
};

export const getBookingsForCarrier = (date) => async dispatch => {
    let endPoint = `${apiUrl}/getbookingsforcarrier?date=${date}`;
    spinnerService.show('bookingGetAll');
    const response = await get(endPoint, 'bookingGetAll');
    if (response.isSuccess) {
        dispatch({
            type: bookingActions.BOOKING_GETBOOKINGSFORCARRIER_SUCCESS,
            payload: response.response.result
        })
    }
}

export const getBookings = (date) => async dispatch => {
    let endPoint = `${apiUrl}/getbookingsfordate?date=${date}`;
    const response = await get(endPoint, 'bookingGetAll');
    if (response.isSuccess) {
        dispatch({
            type: bookingActions.BOOKING_GETBOOKINGS_SUCCESS,
            payload: response.response.result
        })
    }
}

export const markAsArrived = (bookingId) => async dispatch => {
    let endPoint = `${apiUrl}/markunitasarrived?bookingCode=${bookingId}`;

    const response = await post(endPoint, { bookingCode: bookingId });

    if (response.isSuccess) {
        dispatch({
            type: bookingActions.BOOKING_MARK_AS_ARRIVED_SUCCESS,
            payload: bookingId
        })
    }
}

export const markAsUnArrived = (bookingId) => async dispatch => {
    let endPoint = `${apiUrl}/markunitasunarrived?bookingCode=${bookingId}`;

    const response = await post(endPoint);

    if (response.isSuccess) {
        dispatch({
            type: bookingActions.BOOKING_MARKED_AS_UNARRIVED,
            payload: bookingId
        })
    }
};

export const getArrivalReceiptDetails = (bookingId) => async dispatch => {
    const endPoint = `${taskApiUrl}/get-detail-external`;

    const response = await post(endPoint, { externalId: bookingId, channelType: 'Booking' }, 'bookingGetAll');
    if (response.isSuccess) {
        dispatch({
            type: bookingActions.TASK_DETAIL_RECEIVED,
            payload: response.response.result
        });
    }
};

export const cancelBooking = (bookingId) => async dispatch => {
    let endPoint = `${apiUrl}/cancelbooking?bookingCode=${bookingId}`;

    const response = await post(endPoint, { code: bookingId }, 'bookingGetAll');
    if (response.isSuccess) {
        dispatch({ type: bookingActions.BOOKING_CANCEL_BOOKING, payload: bookingId });
    }
};


export const getUnitTypes = () => async dispatch => {
    const endPoint = `${apiUrl}/getunittypes`;
    const getUnitTypesResponse = await get(endPoint, '');
    if (getUnitTypesResponse.isSuccess) {
        dispatch({
            type: bookingActions.UNIT_TYPE_RECEIVED,
            payload: getUnitTypesResponse.response.result
        });
    }
}

export const showInlineInformationModal = (title, data, canSeeAdminNotes, handler) => async dispatch => {

    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'inline-information',
            modalData: { title: title, data: data, canSeeAdminNotes: canSeeAdminNotes, handler: handler }
        }
    })
};

export const clearTimeSlots = () => async dispatch => {
    dispatch({
        type: bookingActions.TIME_SLOTS_CLEARED
    });
}

export const getBusinessAreas = () => async dispatch => {
    const endPoint = `${businessAreasUrl}/getall`;
    const getBusinessAreasResponse = await get(endPoint, 'createOrUpdateBooking');
    const businessAreasItems = getBusinessAreasResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getBusinessAreasResponse.isSuccess) {
        dispatch({
            type: bookingActions.BUSINESS_AREA_RECEIVED,
            payload: businessAreasItems
        });
    }
};

export const getUnitTypesForBooking = () => async dispatch => {
    const endPoint = `${apiUrl}/getunittypes`;
    const getUnitTypesResponse = await get(endPoint, 'createOrUpdateBooking');
    const unitTypesItems = getUnitTypesResponse.response.result.map(item => ({ value: item.id, text: item.name }));
    if (getUnitTypesResponse.isSuccess) {
        dispatch({
            type: bookingActions.UNIT_TYPES_FOR_BOOKING,
            payload: unitTypesItems
        });
    }
}

export const getBookingItem = (bookingId) => async dispatch => {
    if (bookingId && bookingId !== 'new') {
        return;
    }

    dispatch({
        type: bookingActions.BOOKING_FILL_FORM_DATA_SUCCESS,
        payload: {
        }
    });
};


export const clearBookingState = () => async dispatch => {
    dispatch({
        type: bookingActions.BOOKING_CLEAR_STATE
    });
}

const bookingDataRetrieving = (bookingStatus) => async dispatch => {
    dispatch({
       type: bookingActions.BOOKING_RETRIEVING,
       payload: bookingStatus
    });
};
