/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import RoleName from '../component/Role/RoleName';
import PolicyList from '../component/Role/PolicyList';
import PageHeader from '../component/PageHeader/PageHeader';
import { createRole } from '../store/action/roleActions';
import { toast } from 'react-toastify';
import { Button } from '@dfds-ui/react-components'
import Page from '../component/Page/Page';

const CreateRole = (props) => {

    const [roleName, setRoleName] = useState('');
    const [selectedPolicies, setSelectedPolicies] = useState([]);

    //store elements
    const { createRole } = props;

    const onRoleNameChange = value => {
        setRoleName(value);
    }

    const onPolicyToggle = (policyName, newValue) => {
        let selectedPoliciesArray = selectedPolicies;
        if (newValue) {
            selectedPoliciesArray.push(policyName);
        } else {
            selectedPoliciesArray = selectedPoliciesArray.filter(p => p.name !== policyName);
        }
        setSelectedPolicies(selectedPoliciesArray);
    }

    const onSaveRole = () => {
        const errorList = controlInputs(roleName, selectedPolicies);

        if (errorList && errorList.length) { return; }

        createRole(roleName, selectedPolicies);
    }

    return <Page backLink='/role' backToWhere='Roles'> 
       <h4>
           New Role
        </h4><hr className='mt-0' />
        {renderRoleName(onRoleNameChange)}
        {renderPolicies(onPolicyToggle)}
        {renderActionButton(onSaveRole)}
    </Page>
}
 
const renderRoleName = (onRoleNameChange) => {
    return (
        <div className='mt-3'>
            <RoleName
                initialValue=''
                isEditable={true}
                onRoleNameChange={onRoleNameChange}
            />
        </div>
    )
}

const renderPolicies = (onPolicyToggle) => {
    return (
        <div>
            <PolicyList onPolicyToggle={onPolicyToggle} />
        </div>
    )
}

const renderActionButton = (onSaveRole) => {
    return (
        <div className='col'>
            <div className='row'>
                <Button variation="primary"
                    onClick={onSaveRole}
                >Save</Button>
            </div>
        </div>
    )
}

const controlInputs = (roleName, policyList) => {
    const errors = [];

    if (!roleName) { errors.push('Role Name is mandatory') };

    if (!policyList || !policyList.length) { errors.push('At least 1 policy must be selected') };

    for (const error of errors) {
        toast.error(error);
    }

    return errors;
}

export default connect(null, { createRole })(CreateRole);