import shipmentDetailTypes from '../type/shipmentDetailsTypes';
import post from '../../core/httpClientSync';

export const searchShipment = (referenceNumber, spinnerElementId = null) => async dispatch => {

    const getShipmentDetailWithOrderItemIdRequest = { referenceNumber };
    const url = 'xms-crawler-api/api/shipment/get-shipment-detail-with-order-item-id';

    const getShipmentDetailWithOrderItemIdResponse = await post(url, getShipmentDetailWithOrderItemIdRequest, spinnerElementId);

    if (getShipmentDetailWithOrderItemIdResponse.isSuccess) {
        dispatch({
            type: shipmentDetailTypes.ADD_SHIPMENT_INFO,
            payload: getShipmentDetailWithOrderItemIdResponse.response.shipment
        })
    }
}

export const getDocsMissingStates = (orderItemId, spinnerId) => async dispatch => {

    const orderItemIdList = [orderItemId];

    const getMissingDocumentStateForListRequest = { orderItemIdList };
    const url = 'xms-shipment-api/api/missingdocument/get-missing-document-state-for-list';
    const getDocsMissingStatesResult = await post(url, getMissingDocumentStateForListRequest, spinnerId);

    if (getDocsMissingStatesResult.isSuccess) {
        dispatch({
            type: shipmentDetailTypes.SET_SHIPMENT_DOCS_MISSING_STATE,
            payload: getDocsMissingStatesResult.response.shipmentMissingDocumentStateFlagValuePairList[0].isMissingDocumentStateFlagSet
        })
    }
}

export const changeMissingDocsState = (orderItemId, missingDocumentFlag, user, spinnerId) => async dispatch => {

    const url = 'xms-shipment-api/api/missingdocument/change-missing-document-state';
    const changeMissingDocsStateRequest = { orderItemId, missingDocumentFlag, user };
    const changeMissingDocsStateResponse = await post(url, changeMissingDocsStateRequest, spinnerId);
    if (changeMissingDocsStateResponse.isSuccess) {
        dispatch({
            type: shipmentDetailTypes.SET_SHIPMENT_DOCS_MISSING_STATE,
            payload: changeMissingDocsStateResponse.response.shipmentMissingDocumentState.missingDocumentFlag
        })
    }
}

export const concatShipment = relativePath => relativePath ? `Shipment/${relativePath}` : 'Shipment';

export const clearShipmentDetailData = () => async dispatch => {
    dispatch({
        type: shipmentDetailTypes.CLEAR_SHIPMENT_DETAIL_DATA
    });
}