import React from 'react';
import LoadingCustomActions from './LoadingCustomActions';

// Nothing changed from loading task at least for now.
const UnloadingCustomActions = ({ task }) => {
    return <>
        <LoadingCustomActions task={task} />
    </>
}

export default UnloadingCustomActions;