/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { searchUnit, resetUnitData } from '../store/action/unitActions';
import SearchForm from '../component/SearchForm/SearchForm';
import PageHeader from '../component/PageHeader/PageHeader';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';

const UnitSearch = ({ unitSearchError, searchUnit, resetUnitData }) => {

    const onSearchHandler = (formValues) => {
        searchUnit(formValues.searchCriteria.trim(), true, 'searchUnit');
    }

    useEffect(() => {
        resetUnitData();
    }, []);

    return (
        <div>
            <PageHeader text={'Unit Search'} backButtonEnabled={false} />
            <SearchForm
                formSubmit={onSearchHandler}
                placeHolder="Enter Unit ID"
                searchError={unitSearchError}
                emptyFieldErrorMessage='Please Enter Unit ID'
            />
            <LoadingIndicator id={'searchUnit'} />
        </div>
    )
}
export default connect(null, { searchUnit, resetUnitData })(UnitSearch);