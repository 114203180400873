const IMAGEControllerActionTypes = Object.freeze({
    CLEAR_IMAGE_VALUES: Symbol('CLEAR_IMAGE_VALUES'),
    ADD_IMAGE_TO_IMAGE_LIST: Symbol('ADD_IMAGE_TO_IMAGE_LIST'),
    REMOVE_IMAGE_FROM_IMAGE_LIST: Symbol('REMOVE_IMAGE_FROM_IMAGE_LIST'),
    IMAGE_UPLOAD_PERCENT_CHANGED: Symbol('IMAGE_UPLOAD_PERCENT_CHANGED'),
    CHANGE_IMAGE_ID: Symbol('CHANGE_IMAGE_ID'),
    SAVE_IMAGE_LIST: Symbol('SAVE_IMAGE_LIST'),
    RESET_IMAGE_DOWNLOAD_STATUS: Symbol('RESET_IMAGE_DOWNLOAD_STATUS'),
    SET_IMAGE_DOWNLOAD_STATUS_TO_DOWNLOADED: Symbol('SET_IMAGE_DOWNLOAD_STATUS_TO_DOWNLOADED'),
    UPDATE_IMAGE_INFO: Symbol('UPDATE_IMAGE_INFO'),
    UPDATE_IMAGE_COMMENT: Symbol('UPDATE_IMAGE_COMMENT'),

});

export default IMAGEControllerActionTypes;