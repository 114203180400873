export const convertEpochToUtcTime = (epochTime) => {
    const utcDate = new Date(epochTime * 1000);

    let utcHours = utcDate.getUTCHours();
    let utcMinutes = utcDate.getMinutes();

    if (utcHours < 10)
        utcHours = '0' + utcHours;

    if (utcMinutes < 10)
        utcMinutes = '0' + utcMinutes;

    return utcHours + ':' + utcMinutes;
};