import React, { useState, useEffect } from 'react'
import TaskProperty from './TaskProperty';
import TaskActions from './TaskActions';
import { Row, Col, Card } from 'react-bootstrap';
import history from '../../../core/history';
import classes from './Task.module.css';
import { Down, Up } from '@dfds-ui/icons';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import { epochToShortDateTime } from '../../../core/epochToDateConverter';
import Badge from '../../BaseComponents/Badge';

const Task = ({ task, badgeArea: BadgeArea, customActions }) => {
    const [showMore, setShowMore] = useState(false);

    const [properties, setProperties] = useState([
        { columnMdSize: '4', columnXsSize: '6', label: 'Date', value: epochToShortDateTime(task.operationDateTime), displayOnSmall: true },
        { columnMdSize: '3', columnXsSize: '6', label: 'Subject', value: task.subjectId, displayOnSmall: true },
        { columnMdSize: '5', columnXsSize: '6', label: 'Type', value: task.type, displayOnSmall: true },
        { columnMdSize: '4', columnXsSize: '6', label: 'Assigned', value: task.performer ? task.performer.name : '-', displayOnSmall: false, classes: 'd-none d-md-block' },
        { columnMdSize: '3', columnXsSize: '6', label: 'Status', value: <>{task.status}</>, displayOnSmall: false, classes: 'd-none d-md-block' },
    ]);

    useEffect(() => {
        if (showMore) {
            setProperties(properties.map(item => ({ ...item, classes: '' })));
        } else {
            setProperties(properties.map(item => ({ ...item, classes: !item.displayOnSmall ? 'd-none d-md-block' : '' })));
        }

    }, [showMore]);

    const getTaskEndpoint = task => {
        return `/unit/${task.subjectId}`;
    }

    const rowClickedHandler = () => {
        history.push(getTaskEndpoint(task));
    }

    const hasCustoms = () => task.details && task.details['hasCustoms'] && task.details['hasCustoms'] === 'true';

    return <>
        {task && <Card className='mb-2'>
            <Card.Body className={[classes['task-row'], classes[task.status]].join(' ')}>
                <Row onClick={rowClickedHandler}>
                    <Col md='9' sm='12' xs='12'>
                        <Row>
                            <Col md={5} xs={6}>
                                <BadgeArea task={task} />
                            </Col>
                            {properties && properties.map(property => <TaskProperty key={property.label} property={property}></TaskProperty>)}
                        </Row>
                    </Col>
                    <Col md='3' sm='12' xs='12'>
                        <ButtonStack className='d-md-none' align='center'>
                            <Button size='small' variation='outlined' onClick={(e) => { e.stopPropagation(); setShowMore(!showMore) }} icon={!showMore ? <Down /> : <Up />}>
                                {!showMore ? 'More' : 'Less'}
                            </Button>
                        </ButtonStack>
                        <TaskActions task={task} customActions={customActions} />
                    </Col>
                </Row>
                <Badge variant='secondary' style={{ fontStyle: 'italic', fontWeight: '100', padding: '4px' }}>{task.businessAreaName}</Badge>
                {hasCustoms() && <span className="ml-2 font-italic">
                    Custom clearance: <strong>Needed</strong>
                </span>}
            </Card.Body>
        </Card>
        }
    </>
}

export default Task;