import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Input, Button, ButtonStack } from '@dfds-ui/react-components';

const ModalEnterGateForOperation = ({ modalData, onHide }) => {
    const [gate, setGate] = useState('');

    const onAssignGateClicked = () => {
        modalData.onAssignGateClickedHandler(gate);
        onHide();
    }

    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Assign gate to operation'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Input name="gate" maxLength={15} placeholder="Enter gate number" value={gate} onChange={e => setGate(e.target.value)} />
            </Modal.Body>

            <Modal.Footer>
                <Row>
                    <Col>
                        <ButtonStack align='right'>
                            <Button variation='primary' className='ml-3' disabled={!gate} onClick={onAssignGateClicked}>Assign</Button>
                        </ButtonStack>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )

}

export default ModalEnterGateForOperation;