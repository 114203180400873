import {formButtonBehaviour, formInputTypes} from "../FormComponentTypes";

export const EditTaskSchema = {
    SubmitButton: {
        Name: 'Update',
        Behaviour: formButtonBehaviour.BUTTON_NONE
    },
    Commands: [
        {
            name: 'Cancel Task',
            callback: 'cancelTask',
            isConfirmationNeeded: true,
            params: ['operationId'],
            ordering: 1
        }
    ],
    Grouping: ['Details'],
    ColumnCountSettings: {
        columnCount: 3,
        isAdaptiveColumn: true
    },
    FormDesign: [
        {
            groupName: 'Hidden',
            ordering: 1,
            isTagVisible: false
        },
        {
            groupName: 'BasicsTop',
            ordering: 2,
            isTagVisible: false
        },
        {
            groupName: 'BasicsBottom',
            ordering: 3,
            isTagVisible: false
        },
        {
            groupName: 'Details',
            ordering: 5,
            isTagVisible: true
        },
        {
            groupName: 'Details1',
            ordering: 6,
            isTagVisible: false
        },
        {
            groupName: 'Notes',
            ordering: 10,
            isTagVisible: true
        }
    ],
    Fields: [
        {
            name: '',
            id: 'operationId',
            type: formInputTypes.HIDDEN_INPUT,
            ordering: 1,
            formDesign: 'Hidden'
        },
        {
            name: 'Business Area',
            id: 'businessAreaId',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'businessAreas',
            firstItemObject: 'businessAreasFirstItem',
            isRequired: true,
            ordering: 2,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Subject',
            id: 'subjectId',
            type: formInputTypes.STRING_INPUT,
            isRequired: true,
            ordering: 4,
            formDesign: 'BasicsTop',
            validations: []
        },
        {
            name: 'Type',
            id: 'operationType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'operationTypes',
            firstItemObject: 'typeFirstItemObject',
            isRequired: true,
            ordering: 5,
            formDesign: 'BasicsBottom',
            validations: []
        },
        {
            name: 'Task Operation Date',
            id: 'operationDate',
            type: formInputTypes.DATE_INPUT,
            isTodayCheck: false,
            isRequired: true,
            ordering: 10,
            formDesign: 'BasicsBottom',
            validations: []
        },
        {
            name: 'Unit Type',
            id: 'unitType',
            type: formInputTypes.COMBO_INPUT,
            listOfItems: 'unitTypes',
            firstItemObject: 'unitTypeFirstItemObject',
            isRequired: true,
            group: 'Details',
            ordering: 15,
            formDesign: 'Details',
            validations: []
        },
        // {
        //     name: 'THU',
        //     id: 'thu',
        //     type: formInputTypes.NUMBER_INPUT,
        //     step: 1,
        //     minValue: 0,
        //     isRequired: true,
        //     group: 'Details',
        //     ordering: 20,
        //     formDesign: 'Basics',
        //     validations: []
        // },
        {
            name: 'Carrier',
            id: 'carrier',
            type: formInputTypes.STRING_INPUT,
            isRequired: false,
            group: 'Details',
            ordering: 25,
            formDesign: 'Details',
            validations: []
        },
        {
            name: 'Rush',
            id: 'rushRequest',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            group: 'Details',
            ordering: 30,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Non Advised',
            id: 'nonAdvised',
            type: formInputTypes.RADIO_INPUT,
            options: [
                {
                    text: 'Yes',
                    value: 'true'
                },
                {
                    text: 'No',
                    value: 'false'
                }
            ],
            isRequired: true,
            group: 'Details',
            ordering: 35,
            formDesign: 'Details1',
            validations: []
        },
        {
            name: 'Admin Notes',
            id: 'adminNotes',
            type: formInputTypes.TEXTAREA_INPUT,
            authorizationWrapper: 'TaskCreateInternalHoc',
            rowCount: 5,
            group: 'Details',
            ordering: 40,
            formDesign: 'Notes',
        },
        {
            name: 'External Notes',
            id: 'externalNotes',
            type: formInputTypes.TEXTAREA_INPUT,
            rowCount: 5,
            group: 'Details',
            ordering: 45,
            formDesign: 'Notes',
        }
    ]
};