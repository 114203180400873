/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button } from '@dfds-ui/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './InlineConfirm.module.css';

const InlineConfirm = ({ icon, confirmText, showTimer = true, timeOut = 5000, onConfirm, onCancel, children }) => {
    const [confirmState, setConfirmState] = useState({
        isPrompting: false,
        currentTimer: null,
        progressStyles: null
    });

    useEffect(() => {
        setConfirmState({
            ...confirmState,
            progressStyles: {
                borderTop: '2px solid',
                marginTop: '-2px',
                width: confirmState.isPrompting ? '0' : '100%',
                transition: transition,
                opacity: confirmState.isPrompting ? 1 : 0
            }
        });
        return () => {
            if (confirmState.currentTimer) {
                clearTimeout(confirmState.currentTimer);
            }
        }
    }, [confirmState.isPrompting]);

    const transition = `${timeOut / 1000}s width linear`;
    const progressBar = () => showTimer ? <div style={confirmState.progressStyles}></div> : null;

    const elementClickedHandler = () => {
        if (!confirmState.isPrompting) {
            const currentTimer = setTimeout(function () {
                resetConfirm();
            }, timeOut);

            setConfirmState({ ...confirmState, isPrompting: true, currentTimer: currentTimer })
        } else {
            clearTimeout(confirmState.currentTimer);
            onConfirm();
            resetConfirm();
        }
    }

    const resetConfirm = () => {
        setConfirmState({
            ...confirmState,
            currentTimer: null,
            isPrompting: false,
        })
    }

    const renderPromptingButton = () => {
        return <Button as='span' variation='outlined'
            size='small'>
            <FontAwesomeIcon icon={icon} />
            <span className='ml-2'>{confirmText}</span>
            <span className={[classes.inlineButton, 'ml-2'].join(' ')} onClick={elementClickedHandler} ><FontAwesomeIcon icon='check' /></span>
            <span className={[classes.inlineButton, 'ml-2'].join(' ')} onClick={resetConfirm} ><FontAwesomeIcon icon='times' /></span>
            {progressBar()}
        </Button>

    }
    return confirmState.isPrompting ?
        renderPromptingButton()
        : <div onClick={elementClickedHandler}>
            {children}
        </div>
}
export default InlineConfirm;