/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    clearShipmentDetailData,
    searchShipment,
    getDocsMissingStates,
    concatShipment,
    changeMissingDocsState
} from '../store/action/shipmentDetailAction';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import ShipmentSummary from '../component/ShipmentDetail/ShipmentSummary';
import ThuDetails from '../component/ShipmentDetail/ThuDetails';
import PageHeader from '../component/PageHeader/PageHeader';
import FileController from '../component/FileController/FileController';
import ImageController from '../component/ImageController';

const ShipmentDetail = (props) => {

    const { shipmentReference, shipmentId } = props.match.params;

    const [isShipmentFetched, setIsShipmentFetched] = useState(false);

    //Redux Elements
    const {
        shipmentInfo,
        docsMissingState,
        clearShipmentDetailData,
        searchShipment,
        getDocsMissingStates,
        changeMissingDocsState
    } = props;

    useEffect(() => {
        clearShipmentDetailData();
    }, [])

    useEffect(() => {
        if (shipmentInfo) { return; }

        if (shipmentReference) {
            searchShipment(shipmentReference, 'searchShipment');
            setIsShipmentFetched(true);
        }

    }, [shipmentInfo])

    useEffect(() => {
        if (!isShipmentFetched) { return; }
        if (!shipmentInfo) { return; }

        getDocsMissingStates(shipmentReference, 'getFileList');

    }, [shipmentInfo, isShipmentFetched])

    const onToggleDocsMissingState = () => {
        const newMissingDocsState = docsMissingState ? false : true;
        changeMissingDocsState(shipmentReference, newMissingDocsState, null, 'getFileList');
    }

    return (
        <div>
            {renderPageHeader(shipmentInfo)}
            <ShipmentSummary shipment={shipmentInfo} />
            <div id="accordion">
                {renderFileController(shipmentInfo, docsMissingState, onToggleDocsMissingState)}
                {renderImageController(shipmentInfo)}
                {/* {renderCargoList(shipmentReference, shipmentId)} */}
            </div>
            <LoadingIndicator id='searchShipment' />
        </div>
    )
}

const renderImageController = (searchedShipment) => {

    if (!searchedShipment) { return; }

    const imageControllerOptions = {
        relativePath: [`image/shipment/${searchedShipment.referenceNumber}`],
        relativePathForFetch: `image/shipment/${searchedShipment.referenceNumber}`,
        redirectUrl: `/shipment/${searchedShipment.referenceNumber}/${searchedShipment.id}`,
        sourceType: 'shipment',
        sourceKey: searchedShipment.referenceNumber,
        sourceId: searchedShipment.id
    }

    return (
        <ImageController
            imageControllerOptions={imageControllerOptions}
        />
    )
}

const renderPageHeader = shipmentInfo => {

    if (!shipmentInfo) { return <PageHeader text='' /> }

    return <PageHeader text={shipmentInfo.id} />
}

const renderFileController = (searchedShipment, docsMissingState, onToggleDocsMissingState) => {

    if (!searchedShipment) { return; }

    const concatenatedShipmentPath = concatShipment(searchedShipment.referenceNumber);
    const operationButtonsToShow = { addFile: true, download: true, upload: true, missingDocuments: true, shareDocuments: true };

    const uploadOptions = {
        uploadFileRelativePath: concatenatedShipmentPath,
        uploadFileRelativePathAsArray: [concatenatedShipmentPath],
        uploadData: searchedShipment
    };

    const downloadOptions = {
        downloadAllFilePathList: [concatenatedShipmentPath],
        downloadFileRelativePath: [concatenatedShipmentPath]
    };

    const deleteOptions = { deleteFileRelativePath: [concatenatedShipmentPath] };

    const missingDocumentOptions = {
        docsMissingStateKey: searchedShipment.referenceNumber,
        hasDocsMissingFlagSet: docsMissingState,
        onToggleDocsMissingState
    }

    const shareOptions = {
        actionKey: searchedShipment.referenceNumber,
        actionType: 'download-shipment-documents'
    }

    return (
        <FileController
            operationButtonsToShow={operationButtonsToShow}
            uploadOptions={uploadOptions}
            missingDocumentOptions={missingDocumentOptions}
            deleteOptions={deleteOptions}
            downloadOptions={downloadOptions}
            onToggleDocsMissingState={onToggleDocsMissingState}
            shareOptions={shareOptions}
        />
    );
}

const renderCargoList = (shipmentReferenceNumber, shipmentId) => {

    return (
        <ThuDetails
            shipmentReferenceNumber={shipmentReferenceNumber}
            shipmentId={shipmentId}
        />
    )
}

const mapStateToProps = ({ shipmentDetail }) => {
    return {
        shipmentInfo: shipmentDetail.shipmentInfo,
        docsMissingState: shipmentDetail.docsMissingState
    }
}

const mapActionToProps = {
    clearShipmentDetailData,
    searchShipment,
    getDocsMissingStates,
    changeMissingDocsState
}

export default connect(mapStateToProps, mapActionToProps)(ShipmentDetail);