import React from 'react';

const ReadonlyTextField = (label, value) => {
    return (<>
        <div className='col-md-6'>
            <div className='form-group'>
                <label className='text-muted'>{label}</label>
                <input type='text' className='form-control form-control-sm' disabled value={value} />
            </div>
        </div>
    </>)
}
export default ReadonlyTextField;