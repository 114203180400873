import fileControllerTypes from '../type/fileController';
import httpClient from '../../core/httpclient';
import postSync, { get } from '../../core/httpClientSync';
import _ from 'lodash';
import { spinnerService } from "@simply007org/react-spinners";
import modalTypes from '../type/modal';

const apiUrl = 'xms-document-api/api/document';

export const addFileToFileList = (newFile) => {
    return {
        type: fileControllerTypes.ADD_FILE_TO_FILELIST,
        payload: newFile
    };
}

export const uploadSingleFile = (fileToUpload, uploadData, relativePath) => async dispatch => {
    const formData = new FormData();
    formData.append('File', fileToUpload, fileToUpload.name);
    formData.append('Data', JSON.stringify(uploadData));
    formData.append('FileName', fileToUpload.name);
    formData.append('Path', relativePath);
    formData.append('sourceType', 'document');

    const config = {
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (percentCompleted < 100) {
                fileToUpload.status = 'uploading';
                fileToUpload.uploadPercentage = percentCompleted;
                dispatch({
                    type: fileControllerTypes.FILE_UPLOAD_PERCENT_CHANGED,
                    payload: { fileToUpload, percentCompleted }
                })
            } else if (fileToUpload.status !== 'finishing') {
                fileToUpload.status = 'finishing';
                fileToUpload.uploadPercentage = percentCompleted;
                dispatch({
                    type: fileControllerTypes.FILE_UPLOAD_PERCENT_CHANGED,
                    payload: { fileToUpload, percentCompleted }
                })
            }
        }
    };

    const endPoint = `${apiUrl}/uploadFiles`;

    httpClient.post(endPoint, formData, config)
        .then(function (res) {
            fileToUpload.status = 'uploaded';
            dispatch({
                type: fileControllerTypes.FILE_UPLOAD_PERCENT_CHANGED,
                payload: { fileToUpload, percentCompleted: fileToUpload.uploadPercentage }
            })

            dispatch({
                type: fileControllerTypes.CHANGE_FILE_ID,
                payload: { oldId: fileToUpload.uid, newId: res.data.result.uid }
            })
        })
        .catch(function (err) {
            console.log(err);
        });
}

export const getFileList = (relativePathList, spinnerId) => async dispatch => {

    dispatch({
        type: fileControllerTypes.CLEAR_FILELIST
    })

    const endPoint = `${apiUrl}/getFileList`;
    const getFileListRequest = { RelativePaths: relativePathList };

    const postResult = await postSync(endPoint, getFileListRequest, spinnerId);
    if (postResult.isSuccess) {
        const responseFileList = postResult.response[0].files;
        for (let index = 0; index < responseFileList.length; index++) {
            responseFileList[index] = _.omit({ ...responseFileList[index] }, 'data');
            responseFileList[index].status = 'uploaded';
            responseFileList[index].name = responseFileList[index].fileName;
        }
        dispatch({
            type: fileControllerTypes.SAVE_FILE_LIST,
            payload: responseFileList
        })
    }
}

export const deleteFile = (fileToDelete, relativePath, spinnerId) => async dispatch => {
    if (fileToDelete.status === 'uploaded') {
        if (spinnerId) { spinnerService.show(spinnerId) }
        const deleteUrl = `${apiUrl}/deleteFile/?relativePath=${relativePath}&uid=${fileToDelete.uid}`
        httpClient.delete(deleteUrl)
            .then(res => {
                dispatch({
                    type: fileControllerTypes.REMOVE_FILE_FROM_FILELIST,
                    payload: fileToDelete
                })
                if (spinnerId) { spinnerService.hide(spinnerId) }
            })
            .catch(function (err) {
                if (spinnerId) { spinnerService.hide(spinnerId) }
                console.log(err);
            });
    } else {
        dispatch({
            type: fileControllerTypes.REMOVE_FILE_FROM_FILELIST,
            payload: fileToDelete
        })
    }
}

export const downloadSingleFile = (fileToDownload, relativePath, spinnerId) => async dispatch => {
    const endPoint = `${apiUrl}/getFile/?relativePath=${relativePath}&uid=${fileToDownload.uid}`;

    if (spinnerId) { spinnerService.show(spinnerId) }
    httpClient.get(endPoint, { responseType: 'blob' })
        .then(blob => {
            downloadBlobData(blob, fileToDownload.name);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        })
        .catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}

export const showFile = (file, spinnerId) => async dispatch => {
    if (file.status == "uploaded") {
        const endPoint = `${apiUrl}/getFile/?relativePath=${file.relativePath}&uid=${file.uid}`;

        if (spinnerId) { spinnerService.show(spinnerId) }
        httpClient.get(endPoint, { responseType: "blob" }).then(blob => {

            dispatch(showFileModal(blob.data, file.name, file.status));

            if (spinnerId) { spinnerService.hide(spinnerId) }
        }).catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
    } else {
        dispatch(showFileModal(file, file.name, file.status));
    }
}

export const downloadAllDocuments = (relativePathList, downloadFileName, spinnerId) => async dispatch => {
    const endPoint = `${apiUrl}/getFiles`;
    if (spinnerId) { spinnerService.show(spinnerId) }

    httpClient.post(endPoint, relativePathList, { responseType: 'blob' })
        .then(blob => {
            downloadBlobData(blob, downloadFileName);
            dispatch({ type: fileControllerTypes.SET_FILE_DOWNLOAD_STATUS_TO_DOWNLOADED })
            dispatch(() => { if (spinnerId) { spinnerService.hide(spinnerId) } })
        })
        .catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}

export const showCoverPage = (coverPageRequest, downloadFileName, spinnerId) => async dispatch => {

    const downloadCoverPageUrl = `${apiUrl}/getsummary`;
    if (spinnerId) { spinnerService.show(spinnerId) };

    httpClient.post(downloadCoverPageUrl, coverPageRequest, { responseType: 'blob' })
        .then(blob => {

            dispatch(showFileModal(blob.data, downloadFileName, 'uploaded'));

            if (spinnerId) { spinnerService.hide(spinnerId) }
        })
        .catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}

export const downloadBlobData = async (blobData, downloadFileName) => {
    var extension = "." + blobData.data.type.split('/')[1];
    if (downloadFileName.indexOf(extension) == -1) {
        downloadFileName = downloadFileName + extension;
    }

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blobData.data, downloadFileName);
    } else {
        var data = new Blob([blobData.data], { type: blobData.data.type });
        var csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', downloadFileName);
        tempLink.click();
    }
}

export const clearDownloadStatus = () => async dispatch => {
    dispatch({
        type: fileControllerTypes.RESET_FILE_DOWNLOAD_STATUS
    })
}

export const resetFileControllerStoreElements = () => async dispatch => {
    dispatch({
        type: fileControllerTypes.CLEAR_FILELIST
    })
}

const showFileModal = (data, fileName, status) => async dispatch => {

    let file = {
        data: data,
        url: URL.createObjectURL(data),
        name: fileName,
        status: status
    }

    dispatch({
        type: modalTypes.SHOW_MODAL,
        payload: {
            modalType: 'show-file',
            modalData: { file: file }
        }
    })
}
