import isHandlerEnabled from './interceptor.checker';

const authenticationInterceptorHandler = (request) => {
    if (isHandlerEnabled(request)) {
        request.headers.common['Authorization'] = 'Bearer ' +  localStorage.getItem('access_token');
    }
    return request;
}

export default authenticationInterceptorHandler;

//TODO: Local store yerine şunu implemente etmek lazım
//https://michaelwashburnjr.com/best-way-to-store-tokens-redux/