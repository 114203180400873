/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap"; 
import {
  getBookings,
  markAsArrived,
  cancelBooking,
  markAsUnArrived,
  getArrivalReceiptDetails,
} from "../store/action/bookingActions";
import history from "../core/history";
import BookingsTable from "../component/BookingTable/BookingsTable";
import { Button } from "@dfds-ui/react-components";
import Page from "../component/Page/Page";
import { TabCollection } from "../component/BaseComponents/TabCollection";
import { utcToEpoch } from "../core/utcToEpochConverter";
import moment from 'moment';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const dateTabs = [
  utcToEpoch(moment().toDate()),
  utcToEpoch(moment().add(1, 'day').toDate()),
  utcToEpoch(moment().add(2, 'day').toDate()),
  utcToEpoch(moment().add(3, 'day').toDate())
];

const ListBookings = ({ getBookings, bookings, isRefreshData, markAsArrived, cancelBooking, markAsUnArrived, getArrivalReceiptDetails }) => {
  const [selectedDate, setSelectedDate] = useState(utcToEpoch(moment().toDate()));
  
  useEffect(() => {
    getBookings(selectedDate);
  }, [selectedDate]);

  const dateChangedHandler = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if (isRefreshData) {
      getBookings(selectedDate);
    }
  }, [isRefreshData]);

  const markAsArrivedClickedHandler = (bookingId) => {
    markAsArrived(bookingId);
  };

  const arrivalReceiptHandler = (bookingId) => {
    getArrivalReceiptDetails(bookingId);
  };

  const markAsUnArrivedClickedHandler = (bookingId) => {
    //TODO: Change with confirm modal when anatolia push it to master
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Booking will be marked as unarrived. Continue?")) {
      markAsUnArrived(bookingId);
    }
  };

  const editBookingClickedHandler = (bookingId) => {
    history.push(`/booking/${bookingId}`);
  };

  const cancelBookingClickedHandler = (bookingId) => {
    //TODO: Change with confirm modal when anatolia push it to master
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Booking (" + bookingId + ") will be cancelled. Continue?")) {
      cancelBooking(bookingId);
    }
  };

  return <Page>
    <Row>
      <Col>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <div style={{ flex: '1' }}>
            <h4 className='mb-0'>Bookings</h4>
          </div>
          <div>
            <Button title="Create Booking"
              icon={<FontAwesomeIcon icon='plus' />}
              onClick={() => {
                history.push("/booking/new");
              }}>Create Booking</Button>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div style={{ marginBottom: '40px' }}> </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <TabCollection
          tabItemStyle={{ 'height': '2rem', fontSize: 'medium' }}
          selectedTab={selectedDate}
          tabs={dateTabs}
          customTabRenderer={(tab) => moment.utc(tab * 1000).calendar(null, {
            lastDay: '[YESTERDAY] - MMM Do',
            sameDay: '[TODAY] - MMM Do',
            nextDay: '[TOMORROW] - MMM Do',
            nextWeek: 'dddd - MMM Do',
            sameElse: 'dddd - MMM Do'
          }).toLocaleUpperCase()}
          onTabClickedEvent={dateChangedHandler}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <BookingsTable
          data={bookings}
          canMarkAsArrived={true}
          canEditBooking={true}
          canSeeAdminNotes={true}
          markAsUnArrivedClickedEvent={markAsUnArrivedClickedHandler}
          markAsArrivedClickedEvent={markAsArrivedClickedHandler}
          editBookingClickedEvent={editBookingClickedHandler}
          cancelBookingClickedHandler={cancelBookingClickedHandler}
          arrivalReceiptHandler={arrivalReceiptHandler}
        />
      </Col>
    </Row>
  </Page>
};
const mapStateToProps = (state) => {
  return {
    bookings: state.booking.bookings,
    isRefreshData: state.booking.isRefreshData,
  };
};
export default connect(mapStateToProps, {
  getBookings,
  markAsArrived,
  cancelBooking,
  markAsUnArrived,
  getArrivalReceiptDetails,
})(ListBookings);
