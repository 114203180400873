/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fillBookingFormById, fillBookingFormData, clearBookingState, selectTimeSlot, getUnitTypesForBooking, getUnitTypes, getBusinessAreas } from '../store/action/bookingActions';
import history from '../core/history';
import '../core/schema.css';
import './BookingCommon.css';
import { Row, Col } from 'react-bootstrap';
import PageHeader from '../component/PageHeader/PageHeader';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { isOnlyExternalUser } from "../core/authentication";
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import { UpdateBookingSchema } from "../component/FormComponents/Schema/updateBooking";
import Page from '../component/Page/Page';

const UpdateBookingContainer = ({ match, authenticatedUser, getBusinessAreas, businessAreas, clearBookingState, fillBookingFormData, fillBookingFormById, formData, selectTimeSlot, getUnitTypesForBooking, unitTypesForBooking, isBookingRetrieving }) => {

    const bookingId = match.params.bookingid;

    useEffect(() => {
        clearBookingState()
    }, []);

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }

        if (!unitTypesForBooking) {
            getUnitTypesForBooking();
        }
    }, [businessAreas, unitTypesForBooking]);

    useEffect(() => {

        if (!isBookingRetrieving && businessAreas && unitTypesForBooking) {
            fillBookingFormById(bookingId);
        }
    }, [businessAreas, unitTypesForBooking]);

    useEffect(() => {
        if (!formData) {
            return;
        }
        const bookingCarrier = formData.carrier;
        if (!isInternalUser() && bookingCarrier !== authenticatedUser.company) {
            history.push('/noAuthorization');
        }

    }, [formData]);

    const isInternalUser = () => {
        return !isOnlyExternalUser();
    }

    const submitAttributesForBooking = (formData) => {
        fillBookingFormData(formData);
        selectTimeSlot(formData.date, formData.dateTime);
        history.push(`/booking/${bookingId}/confirm`);
    }

    const getOperationTypeList = () => {
        return [
            { value: "false", text: "Loading" },
            { value: "true", text: "Unloading" }
        ];
    };

    return <Page hasBackButton>
        {formData && <Col lg={12} sm={8} xs={12}>
            <SchemaInterpreter
                title='Update Booking'
                form={"UpdateBooking"}
                onSubmit={submitAttributesForBooking}
                formItem={formData}
                formSchema={UpdateBookingSchema}
                operationTypes={getOperationTypeList()}
                typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
                businessAreas={businessAreas}
                businessAreasFirstItem={{ value: '', text: 'Choose Business Area' }}
                unitTypes={unitTypesForBooking}
                unitTypeFirstItemObject={{ value: '', text: 'Choose Unit Type' }}
            />
        </Col>}
        <LoadingIndicator id="createOrUpdateBooking" />
        <LoadingIndicator id="getBookingByCode" />
    </Page>

}

const mapStateToProps = (state) => {
    return {
        formData: state.booking.formData,
        authenticatedUser: state.auth.authenticatedUser,
        businessAreas: state.booking.businessAreas,
        unitTypesForBooking: state.booking.unitTypesForBooking,
        isBookingRetrieving: state.booking.isBookingRetrieving
    }
}
export default connect(mapStateToProps, {
    fillBookingFormData, fillBookingFormById, selectTimeSlot, getBusinessAreas, getUnitTypes, getUnitTypesForBooking, clearBookingState,
})(UpdateBookingContainer);
