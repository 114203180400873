import shipmentDetailsTypes from '../type/shipmentDetailsTypes';

const initialState = {
    shipmentInfo: null,
    docsMissingState: null
}

export default (state = initialState, action) => {

    switch (action.type) {
        case shipmentDetailsTypes.ADD_SHIPMENT_INFO:
            return {
                ...state,
                shipmentInfo: action.payload
            }
        case shipmentDetailsTypes.SET_SHIPMENT_DOCS_MISSING_STATE:
            return {
                ...state,
                docsMissingState: action.payload
            }
        case shipmentDetailsTypes.CLEAR_SHIPMENT_DETAIL_DATA:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }

}