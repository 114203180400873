const notificationActions = Object.freeze({
    NOTIFICATION_GETSUFFIXLIST_SUCCESS: Symbol('NOTIFICATION_GETSUFFIXLIST_SUCCESS'),
    NOTIFICATION_GETSUFFIX_SUCCESS: Symbol('NOTIFICATION_GETSUFFIX_SUCCESS'),
    NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS: Symbol('NOTIFICATION_GETEMAILSFORSUFFIX_SUCCESS'),
    NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS: Symbol('NOTIFICATION_ADDEMAILFORSUFFIX_SUCCESS'),
    NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS: Symbol('NOTIFICATION_REMOVEEMAILFORSUFFIX_SUCCESS'),
    GET_COUNTRIES: Symbol('GET_COUNTRIES'),
    SAVE_SUFFIX: Symbol('SAVE_SUFFIX'),
    CLEAR_STATE: Symbol('CLEAR_STATE'),
});

export default notificationActions;