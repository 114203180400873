import React, { useState } from 'react';

const RoleName = (props) => {

    const { initialValue, isEditable } = props;
    const { onRoleNameChange } = props;

    const [roleName, setRoleName] = useState(initialValue);

    const roleNameChanged = event => {
        const newValue = event.target.value;
        setRoleName(newValue);
        onRoleNameChange(newValue);
    }

    return (
        <>
            <input
                type='text'
                className='form-control'
                value={roleName}
                disabled={!isEditable}
                onChange={roleNameChanged}
                placeholder={roleName ? '' : 'New Role Name'}
            ></input>
            <hr />
        </>
    )
}

export default RoleName;