import types from '../type/actionUrl';
const initialState = {
    actionUrlInfo: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.ACTIONURL_GETACTIONURLINFO_SUCCESS:
            return {
                ...state,
                actionUrlInfo: action.payload.actionUrl
            };
        default:
            return state;
    }

}