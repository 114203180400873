/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRule, getRules, saveRule, finalizeAddOrUpdate, fillBookingSchema, getBusinessAreas, getUnitTypes } from '../store/action/reservedSlotActions';
import { Row, Col, FormLabel, Alert, Form } from 'react-bootstrap';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AddOrEditCalendarRule.css';
import history from '../core/history';
import moment from 'moment';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import BookingForm from '../component/BookingForm/BookingForm';
import { weekDaysWithEveryday } from '../core/utility';
import Page from '../component/Page/Page';

const AddOrEditReservedSlotRule = (props) => {
    //Redux actions
    const { getRule, getRules, saveRule, fillBookingSchema, finalizeAddOrUpdate, getBusinessAreas, getUnitTypes } = props;

    //Redux store
    let { rule, rules, businessAreas, unitTypes, formSchema, isSaveRuleSuccess } = props;

    //Other props
    const { match } = props;

    const [state, setState] = useState({
        startDate: null,
        endDate: null,
        startMinutesSinceMidnight: null,
        endMinutesSinceMidnight: null,
        unitNo: '',
        name: '',
        surname: '',
        email: '',
        thuAmount: 0,
        company: '',
        operationType: 1,
        phoneNumber: '',
        transportNumbers: null,
        administratorNote: '',
        userNote: '',
        dayOfWeek: 'Everyday',
        errors: null,
        selectedBusinessAreaId: null,
        hasDangerousGoods: false,
        hasCustoms: false,
        unitTypeId: null
    });

    const ruleId = match.params.ruleid;

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }
    }, [businessAreas]);

    useEffect(() => {
        if (!unitTypes) {
            getUnitTypes();
        }

        if (!formSchema && unitTypes) {
            fillBookingSchema(unitTypes);
        }
    }, [formSchema, unitTypes]);

    useEffect(() => {
        if (!rules) {
            getRules();
        }
        if (rules && ruleId) {
            getRule(rules, ruleId);
        }

        return () => {
            //Clear rule state
            getRule(null);
        }
    }, [rules]);

    useEffect(() => {
        if (isSaveRuleSuccess) {
            finalizeAddOrUpdate();
            history.push('/booking/reserved-slot-rule');
        }
    }, [isSaveRuleSuccess]);

    useEffect(() => {
        if (rule) {
            setState({
                ...rule,
                startMinutesSinceMidnight: moment().hour(rule.startMinutesSinceMidnight / 60).minute(rule.startMinutesSinceMidnight % 60),
                endMinutesSinceMidnight: moment().hour(rule.endMinutesSinceMidnight / 60).minute(rule.endMinutesSinceMidnight % 60),
                startDate: new Date(rule.startDate * 1000),
                endDate: new Date(rule.endDate * 1000),
                transportNumbers: rule.transportNumbers ? rule.transportNumbers.join(',') : '',
                isUnloading: rule.operationType === 'Loading' ? false : true,
                selectedBusinessAreaId: rule.businessAreaId
            });
        }

    }, [rule])


    const dateChangedHandler = (dateType, value) => {
        switch (dateType) {
            case 'start':
                setState((previousState, props) => ({
                    ...previousState,
                    startDate: value
                }));
                break;

            case 'end':
                setState((previousState, props) => ({
                    ...previousState,
                    endDate: value
                }));
                break;
            default:
                break;
        }
    }

    const startTimeChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            startMinutesSinceMidnight: value
        }));
    }

    const endTimeChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            endMinutesSinceMidnight: value,
        }));
    }

    const weekdayChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            dayOfWeek: value
        }));
    }

    const saveClickedHandler = (schema) => {
        const formData = schema.formData;
        if (checkForm()) {
            //No need to update state again. It will be reset.

            const ruleModel = {
                ...state,
                unitNo: formData.unitNo,
                name: formData.name,
                surname: formData.surname,
                email: formData.email,
                thuAmount: formData.thuAmount,
                company: formData.carrier,
                operationType: formData.isUnloading ? 1 : 0,
                phoneNumber: formData.phoneNumber,
                transportNumbers: formData.transportNumbers ? [formData.transportNumbers] : null,
                note: formData.administratorNote,
                hasDangerousGoods: formData.hasDangerousGoods,
                hasCustoms: formData.hasCustoms,
                unitTypeId: formData.unitTypeId

            };

            saveRule(ruleModel);
        }
    }

    const businessAreaChangedHandler = (value) => {
        setState({ ...state, selectedBusinessAreaId: value });
    }

    const checkForm = () => {
        const errors = [];
        if (state.startMinutesSinceMidnight >= state.endMinutesSinceMidnight) {
            errors.push('Time range is invalid. End time should be greater than start time.');
        }

        if (!state.selectedBusinessAreaId) {
            errors.push('Business area is required.');
        }

        if (!state.startDate) {
            errors.push('Start time is required.');
        }

        if (!state.endDate) {
            errors.push('End time is required.');
        }

        if (state.startDate > state.endDate) {
            errors.push('End time must be greater than start time.');
        }

        setState({
            ...state,
            errors: errors
        });
        return errors.length === 0;
    }

    const transformErrors = (errors) => {
        return errors.map(error => {
            if (error.name === 'minimum') {
                error.message = 'Cannot be negative number.';
                error.stack = 'THU should be greater than 0 (zero).';
            }
            return error;
        });
    }

    const renderErrors = () => {
        return <>
            {
                state.errors ? state.errors.map(error => (<Alert variant='danger'>{error}</Alert>)) : null
            }
        </>
    }

    const renderDate = (type) => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>{type} Date: </FormLabel>
                </Col>
                <Col md='4'>
                    <DatePicker className='form-control' selected={type === 'Start' ? state.startDate : state.endDate} onChange={(value) => dateChangedHandler(type.toLowerCase(), value)} />
                </Col>
            </Row>
            <br />
        </>
    }

    const renderTimeRange = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Time Range: </FormLabel>
                </Col>
                <Col md='2'>
                    <TimePicker value={state.startMinutesSinceMidnight} onChange={(e) => startTimeChangedHandler(e)} showSecond={false} minuteStep={30} />
                </Col>

                <Col md='2'>
                    <TimePicker value={state.endMinutesSinceMidnight} onChange={(e) => endTimeChangedHandler(e)} showSecond={false} minuteStep={30} />
                </Col>
            </Row>
            <br />
        </>
    }

    const renderWeekday = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Day of Week: </FormLabel>
                </Col>
                <Col md='4'>
                    <Form.Control value={state.dayOfWeek} onChange={(e) => weekdayChangedHandler(e.target.value)} md='3' as='select'>
                        {weekDaysWithEveryday.map((day, i) => (<option key={day} value={day}>{day}</option>))}
                    </Form.Control>
                </Col>
            </Row>
            <br />
        </>
    }

    const renderBusinessArea = () => {
        return businessAreas ? <>
            <Row className="justify-content-md-center">
                <Col md="2">
                    <FormLabel>Business Area: </FormLabel>
                </Col>
                <Col md="4">
                    <Form.Control value={state.selectedBusinessAreaId} onChange={(e) => businessAreaChangedHandler(e.target.value)} md="3" as="select">
                        <option value="">Choose...</option>
                        {businessAreas.map(businessArea => (<option key={businessArea.id} value={businessArea.id}>{businessArea.name}</option>))}
                    </Form.Control>
                </Col>
            </Row>
            <br />
        </> : null;
    }


    const renderForm = () => {
        if (!formSchema || !unitTypes) return null;
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Form Data: </FormLabel>
                </Col>
                <Col md='4'>
                    <BookingForm formData={state} schemaJson={formSchema} transformErrors={transformErrors} buttonText='SAVE' formSubmitHandler={saveClickedHandler} />
                </Col>
            </Row>
            <br />
        </>
    }
    return <Page backLink='/booking/reserved-slot-rule' backToWhere='Reserved Slot Rules' >
        <h4>
            {ruleId && ruleId === 'new' ? 'Create Rule' : 'Edit Rule'}
        </h4><hr className='mt-0' />
        <Row className='justify-content-md-center'>
            <Col md='6'>
                {renderErrors()}
            </Col>
        </Row>
        {renderBusinessArea()}
        {renderTimeRange()}
        {renderDate('Start')}
        {renderDate('End')}
        {renderWeekday()}
        {renderForm()}
        <LoadingIndicator id='createRule' />
        <LoadingIndicator id='getRules' />
    </Page>
}

const mapStateToProps = state => {
    return {
        rule: state.reservedSlot.rule,
        rules: state.reservedSlot.rules,
        formSchema: state.reservedSlot.formSchema,
        isSaveRuleSuccess: state.reservedSlot.isSaveRuleSuccess,
        businessAreas: state.reservedSlot.businessAreas,
        unitTypes: state.reservedSlot.unitTypes
    }
}

const mapDispatchToProps = {
    getRule,
    saveRule,
    getRules,
    fillBookingSchema,
    finalizeAddOrUpdate,
    getBusinessAreas,
    getUnitTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditReservedSlotRule);