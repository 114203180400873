/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ImageControllerOperationButtons from './ImageControllerOperationButtons';
import ImageList from './ImageList';
import uuidV1 from 'uuid/v1';
import {
    clearImageControllerValues,
    addImageToImageList,
    uploadSingleImage,
    retrieveImages
} from '../../store/action/imageControllerActions';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const ImageController = (props) => {

    // from parent component
    const {
        imageControllerOptions
    } = props;

    //from store
    const {
        imageList,
        clearImageControllerValues,
        addImageToImageList,
        uploadSingleImage,
        retrieveImages
    } = props;

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => { if (errorMessage) { setTimeout(() => { setErrorMessage(''); }, 3000); } }, [errorMessage])

    useEffect(() => { clearImageControllerValues() }, [])

    useEffect(() => {
        if (imageList) { return; }

        retrieveImages(imageControllerOptions.relativePathForFetch, 'imageControllerPanel');
    }, [imageList])

    const onImageSelected = (selectedImages) => {
        const selectedImageList = Object.values(selectedImages);
        const checkResult = checkSelectedFiles(selectedImageList, imageList);
        if (checkResult) {
            setErrorMessage(checkResult);
            return;
        }
        addImagesToCurrentList(selectedImageList, addImageToImageList)
    }

    const onUploadImages = () => {

        if (!imageList) { return; }

        const imageListAsArray = Object.values(imageList);
        if (!imageListAsArray.length) { return; }

        const notUploadedImageList = imageListAsArray.filter(im => im.status === 'not-uploaded');

        for (const notUploadedImage of notUploadedImageList) {
            const spinnerElementId = `imageItemList_${notUploadedImage.uid}`;
            const uploadData = notUploadedImage.comment ? { comment: notUploadedImage.comment } : {};
            uploadSingleImage(notUploadedImage, uploadData, imageControllerOptions.relativePath, spinnerElementId);
        }

    }

    return (
        <div className='card'>
            {renderAccordionHeader(imageList)}
            <div id="collapseImages" className="collapse">
                <div className='card card-body'>
                    <div className='row'>
                        {renderOperationButtons(onImageSelected, onUploadImages)}
                        {renderErrorMessage(errorMessage)}
                    </div>
                    <hr />
                    <ImageList imageControllerOptions={imageControllerOptions} />
                </div>
            </div>
            <LoadingIndicator id='imageControllerPanel' />
        </div>
    )
}

const renderAccordionHeader = (imageList) => {

    let imageCount = 0;
    if (imageList) {
        imageCount = Object.values(imageList).length;
    }

    return (
        <div className='card-header collapsed' href="#collapseImages" data-toggle="collapse" data-parent="#accordion">
            <h5 className='mb-0'>
                <div >
                    <img src="/image/icon/down.svg" className="color-action-blue" width="20px" alt='' /> Images ({imageCount})
                </div>
            </h5>
        </div>
    )
}

const renderOperationButtons = (onImageSelected, onUploadImages) => {
    return (
        <>
            <ImageControllerOperationButtons
                imageSelected={onImageSelected}
                uploadImages={onUploadImages}
            />
        </>
    )
}

const renderErrorMessage = (errorMessage) => {

    if (!errorMessage) { return; }

    return (
        <div className='d-flex flex-column'>
            <h6 className='text-danger my-auto'>{errorMessage}</h6>
        </div>
    )

}

const checkSelectedFiles = (selectedImageList, currentImageList) => {

    const currentImageListAsArray = currentImageList ? Object.values(currentImageList) : [];

    const nonJpgFiles = selectedImageList.some(i => !i.name.toLowerCase().endsWith('jpg') && !i.name.toLowerCase().endsWith('jpeg'));
    if (nonJpgFiles) { return ('Select only files with jpg/jpeg extensions'); }

    for (const imageFile of selectedImageList) {
        const isImageAlreadyExists = currentImageListAsArray.some(im =>
            im.lastModified === imageFile.lastModified &&
            im.name === imageFile.name &&
            im.size === imageFile.size
        )

        if (isImageAlreadyExists) {
            return (`Image with name ${imageFile.name} already in image list`);
        }
    }

    return;
}

const addImagesToCurrentList = (selectedImageList, addImageToImageList) => {

    for (const selectedImage of selectedImageList) {
        selectedImage.status = 'not-uploaded';
        selectedImage.uid = uuidV1();
        selectedImage.comment = '';
        addImageToImageList(selectedImage);
    }
}

const mapStateToProps = ({ imageController }) => {
    return {
        imageList: imageController.imageList
    }
}

const mapActionToProps = {
    clearImageControllerValues,
    addImageToImageList,
    uploadSingleImage,
    retrieveImages
}


export default connect(mapStateToProps, mapActionToProps)(ImageController);