import React from 'react';
import StyledCard from '../StyledCard';
import { Card } from 'react-bootstrap';
import EntityHeader from '../EntityHeader';
import EntityChange from '../EntityChange/EntityChange';
import { Accordion } from 'react-bootstrap';

const AddedOrUpdatedEntity = ({ diff }) => {
    return <StyledCard>
        <Accordion.Toggle as={Card.Header} eventKey={diff.id}>
            <EntityHeader diff={diff} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={diff.id}>
            <Card.Body>
                {diff.changes && diff.changes.map(change => <EntityChange key={change.propertyName} change={change} objectType={diff.objectType} action={diff.action} />)}
            </Card.Body>
        </Accordion.Collapse>
    </StyledCard>
}

export default AddedOrUpdatedEntity;