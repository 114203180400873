import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Col, Button } from 'react-bootstrap';

const ReduxFormControl = ({ input, meta: { touched, error, warning }, ...props }) => {
    return <><Form.Control {...props} {...input} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span className="text-warning">{warning}</span>))}
    </>
};
const checkName = value => value && value === 'new' ? `Code cannot be "new".` : undefined;
const required = value => value ? undefined : 'Required';

let ServiceForm = ({ isNewService, handleSubmit, pristine, submitting }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Service code</Form.Label>
                    <Field
                        name="code"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Service code"
                        disabled={!isNewService}
                        validate={[required, checkName]}
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Name</Form.Label>
                    <Field
                        name="name"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Name"
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Description</Form.Label>
                    <Field
                        name="description"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Description"
                        required
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Operation Type</Form.Label>
                    <Field
                        name="operationType"
                        component={ReduxFormControl}
                        type="text"
                        placeholder="Operation type"
                        required
                    />
                </Form.Group>
            </Form.Row>
            <div>
                <Button type="submit" disabled={pristine || submitting}>Save</Button>
            </div>
        </Form>
    );
};


ServiceForm = reduxForm({
    form: 'insertOrUpdateServiceForm', // a unique identifier for this form
})(ServiceForm);
ServiceForm = connect(
    state => ({
        initialValues: state.service.service // pull initial values from service reducer
    }),
)(ServiceForm);

export default ServiceForm;
