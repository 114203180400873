import React from "react";
import { HiddenInputFormComponent } from "../../HiddenInputFormComponent";
import * as PropTypes from "prop-types";

export const HiddenRenderer = (field) => {
    const hiddenControl = (<HiddenInputFormComponent key={field.id} name={field.id} />);

    return hiddenControl;
};

HiddenRenderer.propTypes = {
    item: PropTypes.object.isRequired
};