import { FieldRenderer } from "./FieldRenderer";
import { Col, Form } from "react-bootstrap";
import React from "react";

const addTag = (item) => {
      if (item.isTagVisible) {
            return <Col md='12' className='text-left'>
                  <div className='custom-control-inline'>
                        <h5 style={{ marginBottom: '0' }} className='mr-3'>{item.groupName}</h5>
                  </div>
                  <hr style={{ marginTop: '0' }} />
            </Col>
      }
};

const columnCountForAdaptive = (fieldsLength, formColumnCount, isAdaptiveColumn) => isAdaptiveColumn && fieldsLength < formColumnCount ? 12 / fieldsLength : formColumnCount;

export const MainRenderer = (schema, props) => {

      let formColumnCount = 12;
      let isAdaptiveColumn = false;

      if (schema.ColumnCountSettings && schema.ColumnCountSettings.columnCount && schema.ColumnCountSettings.columnCount > 0) {
            formColumnCount = 12 / schema.ColumnCountSettings.columnCount;
            isAdaptiveColumn = schema.ColumnCountSettings.isAdaptiveColumn;
      }

      if (!schema.FormDesign || schema.FormDesign.length === 0) {
            return (
                  <Form.Row>
                        {FieldRenderer(schema.Fields, props, formColumnCount)}
                  </Form.Row>
            );
      }

      const orderedFormItems = schema.FormDesign.slice(0).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);
      let formItems = [];

      orderedFormItems.forEach(item => {

            const fieldsOfItems = schema.Fields.filter(x => x.formDesign === item.groupName).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);

            const innerColumnCount = columnCountForAdaptive(fieldsOfItems.length, formColumnCount, isAdaptiveColumn);

            const aggregatedFields = (
                  <Form.Row key={item.groupName}>
                        {addTag(item)}
                        {FieldRenderer(fieldsOfItems, props, innerColumnCount)}
                  </Form.Row>
            );

            formItems.push(aggregatedFields);
      });

      const grouplessFields = schema.Fields.filter(x => !x.formDesign).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);

      if (grouplessFields && grouplessFields.length > 0) {

            const innerColumnCount = columnCountForAdaptive(grouplessFields.length, formColumnCount, isAdaptiveColumn);

            const aggregatedGrouplessFields = (
                  <Form.Row key={'groupless'}>
                        {FieldRenderer(grouplessFields, props, innerColumnCount)}
                  </Form.Row>
            );

            formItems.push(aggregatedGrouplessFields);
      }

      return formItems.map(item => {
            return item
      });
};