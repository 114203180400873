import types from '../type/authentication';
import history from '../../core/history';

import postSync, { refreshAxios } from '../../core/httpClientSync';
import { decodeToken } from '../../core/token';

const applicationName = 'XMS';
const region = 'ARN';

const apiUrl = 'xms-identity-api/api/auth';
const roleApiUrl ='xms-authorize-api/api/role';

export const login = (username, password) => async dispatch => {
    const endPoint = `${apiUrl}/authenticate`;
    const authenticationRequest = { username, password, applicationName, region };

    const loginResult = await postSync(endPoint, authenticationRequest, 'login');

    if (!loginResult.isSuccess) { return; }

    const token = loginResult.response.access_token;

    localStorage.setItem('access_token', token);
    refreshAxios();

    const policyEndpoint = `${roleApiUrl}/mypolicies`;

    const policyResult = await postSync(policyEndpoint, '', 'login');

    if(!policyResult.isSuccess){
        return;
    }
    localStorage.setItem('policies', policyResult.response.join(','));

    dispatch(getUserFromLocalStorage(token));
};

export const logout = () => async dispatch => {
    localStorage.clear();
    history.push('/login');
    dispatch({
        type: types.LOG_OUT
    });
}

export const getUserFromLocalStorage = (access_token = null) => async dispatch => {
    const localToken = access_token ? access_token : localStorage.getItem('access_token');
    const payload = { isAuthenticated: false, authenticatedUser: null, accessToken: null };

    if (localToken) {
        const userInfo = extractUserClaimsFromToken(localToken);
        payload.isAuthenticated = true;
        payload.authenticatedUser = userInfo;
        payload.accessToken = localToken;
    }
    dispatch({
        type: types.RESTORE_USER,
        payload: payload
    })
}


const extractUserClaimsFromToken = (token) => {
    const decodedToken = decodeToken(token);
    const userInfo = {
        id: decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
        name: decodedToken['NameSurname'],
        region: decodedToken['Region'],
        company: decodedToken['Company'],
        applicationName: decodedToken['ApplicationName'],
        roles: decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
        location: 'DLC Arendal' //TODO: It can be parametric later on when we have more places.
    };

    return userInfo;
}