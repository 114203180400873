import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
  getBookingsForCarrier,
  cancelBooking,
} from "../store/action/bookingActions";
import BookingsTable from "../component/BookingTable/BookingsTable";
import history from "../core/history";
import { Button } from "@dfds-ui/react-components";
import Page from "../component/Page/Page";
import { Plus } from "@dfds-ui/icons";
import { TabCollection } from "../component/BaseComponents/TabCollection";
import { utcToEpoch } from "../core/utcToEpochConverter";
import moment from 'moment';

const dateTabs = [
  utcToEpoch(moment().toDate()),
  utcToEpoch(moment().add(1, 'day').toDate()),
  utcToEpoch(moment().add(2, 'day').toDate()),
  utcToEpoch(moment().add(3, 'day').toDate())
];

const CarrierBookings = ({ isRefreshData, getBookingsForCarrier, carrierBookings, cancelBooking }) => {
  const [selectedDate, setSelectedDate] = useState(utcToEpoch(moment().toDate()));

  useEffect(() => {
    getBookingsForCarrier(selectedDate);
  }, [selectedDate]);

  const dateChangedHandler = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if (isRefreshData) {
      getBookingsForCarrier(selectedDate);
    }
  }, [isRefreshData]);
 
  const editBookingClickedHandler = (bookingId) => {
    history.push(`/booking/${bookingId}`);
  };

  const cancelBookingClickedHandler = (bookingId) => {
    //TODO: Change with confirm modal when anatolia push it to master
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Booking (" + bookingId + ") will be cancelled. Continue?")) {
      cancelBooking(bookingId);
    }
  };

  // return (
  //   <>
  //     <Row>
  //       <Col>
  //         <PageHeader text="Bookings" />
  //       </Col>
  //     </Row>
  //     <Row className="text-center justify-content-md-center justify-content-sm-center mt-3">
  //       <Col lg={4} sm={6} xs={12}>
  //         <div className="date-selection">
  //           <DateSelector
  //             callBackForDateChanged={dateChangedHandler}
  //             initialDate={selectedDate}
  //           />
  //         </div>
  //       </Col>
  //     </Row>
  //     <Row>
  //       <Col>
  //         <ButtonStack align="right">
  //           <Button
  //             variation="primary"
  //             title="New Booking"
  //             onClick={() => {
  //               history.push("/booking/new");
  //             }}
  //             icon={<FontAwesomeIcon icon="plus" />}
  //             className="d-none d-md-block"
  //           >
  //             New Booking
  //           </Button>
  //         </ButtonStack>
  //       </Col>
  //     </Row>
  //     <Row>
  //       <Col>
  //         <BookingsTable
  //           data={carrierBookings}
  //           canEditBooking={true}
  //           canSeeAdminNotes={false}
  //           editBookingClickedEvent={editBookingClickedHandler}
  //           cancelBookingClickedHandler={cancelBookingClickedHandler}
  //         />
  //       </Col>
  //     </Row>
  //   </>
  // );

  return <Page>
    <Row>
      <Col>
        <div className='d-flex' style={{ alignItems: 'center' }}>
          <div style={{ flex: '1' }}>
            <h4 className='mb-0'>Bookings</h4>
          </div>
          <div>
            <Button title="New Booking"
              icon={<Plus />}
              iconAlign='left'
              onClick={() => {
                history.push("/booking/new");
              }}>New Booking</Button>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div style={{ marginBottom: '40px' }}> </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <TabCollection
          tabItemStyle={{ 'height': '2rem', fontSize: 'medium' }}
          selectedTab={selectedDate}
          tabs={dateTabs}
          customTabRenderer={(tab) => moment.utc(tab * 1000).calendar(null, {
            lastDay: '[YESTERDAY] - MMM Do',
            sameDay: '[TODAY] - MMM Do',
            nextDay: '[TOMORROW] - MMM Do',
            nextWeek: 'dddd - MMM Do',
            sameElse: 'dddd - MMM Do'
          }).toLocaleUpperCase()}
          onTabClickedEvent={dateChangedHandler}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <BookingsTable
          data={carrierBookings}
          canEditBooking={true}
          canSeeAdminNotes={false}
          editBookingClickedEvent={editBookingClickedHandler}
          cancelBookingClickedHandler={cancelBookingClickedHandler}
        />
      </Col>
    </Row>
  </Page>
};

const mapStateToProps = (state) => {
  return {
    carrierBookings: state.booking.carrierBookings,
    isRefreshData: state.booking.isRefreshData,
  };
};

export default connect(mapStateToProps, {
  getBookingsForCarrier,
  cancelBooking,
})(CarrierBookings);
