import React from 'react';
import { Accordion } from 'react-bootstrap';
import { AddedOrUpdatedEntity, DeletedEntity } from './EntityStateDiff/EntityTypes';

const entityTypes = {
    Create: AddedOrUpdatedEntity,
    Update: AddedOrUpdatedEntity,
    Delete: DeletedEntity
}

const EntityStateDiffList = ({ diffList }) => {
    return <Accordion defaultActiveKey="0">
        {diffList && diffList.map(diff => {
            let Entity = entityTypes[diff.action];
            return <Entity key={diff.id} diff={diff} />
        })}
    </Accordion>
}

export default EntityStateDiffList;
