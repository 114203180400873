import React from 'react';
import LoadingTaskBadge from './LoadingTaskBadge';

// Nothing changed from loading task at least for now.
const UnloadingTaskBadge = ({ task }) => {
    return <>
        <LoadingTaskBadge task={task} />
    </>
}

export default UnloadingTaskBadge;