import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { ButtonStack, Button } from '@dfds-ui/react-components';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { connect } from 'react-redux';
import { getJsonModel, saveHistoryJsonModel } from '../store/action/historyActions';
import Page from '../component/Page/Page';

const EditHistoryJsonDefinition = ({ persistedJsonModel, getJsonModel, saveHistoryJsonModel }) => {
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [jsonModel, setJsonModel] = useState('');
    useEffect(() => {
        getJsonModel();
    }, []);

    const saveClickedHandler = () => {
        saveHistoryJsonModel(jsonModel);
    }

    const onJsonChange = value => {
        const isJsonValid = value.jsObject !== undefined;
        setIsSaveEnabled(isJsonValid);
        if (isJsonValid) {
            setJsonModel(value.json);
        }
    }
    return <Page> 
        <Row>
            <Col md='8' className='text-left'>
                <div className='custom-control-inline'>
                    <h2 style={{ lineHeight: '1.7', marginBottom: '0' }}>Edit History Definition</h2>
                </div>
            </Col>
            <Col md='4'>
                <ButtonStack align='right'>
                    <Button variation='primary'
                        disabled={!isSaveEnabled}
                        onClick={saveClickedHandler}
                        icon={<FontAwesomeIcon icon='save' />}
                    >
                        Save
                        </Button>
                </ButtonStack>
            </Col>
        </Row>
        <hr />
        <JSONInput
            width='100%'
            height='100%'
            id='a_unique_id'
            placeholder={persistedJsonModel}
            theme='light_mitsuketa_tribute'
            locale={locale}
            height='550px'
            style={{
                body: {
                    fontFamily: ['DFDS-Light', 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
                    fontSize: '16px',
                    fontWeigth: 'bold',
                    lineHeight: '2rem',
                    letterSpacing: '.6px'
                }
            }}
            onChange={onJsonChange}
        />
        <LoadingIndicator id='editHistoryDefinitions' />
    </Page>
}

const mapStateToProps = state => {
    return {
        persistedJsonModel: state.history.jsonModel
    }
}
export default connect(mapStateToProps, { getJsonModel, saveHistoryJsonModel })(EditHistoryJsonDefinition);