import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalShowImage = ({ modalData, onHide }) => {

    const renderImage = () => {
        if (modalData.url) {
            return (
                <img width="75%" height="75%" src={modalData.url} alt='' />
            )
        }
        else {
            return (
                <img width="75%" height="75%" src={URL.createObjectURL(modalData.image)} alt='' />
            )
        }
    }

    return (
        <Modal
            show={true}
            onHide={onHide}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body className="text-center">
                {renderImage()}
            </Modal.Body>

            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )

}

export default ModalShowImage;