import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { logout } from '../../store/action/authActions';

const Logout = ({ logout }) => {

    useEffect(() => {
        logout();
    }, [logout]);

    return (
        <div></div>
    );
}

export default connect(null, { logout })(Logout);