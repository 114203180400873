import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import unitReducer from './unitReducer';
import shipmentReducer from './shipmentReducer';
import shipmentDetail from './shipmentDetailReducer';
import splitReducer from './splitReducer';
import transportReducer from './transportReducer';
import fileControllerReducer from './fileControllerReducer';
import BookingReducer from './bookingReducer';
import NotificationReducer from './notificationReducer';
import UserReducer from './userReducer';
import modalReducer from './modalReducer';
import actionUrlReducer from './actionUrlReducer';
import companyReducer from './companyReducer';
import calendarReducer from './calendarReducer';
import gateQuotaReducer from './gateQuotaReducer';
import roleReducer from './roleReducer';
import thuDetails from './thuDetailReducer';
import image from './imageReducer';
import shipmentsReducer from './shipmentsReducer';
import imageController from './imageControllerReducer';
import additionalGateReducer from './additionalGateReducer';
import reservedSlotReducer from './reservedSlotReducer';
import taskReducer from './taskReducer';
import historyReducer from "./historyReducer";
import serviceReducer from './serviceReducer';

export default combineReducers({
    form: formReducer,

    auth: authReducer,
    unit: unitReducer,
    shipment: shipmentReducer,
    split: splitReducer,
    transport: transportReducer,
    fileController: fileControllerReducer,
    booking: BookingReducer,
    notification: NotificationReducer,
    user: UserReducer,
    company: companyReducer,
    service: serviceReducer,
    modal: modalReducer,
    actionUrl: actionUrlReducer,
    calendar: calendarReducer,
    additionalGate: additionalGateReducer,
    gateQuota: gateQuotaReducer,
    role: roleReducer,
    shipments: shipmentsReducer,
    shipmentDetail,
    thuDetails,
    image,
    imageController,
    reservedSlot: reservedSlotReducer,
    task: taskReducer,
    history: historyReducer
});
