/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Form from 'react-jsonschema-form';
import '../../core/schema.css';
import '../../RoutedComponents/BookingCommon.css';
import { Button } from '@dfds-ui/react-components';

const BookingForm = ({ formSubmitHandler, schemaJson, renderBookingResult, formData, transformErrors= null, isDisabled = false, buttonText = 'CONTINUE' }) => {
    return (
        <>
            {schemaJson ? <div>
                <Form
                    disabled={isDisabled}
                    schema={schemaJson.schema}
                    formData={formData}
                    uiSchema={schemaJson.uiSchema}
                    onSubmit={formSubmitHandler}
                    transformErrors={transformErrors}>
                    {renderBookingResult ? renderBookingResult() : null}
                    <Button type="submit" variant="primary" className="btn-lg float-right">{buttonText}</Button>
                </Form>
            </div> : null}
        </>
    )
}

export default BookingForm;