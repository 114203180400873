const userActions = Object.freeze({
    UNIT_FOUND: Symbol('UNIT_FOUND'),
    SEARCH_UNIT_RESET: Symbol('SEARCH_UNIT_RESET'),

    UNIT_FETCHED: Symbol('UNIT_FETCHED'),
    RESET_UNIT_DATA: Symbol('RESET_UNIT_DATA'),
    SAVE_SHIPMENT_FILE_LIST: Symbol('SAVE_SHIPMENT_FILE_LIST'),
    SAVE_DOCS_MISSING_STATE_LIST: Symbol('SAVE_DOCS_MISSING_STATE_LIST'),


});

export default userActions;