import React from 'react';
import StyledCard from '../StyledCard';
import { Card } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import EntityHeader from '../EntityHeader';

const DeletedEntity = ({ diff }) => {
    return <StyledCard>
        <Accordion.Toggle as={Card.Header}>
            <EntityHeader diff={diff} />
        </Accordion.Toggle>
    </StyledCard>
}

export default DeletedEntity;