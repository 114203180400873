import React from 'react';
import { Button } from '@dfds-ui/react-components';
import { connect } from 'react-redux';
import { showEnterGateNumberToOperationModal, putDetail } from '../../../../store/action/taskActions';
import { utcToEpochWithTime } from '../../../../core/utcToEpochConverter';
import PolicyGroupAccessHoc from '../../../../RoutedComponents/Routes/PolicyGroupAccessHoc';

const LoadingCustomActions = ({ task, putDetail, showEnterGateNumberToOperationModal }) => {

    const onMarkedAsArrivedClickedHandler = (e) => {
        e.stopPropagation();
        //TODO: Change with confirm modal when anatolia push it to master
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Please confirm to mark as arrived.')) {
            putDetail(task.id, { "isArrived": "true", "actualTimeOfArrival": utcToEpochWithTime(new Date()) }, 'Marked as arrived');
        }
    }

    const enterGateNumberClickedHandler = (e) => {
        e.stopPropagation();
        showEnterGateNumberToOperationModal(assignGateClickedHandler);
    }

    const assignGateClickedHandler = gate => {
        putDetail(task.id, { gate: gate }, `Operation assigned to gate ${gate}`);
    }

    const isMarkAsArrivedVisible = () => !((task.details && task.details['isArrived'] && task.details['isArrived'] === 'true') || (task.status === 'Completed' || task.status === 'Cancelled'));

    const isEnterGateNumberVisible = () => task.status !== 'Completed' || task.status !== 'Cancelled';

    const hasGate = () => task.details && task.details['gate']

    return <>
        {isMarkAsArrivedVisible() && <PolicyGroupAccessHoc componentName="MarkAsArrivedOnTask">
            <Button
                size='small'
                className={'mt-1 mb-1'}
                onClick={onMarkedAsArrivedClickedHandler}
                variation='outlined'>
                Mark As Arrived
        </Button>
        </PolicyGroupAccessHoc>}

        {isEnterGateNumberVisible() && <PolicyGroupAccessHoc componentName="EnterGateNumberOnTask">
            <Button
                size='small'
                className='mt-1'
                onClick={enterGateNumberClickedHandler}
                variation='outlined'>
                {hasGate() ? 'Change gate' : 'Assign gate'}
            </Button>
        </PolicyGroupAccessHoc>}
    </>
}

export default connect(null, { putDetail, showEnterGateNumberToOperationModal })(LoadingCustomActions);