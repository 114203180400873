import types from '../type/task';

const initialState = {
    businessAreas: null,
    tasks: null,
    filteredTasks: null,
    usersForAssignment: null,
    unitTypes: null,
    isTaskCreated: false,
    isTaskUpdated: false,
    isTaskCancelled: false,
    taskItemModel: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.BUSINESS_AREAS_RETRIEVED:
            return {
                ...state,
                businessAreas: action.payload
            }
        case types.TASKS_RECEIVED:
            return {
                ...state,
                tasks: action.payload,
                filteredTasks: action.payload
            };
        case types.TASKS_FILTERED:
            return {
                ...state,
                filteredTasks: action.payload
            };
        case types.TASKS_CLEARED:
            return {
                ...state,
                tasks: null,
                filteredTasks: null
            }
        case types.TASK_CREATED:
            return {
                ...state,
                isTaskCreated: true
            }
        case types.USERS_RECEIVED:
            return {
                ...state,
                usersForAssignment: action.payload
            }
        case types.TASK_ITEM_MODEL:
            return {
                ...state,
                taskItemModel: action.payload
            }
        case types.UNIT_TYPE_RECEIVED: {
            return {
                ...state,
                unitTypes: action.payload
            }
        }
        case types.BUSINESS_AREAS_RECEIVED: {
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case types.TASK_CANCELLED: {
            return {
                ...state,
                isTaskCancelled: true,
                tasks: null,
                filteredTasks: null
            }
        }
        case types.TASK_UPDATED:
            return {
                ...state,
                isTaskUpdated: true
            }
        case types.DETAIL_PUT: {
            return {
                ...state,
                tasks: null,
                filteredTasks: null
            }
        }
        case types.CLEAR_STATE:
            return initialState;
        default:
            return state;
    }

}