import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Button, ButtonStack } from "@dfds-ui/react-components";

const StyledTabItem = styled(Button)`
    font-size: medium;
    margin-bottom: -2px;
    color:  ${props => props.isSelected ? '#68B2E3' : '#D8DAD9'};
    ${props => ({ ...props.tabItemStyle })};
    border-bottom: ${props => props.isSelected ? '2px solid #68B2E3' : ''};
`;

const StyledTabContent = styled.div`
    background-color:#fff; 
`;

export const TabCollection = ({ tabs, selectedTab, onTabClickedEvent, customTabRenderer, customIsTabSelected, children, tabItemStyle }) => {
    const isTabSelected = item => customIsTabSelected ? customIsTabSelected(item) : item === selectedTab;

    return <>
        <ButtonStack className={'tab-collection'}>
            {tabs.map((tab, index) => <StyledTabItem
                tabItemStyle={tabItemStyle}
                key={index}
                isSelected={isTabSelected(tab)}
                onClick={() => onTabClickedEvent(tab)}
                size='small'
                className='flex-fill'
                variation='text'>
                {customTabRenderer ? customTabRenderer(tab) : tab}
            </StyledTabItem>)
            }
        </ButtonStack>
        <StyledTabContent>
            {children}
        </StyledTabContent>
    </>
};

TabCollection.propTypes = {
    tabs: PropTypes.array.isRequired,
    customTabRenderer: PropTypes.func,
    customIsTabSelected: PropTypes.func,
    onTabClickedEvent: PropTypes.func
};