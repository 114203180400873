import httpClient from "../../core/httpclient";
import { spinnerService } from "@simply007org/react-spinners";
import serviceActions from "../type/service";

const apiUrl = 'xms-slm-api/api/servicedefinition';

export const getServiceList = () => async dispatch => {
    const endPoint = `${apiUrl}`;
    spinnerService.show('getServiceList')
    httpClient.get(endPoint)
        .then(response => {
            dispatch({
                type: serviceActions.SERVICE_GETSERVICES_SUCCESS,
                payload: response.data.result
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('getServiceList')
        });
}

export const insertOrUpdateService = (id, code, name, description, operationType) => async dispatch => {
    const endPoint = `${apiUrl}/save`;
    spinnerService.show('serviceDetail')
    const insertOrUpdateServiceRequest = {
        id: id,
        code: code.trim(),
        name: name.trim(),
        description: description.trim(),
        operationType: operationType
    }

    httpClient.post(endPoint, insertOrUpdateServiceRequest)
        .then(response => {
            dispatch({
                type: serviceActions.SERVICE_CLEARSERVICESTATE_SUCCESS
            });
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            spinnerService.hide('serviceDetail');
        });
}

export const getService = (serviceList, id) => async dispatch => {
    dispatch({
        type: serviceActions.SERVICE_GETSERVICE_SUCCESS,
        payload: serviceList && serviceList.length > 0 ? serviceList.find(x => x.id === id) : null
    });
}

export const clearServiceState = () => async dispatch => {
    dispatch({
        type: serviceActions.SERVICE_CLEARSERVICESTATE_SUCCESS
    });
}