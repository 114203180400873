import React from 'react'
import { ListChange, PrimitiveChange, ObjectChange } from './ChangeTypes'

const EntityChangeValue = ({ change, action, objectType }) => {
    const changeTypes = {
        'List': ListChange,
        'Primitive': PrimitiveChange,
        'Object': ObjectChange
    }

    const renderValue = () => {
        let Change = changeTypes[change.valueType]
        return <Change change={change} action={action} objectType={objectType}></Change>;
    }
    
    return renderValue();
}

export default EntityChangeValue;
