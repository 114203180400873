import { formInputTypes } from "../../FormComponentTypes";
import * as Renderers from "./renders";

export const FieldRenderer = (fields, props, formColumnCount) => {
    const orderedSchema = fields.slice(0).sort((first, second) => (first.ordering > second.ordering) ? 1 : -1);
    return orderedSchema.map((field) => {
        if (field.type === formInputTypes.HIDDEN_INPUT) {
            return Renderers.HiddenRenderer(field);
        }
        if (field.type === formInputTypes.STRING_INPUT) {
            return Renderers.StringRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.DATE_INPUT) {
            return Renderers.DateRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.NUMBER_INPUT) {
            return Renderers.NumberRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.TEXTAREA_INPUT) {
            return Renderers.TextAreaRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.RADIO_INPUT) {
            return Renderers.RadioButtonListRenderer(field, formColumnCount);
        }
        if (field.type === formInputTypes.COMBO_INPUT) {
            const listItem = props[field.listOfItems] ? [...props[field.listOfItems]] : [];
            const firstItemObject = props[field.firstItemObject];
            return Renderers.ComboboxRenderer(listItem, firstItemObject, field, formColumnCount);
        }
    });
};