import types from '../type/shipment';

const initialState = {
    shipmentList: null,
    fileList: null,
    docsMissingStateList: null,
    searchedShipment: null
};

export default (state = initialState, action) => {

    switch (action.type) {
        case types.ADD_SHIPMENTS_TO_LIST:
            const newTemp = { ...state.shipmentList };
            for (const shipment of action.payload) {
                newTemp[shipment.referenceNumber] = shipment;
            }
            return {
                ...state,
                shipmentList: newTemp,
                searchedShipment: null
            }
        case types.UPDATE_SEARCHED_SHIPMENT:
            return {
                ...state,
                searchedShipment: action.payload
            }
        case types.INITALIZE_SHIPMENT_DATA:
            return {
                ...state,
                ...initialState
            }
        case types.SAVE_FILE_LIST:

            const newFileList = { ...state.fileList };
            for (const shipment of action.payload) {
                newFileList[shipment.relativePath] = shipment.files
            }

            return {
                ...state,
                fileList: newFileList
            }
        case types.SAVE_DOCS_MISSING_STATE_LIST:
            const newDocsMissingStateList = { ...state.docsMissingStateList };
            for (const docsMissingStateFlag of action.payload) {
                newDocsMissingStateList[docsMissingStateFlag.orderItemId] = docsMissingStateFlag.isMissingDocumentStateFlagSet
            }

            return {
                ...state,
                docsMissingStateList: newDocsMissingStateList
            }
        case types.SAVE_DOCS_MISSING_STATE:
            const newDocsMissingStateListForUpdate = { ...state.docsMissingStateList };
            newDocsMissingStateListForUpdate[action.payload.orderItemId] = action.payload.isMissingDocumentStateFlagSet;
            return {
                ...state,
                docsMissingStateList: newDocsMissingStateListForUpdate
            }
        default:
            return state;
    }

};