import React from 'react';
import { Form } from 'react-bootstrap';

class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { options, input } = this.props;

        return options.map((option, index) => {
            return (
                <div className="checkbox" key={index}>
                    <Form.Check
                        type="checkbox"
                        label={option.name}
                        name={`${input.name}[${index}]`}
                        value={option.name}
                        checked={input.value.indexOf(option.name) !== -1}
                        onChange={(event) => {
                            const newValue = [...input.value];
                            if (event.target.checked) {
                                newValue.push(option.name);
                            } else {
                                newValue.splice(newValue.indexOf(option.name), 1);
                            }
                            return input.onChange(newValue);
                        }} />
                </div>)
        });
    }

    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}


export default CheckboxGroup;