import { decodeToken } from "./token";
import { ComponentPolicyList, LayoutPolicyList } from "../context/Policy";

export const isTokenExist = () => {
    const token = localStorage.getItem('access_token');

    if (typeof token === 'undefined' || token === null) {
        return false;
    }

    return true;
};

const getToken = () => {
    return localStorage.getItem('access_token');
};

const getPolicies = () => {
    return localStorage.getItem('policies');
};
const getRenderPolicies = (componentName, policies, isRoutePolicy) => {

    if (!policies) {
        console.log(componentName + ': No render policies has been found.');
        return [];
    }

    if (isRoutePolicy) {
        const routePolicyList = policies.routePolicy;

        if (!routePolicyList || routePolicyList.length === 0) {
            console.log(componentName + ': No route policies has been found');
            return [];
        }

        return routePolicyList;
    }

    if (!policies.components || policies.components.length === 0) {
        console.log(componentName + ': Policy component list is empty.');
        return [];
    }

    const componentPolicy = policies.components.find(x => x.ComponentName === componentName);

    if (!componentPolicy) {
        console.log(componentName + ': Policy component has not been found.');
        return [];
    }

    if (!componentPolicy.AllowedPolicies || componentPolicy.AllowedPolicies.length === 0) {
        console.log(componentName + ': Policy component list is empty.');
        return [];
    }

    return componentPolicy.AllowedPolicies;
};

const getAppPolicies = (appContext) => {

    if (!appContext) {
        console.log('AppContext is null');
        return [];
    }

    if (appContext.length === 0) {
        console.log('AppContext Allowance is empty;');
        return [];
    }

    return appContext;
};

const getAuthorization = () => {

    const policies = getPolicies();

    if (!policies)
        return [];

    if (policies.indexOf(',') < 0)
        return [policies];

    return policies.split(',');
};

export const hasAuthorizationToGo = (Component, auth) => {
    if (!auth)
        return true;

    const authArray = auth.split(",").map(function (item) {
        return item.trim();
    });

    if (!authArray || authArray.length === 0)
        return true;

    const userAuthorization = getAuthorization();

    if (!userAuthorization)
        return false;

    if (!Array.isArray(userAuthorization)) {
        return authArray.includes(userAuthorization);
    }

    return userAuthorization.some(r => authArray.includes(r));
};

export const checkPermissionForControl = (allowedPermission) => {
    if (!allowedPermission)
        return true;

    if (!allowedPermission || allowedPermission.length === 0)
        return true;

    const userAuthorization = getAuthorization();

    if (!userAuthorization)
        return false;

    return userAuthorization.some(r => allowedPermission.includes(r));
};

//TODO: Bu metodun kalkması lazım. Sistemin sadece policy ile çalışması gerekiyor.
export const isOnlyExternalUser = () => {
    const userAuthorization = getAuthorization();
    if (!userAuthorization)
        return false;

    if (userAuthorization.some(x => x === 'BookingExternalPolicy')) {
        return true;
    }

    return false;
};

export const isForkliftUser = () => {
    const userAuthorization = getAuthorization();
    if (!userAuthorization)
        return false;

    if (userAuthorization.some(x => x === 'TerminalForkliftPolicy')) {
        return true;
    }

    return false;
};

export const checkForAuthorization = (componentName, appContext, authorizeContext, isRoutePolicy) => {

    if (!componentName) {
        console.log(componentName + ': No component name has come.');
        return true;
    }

    const policies = getRenderPolicies(componentName, authorizeContext, isRoutePolicy);

    const allowanceList = getAppPolicies(appContext);

    if (policies.length === 0 && allowanceList.length === 0) {
        console.log(componentName + ': Both policies and allowanceList is empty. There might be a problem.');
        return false;
    }

    if (policies.length === 0) {
        console.log(componentName + ': Policy list is empty');
        return true;
    }

    const userAuthorization = getAuthorization();

    if (!userAuthorization || userAuthorization.length === 0) {
        console.log(componentName + ': No storage policies has found.');
        return false;
    }

    const hasAuthExistInPolicies = userAuthorization.some(r => policies.includes(r));

    if (hasAuthExistInPolicies) {
        return true;
    }

    // Policies has returned false. What about AppContext?

    const hasAppContextIncludeComponent = allowanceList.includes(componentName);

    if (hasAppContextIncludeComponent) {
        return true;
    }


    return false;
};

export const checkForRender = (sectionName, appContext, authorizeContext) => {
    if (!sectionName) {
        console.log(sectionName + ': No component name has come.');
        return true;
    }

    const policies = getRenderPolicies(sectionName, authorizeContext);
    const allowanceList = getAppPolicies(appContext);

    if (policies.length === 0 && allowanceList.length === 0) {
        console.log(sectionName + ': Both policies and allowanceList is empty. There might be a problem.');
        return false;
    }

    const userAuthorization = getAuthorization();

    if (!userAuthorization || userAuthorization.length === 0) {
        console.log(sectionName + ': No storage policies has found.');
        return false;
    }

    const hasAuthExistInPolicies = userAuthorization.some(r => policies.includes(r));

    if (hasAuthExistInPolicies) {
        return true;
    }

    // Policies has returned false. What about AppContext?

    const hasAppContextIncludeComponent = allowanceList.includes(sectionName);

    if (hasAppContextIncludeComponent) {
        return true;
    }


    console.log(sectionName + ': Both policies and AppContext have not any allowance.');
    return false;
};
