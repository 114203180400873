/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCompany, clearCompanyState, getCompanyList, insertOrUpdateCompany } from '../store/action/companyActions';
import { Row, Col } from 'react-bootstrap';
import CompanyForm from '../component/CompanyForm/CompanyForm';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import { toast } from 'react-toastify';
import Page from '../component/Page/Page';

function AddOrEditCompany({ match, companyList, company, getCompany, clearCompanyState, getCompanyList, insertOrUpdateCompany }) {
    useEffect(() => {
        if (!companyList) {
            getCompanyList();
        }
        return () => {
            clearCompanyState();
        }
    }, []);

    useEffect(() => {
        if (match.params.companycode !== 'new' && !company) {
            getCompany(companyList, match.params.companycode);
        }
    }, [companyList, match.params.companycode])

    const handleSubmit = (form) => {
        insertOrUpdateCompany(form.name, form.code);
        if (match.params.companycode === 'new') {
            toast.success('Company added.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        } else {
            toast.success('Company updated.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }

        history.push('/company');
    }

    return <Page backLink='/company' backToWhere='Companies' >
        <h4>
            {company ? company.name : 'New Company'}
        </h4><hr className='mt-0' />
        <CompanyForm onSubmit={handleSubmit} isNewCompany={match.params.companycode === 'new'} />
        <LoadingIndicator id="companyDetail" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        company: state.company.company,
        companyList: state.company.companyList,
    }
}
export default connect(mapStateToProps, { getCompanyList, getCompany, clearCompanyState, insertOrUpdateCompany })(AddOrEditCompany);
