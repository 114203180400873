import types from '../type/gatequota';

const initialState = {
    defaultConfig: null,
    customConfigs: null,
    newConfig: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_CONFIGURATION:
            {
                const newConfig = { ...action.payload };
                if (newConfig.configurationType === 'SpecificDateGateQuota') {
                    const customConfigsCloned = [...state.customConfigs];
                    customConfigsCloned.push(newConfig);
                    return {
                        ...state,
                        customConfigs: customConfigsCloned
                    }
                } else {
                    return {
                        ...state,
                        defaultConfig: newConfig,
                    };
                }
            }
        case types.DELETE_CONFIGURATION: {
            return initialState;
        }
        case types.GET_ALL_CONFIGURATIONS:
            const allConfigs = action.payload;
            return {
                ...state,
                defaultConfig: allConfigs.find(x => x.configurationType === 'DefaultGateQuota'),
                customConfigs: allConfigs.filter(x => x.configurationType !== 'DefaultGateQuota'),
            }
        default:
            return state;
    }
}