import React from 'react';
import classes from './TimeSlotTable.module.css';
import { convertEpochToUtcTime } from '../../core/convertEpochToUtcTime';
import { Col } from 'react-bootstrap';

const TimeSlotTable = ({ timeSlots, timeSlotClickedEvent, selectedTimeSlots, allowToSelectAllocatedTimeSlots = false, readOnly = false }) => {
    const getOnClickEvent = timeSlot => {
        if (!readOnly && (allowToSelectAllocatedTimeSlots || !timeSlot.isAllocated)) {
            return () => timeSlotClickedEvent(timeSlot.date, timeSlot.startTime);
        };
        return null;
    }

    const getColumnClasses = timeSlot => {
        const columnClasses = ['d-flex', 'align-items-center', 'justify-content-center', 'm-3', classes.timeSlot];
        
        if (timeSlot.isAllocated && !allowToSelectAllocatedTimeSlots) {
            columnClasses.push(classes.unAvailableTimeSlot);
        }

        if (selectedTimeSlots && ~selectedTimeSlots.indexOf(timeSlot.startTime)) {
            columnClasses.push(classes.selected);
        }
        return columnClasses.join(' ');
    }
    return <>
        {timeSlots && timeSlots.map((timeSlot, index) => <Col key={index} lg={3} sm={3} xs={6}
            onClick={getOnClickEvent(timeSlot)}>
            <span className={getColumnClasses(timeSlot)}>
                {convertEpochToUtcTime(timeSlot.startTime)}
            </span>
        </Col>)}
    </>
}

export default TimeSlotTable;