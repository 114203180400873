import type from '../type/shipments';

const initialState={
    suffixList:null,
    inStockShipmentList:null
};

export default (state = initialState, action) => {
    switch(action.type){
        case type.GET_SUFFIX_LIST:
            return {
                ...state,
                suffixList:action.payload
            }
        case type.GET_SHIPMENT_LIST:
            return {
                ...state,
                inStockShipmentList:action.payload
            }  
        case type.CLEAR_ADD_SHIPMENTS_STATE: 
            return{
                initialState
            }
        default:
            return state
        }
}