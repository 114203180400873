/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRule, getRules, getBusinessAreas, saveRule } from '../store/action/calendarActions';
import XmsPage from '../component/XmsPage/XmsPage';
import { Row, Col, Button, FormLabel, Form, Alert } from 'react-bootstrap';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './AddOrEditCalendarRule.css';
import history from '../core/history';
import moment from 'moment';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { weekDays } from '../core/utility';
import Page from '../component/Page/Page';

const AddOrEditCalendarRule = (props) => {
    //Redux actions
    const { getRule, getRules, getBusinessAreas, saveRule } = props;

    //Redux store
    let { rule, rules, businessAreas } = props;

    //Others
    let { match } = props;

    const [state, setState] = useState({
        businessArea: '',
        ruleType: 'Everyday',
        gateQuota: -1,
        slotSize: '60',
        useDefaultQuota: true,
        startMinutesSinceMidnight: null,
        endMinutesSinceMidnight: null,
        weekday: '',
        dateEpoch: null,
        ruleState: 'Available',
        errors: null
    });

    useEffect(() => {
        if (!rules) {
            getRules();
        }
    }, []);

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }
    }, [businessAreas]);

    useEffect(() => {
        if (rules && match.params.ruleid) {
            getRule(rules, match.params.ruleid);
        }
    }, [rules]);

    useEffect(() => {
        if (rule) {
            const ruleCloned = { ...rule }
            ruleCloned.startMinutesSinceMidnight = moment().hour(ruleCloned.startMinutesSinceMidnight / 60).minute(ruleCloned.startMinutesSinceMidnight % 60);
            ruleCloned.endMinutesSinceMidnight = moment().hour(ruleCloned.endMinutesSinceMidnight / 60).minute(ruleCloned.endMinutesSinceMidnight % 60);
            ruleCloned.dateEpoch = new Date(rule.date * 1000);
            setState(ruleCloned);
        }
    }, [rule])

    const renderBusinessArea = () => {
        return businessAreas ? <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Business Area: </FormLabel>
                </Col>
                <Col md='4'>
                    <Form.Control value={state.businessArea} onChange={(e) => businessAreaChangedHandler(e.target.value)} md='3' as='select'>
                        <option>Choose...</option>
                        {businessAreas.map(businessArea => (<option key={businessArea.id} value={businessArea.id}>{businessArea.name}</option>))}
                    </Form.Control>
                </Col>
            </Row>
            <br />
        </> : null;
    }

    const renderWeekday = () => {
        return state.ruleType === 'Weekday' ? <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Weekday: </FormLabel>
                </Col>
                <Col md='4'>
                    <Form.Control max='999' value={state.weekday} onChange={(e) => weekdayChangedHandler(e.target.value)} md='3' as='select'>
                        <option>Choose...</option>
                        {weekDays.map((day, i) => (<option key={i}>{day}</option>))}
                    </Form.Control>
                </Col>
            </Row>
            <br />
        </>
            : null
    }

    const renderSpecificDate = () => {
        return state.ruleType === 'SpecificDate' ? <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Date: </FormLabel>
                </Col>
                <Col md='4'>
                    <DatePicker className='form-control' selected={state.dateEpoch} onChange={dateChangedHandler} />
                </Col>
            </Row>
            <br />
        </> : null
    }

    const dateChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            dateEpoch: value
        }));
    }
    const stateClickedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            ruleState: value
        }));
    }
    const weekdayChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            weekday: value
        }));
    }

    const businessAreaChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            businessArea: value
        }));
    }

    const quotaChangedHandler = value => {
        if (value >= -1 && value <= 999) {
            setState({
                ...state,
                gateQuota: value
            }
            );
        }
    }
    const typeClickedHandler = (type) => {
        const newState = { ...state };
        newState.ruleType = type;
        switch (type) {
            case 'Everyday':
                newState.weekday = '';
                newState.dateEpoch = null;
                break;
            case 'Weekday':
                newState.dateEpoch = null;
                break;
            case 'SpecificDate':
                newState.weekday = '';
                break;
            default:
                break;
        }

        setState(newState);
    }

    const startTimeChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            startMinutesSinceMidnight: value
        }));
    }

    const endTimeChangedHandler = value => {
        setState((previousState, props) => ({
            ...previousState,
            endMinutesSinceMidnight: value,
        }));
    }

    const saveClickedHandler = () => {
        if (checkForm()) {
            saveRule(state);
            history.goBack();
        }
    }

    const checkForm = () => {
        const errors = [];
        if (state.startMinutesSinceMidnight >= state.endMinutesSinceMidnight) {
            errors.push('Time range is invalid. End time should be greater than start time.');
        }

        if (!state.businessArea) {
            errors.push('Business area must be selected.');
        }

        if (!state.ruleType) {
            errors.push('Rule type must be selected.');
        }

        if (!state.ruleState) {
            errors.push('State must be selected.');
        }

        if (state.ruleType === 'Weekday' && !state.weekday) {
            errors.push('Weekday must be selected.');
        }

        if (state.ruleType === 'SpecificDate' && !state.dateEpoch) {
            errors.push('Date must be selected.');
        }

        setState({
            ...state,
            errors: errors
        });
        return errors.length === 0;
    }
    const renderErrors = () => {
        return <>
            {
                state.errors ? state.errors.map(error => (<Alert variant='danger'>{error}</Alert>)) : null
            }
        </>
    }

    const renderType = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Type: </FormLabel>
                </Col>
                <Col md='4'>
                    <Row>
                        <Col md='4'>
                            <Button variant={state.ruleType === 'Everyday' ? 'success' : 'secondary'} onClick={() => { typeClickedHandler('Everyday') }} block>Everyday</Button>
                        </Col>
                        <Col md='4'>
                            <Button variant={state.ruleType === 'Weekday' ? 'success' : 'secondary'} onClick={() => { typeClickedHandler('Weekday') }} block>Weekday</Button>
                        </Col>
                        <Col md='4'>
                            <Button variant={state.ruleType === 'SpecificDate' ? 'success' : 'secondary'} onClick={() => { typeClickedHandler('SpecificDate') }} block>A Day</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br />
        </>
    }
    const renderTimeRange = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Time Range: </FormLabel>
                </Col>
                <Col md='2'>
                    <TimePicker value={state.startMinutesSinceMidnight} onChange={(e) => startTimeChangedHandler(e)} showSecond={false} minuteStep={30} />
                </Col>

                <Col md='2'>
                    <TimePicker value={state.endMinutesSinceMidnight} onChange={(e) => endTimeChangedHandler(e)} showSecond={false} minuteStep={30} />
                </Col>
            </Row>
            <br />
        </>
    }

    const useDefaultQuotaChangedHandler = event => {
        const useDefault = event.target.checked;
        if (useDefault) {
            setState({
                ...state,
                gateQuota: -1,
                useDefaultQuota: event.target.checked
            })
        }
        else {
            setState({
                ...state,
                gateQuota: 0,
                useDefaultQuota: event.target.checked
            })
        }
    }

    const slotSizeChangedHandler = event => {
        setState({ ...state, slotSize: event.target.value })
    }

    const renderQuota = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Number Of Gates: </FormLabel>
                </Col>

                <Col md='4'>
                    <Form.Check id='useDefaultQuota' custom type='checkbox' checked={state.useDefaultQuota} onChange={useDefaultQuotaChangedHandler} label='Use default number of gates' />
                    {!state.useDefaultQuota ?
                        <Form.Control type='number' value={state.gateQuota} onChange={(e) => quotaChangedHandler(e.target.value)} />
                        : null
                    }
                </Col>
            </Row>
            <br />
        </>
    }

    const renderSlotSize = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>Slot size in minutes: </FormLabel>
                </Col>

                <Col md='4'>
                    <Form.Check name='slotSize' inline custom type='radio' id='slotSize-60' label='60' value='60' onChange={slotSizeChangedHandler} defaultChecked />
                    <Form.Check name='slotSize' inline custom type='radio' id='slotSize-30' label='30' value='30' onChange={slotSizeChangedHandler} />
                </Col>
            </Row>
            <br />
        </>
    }

    const renderState = () => {
        return <>
            <Row className='justify-content-md-center'>
                <Col md='2'>
                    <FormLabel>State: </FormLabel>
                </Col>
                <Col md='2'>
                    <Button onClick={() => stateClickedHandler('Available')} variant={state.ruleState === 'Available' ? 'success' : 'secondary'} block> Open </Button>
                </Col>
                <Col md='2'>
                    <Button onClick={() => stateClickedHandler('Unavailable')} variant={state.ruleState === 'Unavailable' ? 'success' : 'secondary'} block> Close </Button>
                </Col>
            </Row>
            <br />
        </>
    }

    return <Page backLink='/booking/calendar-rule' backToWhere='Rules'>
        <h4>
            {'Add/Edit Rule'}
        </h4><hr className='mt-0' />
        <Row className='justify-content-md-center'>
            <Col md='6'>
                {renderErrors()}
            </Col>
        </Row>
        {renderBusinessArea()}
        {renderType()}
        {renderQuota()}
        {renderSlotSize()}
        {renderTimeRange()}
        {renderWeekday()}
        {renderSpecificDate()}
        {renderState()}

        <Row className='justify-content-md-center'>
            <Col md='2'><span></span></Col>
            <Col md='4'>
                <Button onClick={saveClickedHandler} block>Save</Button>
            </Col>
        </Row>
        <LoadingIndicator id='createRule' />
    </Page>
}

const mapStateToProps = state => {
    return {
        businessAreas: state.calendar.businessAreas,
        rule: state.calendar.rule,
        rules: state.calendar.rules
    }
}

export default connect(mapStateToProps, { getRule, saveRule, getRules, getBusinessAreas })(AddOrEditCalendarRule);