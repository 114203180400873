import UnitSearch from '../UnitSearch';
import UnitDetails from '../UnitDetails';
import HomePage from '../../component/HomePage/HomePage';
import Logout from '../../component/Logout/Logout';
import NoAuth from '../../component/NoAuth/NoAuth';
import ShipmentSearch from '../ShipmentSearch';
import ShipmentDetail from '../ShipmentDetail';
import UnitSplitContainer from '../UnitSplitContainer';
import Notification from '../Notification';
import NotificationDetail from '../NotificationDetail';
import UserList from '../UserList';
import AddOrEditUser from '../AddOrEditUser';
import ActionUrl from '../ActionUrl';
import CarrierBookings from '../CarrierBookings';
import CompanyList from '../CompanyList';
import AddOrEditCompany from '../AddOrEditCompany';
import ChangePassword from '../ChangePassword';
import CalendarRuleList from '../CalendarRuleList';
import AddOrEditCalendarRule from '../AddOrEditCalendarRule';
import RoleList from "../RoleList";
import Booking from '../Booking';
import Bookings from '../Bookings';
import SelectTimeSlot from '../SelectTimeSlot';
import CreateBookingContainer from '../CreateBookingContainer';
import ConfirmBooking from '../ConfirmBooking';
import UpdateBookingContainer from '../UpdateBookingContainer';
import EditRole from '../EditRole';
import CreateRole from '../CreateRole';
import CreateThuDetail from '../CreateThuDetail';
import AddOrEditSuffix from '../AddOrEditSuffix';
import OpenAdditionalGate from '../OpenAdditionalGate';
import ReservedSlotRuleList from '../ReservedSlotRuleList';
import AddOrEditReservedSlotRule from '../AddOrEditReservedSlotRule';
import Tasks from '../Tasks';
import CreateOrUpdateTask from '../CreateOrUpdateTask';
import History from '../History';
import EditHistoryJsonDefinition from '../EditHistoryJsonDefinition';
import ServiceList from '../ServiceList';
import AddOrEditService from '../AddOrEditService';

export const routes = [
    {
        path: '/',
        name: '',
        component: HomePage
    },
    {
        path: '/unit',
        name: 'UnitSearch',
        component: UnitSearch
    },
    {
        path: '/unit/:unitId/split',
        name: 'UnitSplitContainer',
        component: UnitSplitContainer
    },

    {
        path: '/unit/:unitId',
        name: 'UnitDetails',
        component: UnitDetails
    },

    {
        path: '/shipment',
        name: 'ShipmentSearch',
        component: ShipmentSearch
    },


    {
        path: '/shipment/:shipmentReference/:shipmentId',
        name: 'ShipmentDetail',
        component: ShipmentDetail
    },

    {
        path: '/action-url/:guid',
        name: 'ActionUrl',
        component: ActionUrl
    },

    {
        path: '/booking/calendar-rule',
        name: 'CalendarRuleList',
        component: CalendarRuleList
    },
    {
        path: '/booking/calendar-rule/open-additional-gate',
        name: 'OpenAdditionalGate',
        component: OpenAdditionalGate
    },
    {
        path: '/booking/calendar-rule/:ruleid',
        name: 'AddOrEditCalendarRule',
        component: AddOrEditCalendarRule
    },
    {
        path: '/booking/reserved-slot-rule',
        name: 'ReservedSlotRuleList',
        component: ReservedSlotRuleList
    },
    {
        path: '/booking/reserved-slot-rule/:ruleid',
        name: 'AddOrEditReservedSlotRule',
        component: AddOrEditReservedSlotRule
    },
    {
        path: '/booking',
        name: 'Booking',
        component: Booking
    },
    {
        path: '/booking/list',
        name: 'Bookings',
        component: Bookings
    },
    {
        path: '/booking/carrier-booking-list',
        name: 'CarrierBookings',
        component: CarrierBookings
    },
    {
        path: '/booking/new',
        name: 'CreateBookingContainer',
        component: CreateBookingContainer
    },
    {
        path: '/booking/new/select-time-slot',
        name: 'SelectTimeSlot',
        component: SelectTimeSlot
    },
    {
        path: '/booking/:bookingid/confirm',
        name: 'ConfirmBooking',
        component: ConfirmBooking
    },
    {
        path: '/booking/:bookingid',
        name: 'UpdateBookingContainer',
        component: UpdateBookingContainer
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification
    },
    {
        path: '/notification/:suffix',
        name: 'Notification',
        component: AddOrEditSuffix
    },
    {
        path: '/notification/email/:notificationId',
        name: 'NotificationDetail',
        component: NotificationDetail
    },
    {
        path: '/company',
        name: 'CompanyList',
        component: CompanyList
    },
    {
        path: '/company/:companycode',
        name: 'AddOrEditCompany',
        component: AddOrEditCompany
    },

    {
        path: '/user',
        name: 'UserList',
        component: UserList
    },
    {
        path: '/user/:username/change-password',
        name: 'ChangePassword',
        component: ChangePassword
    },
    {
        path: '/user/:username',
        name: 'AddOrEditUser',
        component: AddOrEditUser
    },
    {
        path: '/noAuthorization',
        name: 'NoAuth',
        component: NoAuth
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/role',
        name: 'RoleList',
        component: RoleList
    },
    {
        path: '/role/new',
        name: 'CreateRole',
        component: CreateRole
    },
    {
        path: '/role/:roleName',
        name: 'EditRole',
        component: EditRole
    },
    {
        path: '/thuDetails/:shipmentReferenceNumber/:shipmentId',
        name: 'CreateThuDetail',
        component: CreateThuDetail
    },
    {
        path: '/task',
        name: 'Tasks',
        component: Tasks
    },
    {
        path: '/task/:taskid',
        name: 'CreateOrUpdateTask',
        component: CreateOrUpdateTask
    },
    {
        path: '/history',
        name: 'History',
        component: History
    },
    {
        path: '/edit-history-json-definition',
        name: 'EditHistoryJsonDefinition',
        component: EditHistoryJsonDefinition
    },
    {
        path: '/history/:objecttype/:entityid',
        name: 'History',
        component: History
    },
    {
        path: '/service',
        name: 'ServiceList',
        component: ServiceList
    },
    {
        path: '/service/:serviceId',
        name: 'AddOrEditService',
        component: AddOrEditService
    },
]