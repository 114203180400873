import React, { useState } from "react";
import styles from "./Login.module.css";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

import { Row, Col, Card } from "react-bootstrap";
import {
  Button,
  TextField,
  PasswordField,
  ButtonStack,
} from "@dfds-ui/react-components";
import { Mail } from "@dfds-ui/icons";

const LoginForm = ({ onFormSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setPasswordEmailError] = useState(false);

  const onClick = () => {
    setShowEmailError(false);
    setPasswordEmailError(false);

    if (email && password) {
      onFormSubmit(email, password);
    }

    if (!email) {
      setShowEmailError(true);
    }

    if (!password) {
      setPasswordEmailError(true);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClick();
    }
  };
  return (
    <>
      <div className={`${styles.background}`}></div>
      <div className={`row ${styles.loginForm}`}>
        <div className="col-12 my-auto">
          <div className="card col-md-6 mx-auto px-0">
            <div style={{ display: "contents" }}>
              <LoadingIndicator id="login" />
              <Card.Body>
                <Row className="mb-4">
                  <Col>
                    <h2>
                      <b>Log in</b>
                    </h2>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <TextField
                      label="Email"
                      value={email}
                      onKeyPress={handleKeyPress}
                      onChange={(event) => setEmail(event.target.value)}
                      errorMessage={showEmailError ? "Email is mandatory" : ""}
                      icon={<Mail />}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <PasswordField
                      label="Password"
                      value={password}
                      onKeyPress={handleKeyPress}
                      onChange={(event) => setPassword(event.target.value)}
                      errorMessage={
                        showPasswordError ? "Password is mandatory" : ""
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <ButtonStack align="center">
                      <Button variation="primary" onClick={onClick}>
                        Log in
                      </Button>
                    </ButtonStack>
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
