import CreateOrUpdateTask from "../RoutedComponents/CreateOrUpdateTask";

export const GeneralPolicyList = {
  Policies: {
    Allowance: ["Booking", "UnitDetails"],
  },
};

export const LayoutPolicyList = {
  Policies: {
    Header: {
      components: [
        {
          ComponentName: "UserAdminSection",
          AllowedPolicies: ["UserAdminPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "NotificationAdminSection",
          AllowedPolicies: ["SuffixAdminPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "CompanyAdminSection",
          AllowedPolicies: ["CompanyAdminPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "BookingAdminSection",
          AllowedPolicies: ["BookingAdminPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "RoleAdminSection",
          AllowedPolicies: ["RoleAdminPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "DividerSection",
          AllowedPolicies: [
            "RoleAdminPolicy",
            "UserAdminPolicy",
            "SuffixFullAccessPolicy",
            "CompanyAdminPolicy",
            "BookingAdminPolicy",
          ],
          Action: "Hide",
        },
        {
          ComponentName: "BookingInternalLinkSection",
          AllowedPolicies: ["BookingInternalPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "BookingExternalLinkSection",
          AllowedPolicies: ["BookingExternalPolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "InternalUserSection",
          AllowedPolicies: [
            "CrawlerFullAccessPolicy",
            "DocumentFullAccessPolicy",
            "ShipmentFullAccessPolicy",
          ],
          Action: "Hide",
        },
        {
          ComponentName: "TaskLinkSection",
          AllowedPolicies: ["TaskBasePolicy"],
          Action: "Hide",
        },
        {
          ComponentName: "HistorySection",
          AllowedPolicies: ["HistoryPolicy"],
          Action: "Hide",
        },
      ],
    },
  },
};

export const ComponentPolicyList = {
  Policies: {
    HomePage: {},
    UnitSearch: {
      routePolicy: ["CrawlerFullAccessPolicy"],
      components: [],
    },
    UnitSplitContainer: {
      routePolicy: ["CrawlerFullAccessPolicy"],
    },
    UnitDetails: {
      routePolicy: ["CrawlerFullAccessPolicy"],
    },
    ShipmentSearch: {
      routePolicy: ["ShipmentFullAccessPolicy"],
    },
    ShipmentDetail: {
      routePolicy: ["ShipmentFullAccessPolicy"],
    },
    ActionUrl: {
      routePolicy: [
        "DownloadDocumentPolicy",
        "DocumentFullAccessPolicy",
        "ShipmentFullAccessPolicy",
      ],
    },
    CalendarRuleList: {
      routePolicy: ["BookingAdminPolicy"],
    },
    OpenAdditionalGate: {
      routePolicy: ["BookingAdminPolicy"],
    },
    ReservedSlotRuleList: {
      routePolicy: ["BookingAdminPolicy"],
    },
    AddOrEditReservedSlotRule: {
      routePolicy: ["BookingAdminPolicy"],
    },
    AddOrEditCalendarRule: {
      routePolicy: ["BookingAdminPolicy"],
    },
    Booking: {
      routePolicy: ["BookingBasePolicy"],
      components: [
        {
          ComponentName: "AllBookingHoc",
          AllowedPolicies: ["BookingInternalPolicy"],
        },
        {
          ComponentName: "MyBookingHoc",
          AllowedPolicies: ["BookingExternalPolicy"],
        },
      ],
    },
    Bookings: {
      routePolicy: ["BookingInternalPolicy"],
    },
    CarrierBookings: {
      routePolicy: ["BookingExternalPolicy"],
    },
    CreateBookingContainer: {
      routePolicy: ["BookingBasePolicy"],
      components: [
        {
          ComponentName: "AdministratorHoc",
          AllowedPolicies: ["BookingInternalPolicy"],
        },
        {
          ComponentName: "ExternalHoc",
          AllowedPolicies: ["BookingExternalPolicy"],
        },
      ],
    },
    SelectTimeSlot: {
      routePolicy: ["BookingBasePolicy"],
    },
    ConfirmBooking: {
      routePolicy: ["BookingBasePolicy"],
      components: [
        {
          ComponentName: "AdministratorHoc",
          AllowedPolicies: ["BookingInternalPolicy"],
        },
        {
          ComponentName: "ExternalHoc",
          AllowedPolicies: ["BookingExternalPolicy"],
        },
      ],
    },
    UpdateBookingContainer: {
      routePolicy: ["BookingBasePolicy"],
      components: [
        {
          ComponentName: "AdministratorHoc",
          AllowedPolicies: ["BookingInternalPolicy"],
        },
        {
          ComponentName: "ExternalHoc",
          AllowedPolicies: ["BookingExternalPolicy"],
        },
      ],
    },
    Notification: {
      routePolicy: ["SuffixAdminPolicy"],
    },
    NotificationDetail: {
      routePolicy: ["SuffixAdminPolicy"],
    },
    AddOrEditSuffix: {
      routePolicy: ["SuffixAdminPolicy"],
    },
    CompanyList: {
      routePolicy: ["CompanyAdminPolicy"],
    },
    AddOrEditCompany: {
      routePolicy: ["CompanyAdminPolicy"],
    },
    UserList: {
      routePolicy: ["UserAdminPolicy"],
    },
    ChangePassword: {
      routePolicy: ["UserAdminPolicy"],
    },
    AddOrEditUser: {
      routePolicy: ["UserAdminPolicy"],
    },
    NoAuth: {},
    Logout: {},
    RoleList: {
      routePolicy: ["RoleAdminPolicy"],
    },
    CreateRole: {
      routePolicy: ["RoleAdminPolicy"],
    },
    EditRole: {
      routePolicy: ["RoleAdminPolicy"],
    },
    CreateThuDetail: {
      routePolicy: ["CargoFullAccessPolicy"],
    },
    Shipments: {
      routePolicy: ["ShipmentFullAccessPolicy"],
    },
    Tasks: {
      routePolicy: ["TaskBasePolicy"],
      components: [
        {
          ComponentName: "MarkAsArrivedOnTask",
          AllowedPolicies: ["TaskInternalPolicy"],
        },
        {
          ComponentName: "EnterGateNumberOnTask",
          AllowedPolicies: ["TaskInternalPolicy"],
        },
        {
          ComponentName: "EditTask",
          AllowedPolicies: ["TaskInternalPolicy"],
        },
        {
          ComponentName: "KPIReport",
          AllowedPolicies: ["KPIReportAccessPolicy"],
        },
      ],
    },
    CreateOrUpdateTask: {
      routePolicy: ["TaskBasePolicy"],
      components: [
        {
          ComponentName: "TaskCreateInternalHoc",
          AllowedPolicies: ["TaskInternalPolicy"],
        },
      ],
    },
    History: {
      routePolicy: ["HistoryPolicy"],
    },
  },
};
