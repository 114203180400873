/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getUserList } from '../store/action/userActions';

import history from '../core/history';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { Button } from '@dfds-ui/react-components/button';
import { Card } from 'react-bootstrap';
import Page from '../component/Page/Page';

const UserList = ({ pageIndex, userList, getUserList }) => {
    useEffect(() => {
        if (!userList) {
            getUserData(null);
        }
    }, [userList])
    const getUserData = () => {
        getUserList(pageIndex);
    }
    const userClickedHandler = user => {
        history.push('/user/' + user.username);
    }

    const changePasswordHandler = user => {
        history.push('/user/' + user.username + '/change-password');
    }
    const loadMoreDataClickedHandler = () => {
        getUserData(pageIndex)
    }

    const newUserClickedHandler = () => {
        history.push('/user/new');
    }


    const renderStatusColumn = row => {
        return row.status === 1 || row.status === '1' ? <span className='text-success'>Active</span> : <span className='text-danger'>Passive</span>;
    }

    const tableConfigurations = {
        title: 'Users',
        noRowText: 'There are not any users to show.',
        hasFilter: false,
        entities: userList,
        columns: [
            { alias: 'Username', name: 'username', type: 'string' },
            { alias: 'Email', name: 'email', type: 'string' },
            { alias: 'Name', name: 'name', type: 'string', style: { whiteSpace: 'nowrap' } },
            { alias: 'Surname', name: 'surname', type: 'string' },
            { alias: 'Company', name: 'companyCode', type: 'string' },
            { alias: 'Region', name: 'region', type: 'string' },
            { alias: 'Status', name: 'status', type: 'custom', customRender: renderStatusColumn },
            { alias: 'Roles', name: 'roles', type: 'array' },
        ],
        tableActions: [
            { clickEvent: newUserClickedHandler, buttonText: 'Create User', icon: 'plus' }
        ],
        rowActions: [
            { title: 'Edit user', clickEvent: userClickedHandler, icon: 'edit' },
            { title: 'Change password', clickEvent: changePasswordHandler, icon: 'key' }
        ],
    }

    return <Page>
        <XmsTable config={tableConfigurations} />
        <div className='text-center'>
            <Button disabled={pageIndex === null || pageIndex === '{}'} variant={'secondary'} onClick={loadMoreDataClickedHandler} className='text-center'>Load More</Button>
        </div>
        <LoadingIndicator id='getUserList' />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        pageIndex: state.user.userListResponse ? state.user.userListResponse.pageIndex : null,
        userList: state.user.userListResponse ? state.user.userListResponse.items : null,
    }
}
export default connect(mapStateToProps, { getUserList })(UserList);  
