/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllConfigurations, createConfiguration } from '../../store/action/gateQuotaActions';
import { toast } from 'react-toastify';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const DefaultQuota = ({ defaultQuota, getAllConfigurations, createConfiguration }) => {
    const [quota, setQuota] = useState(0);
    useEffect(() => {
        getAllConfigurations();
    }, []);

    useEffect(() => {
        if (defaultQuota) {
            setQuota(defaultQuota.quota)
        }
    }, [defaultQuota]);

    const defaultQuotaChangedHandler = (event) => {
        if (event.target.value >= 0 && event.target.value <= 999) {
            setQuota(event.target.value)
        }

    }

    const defaultQuotaOnBlurHander = event => {
        if (event.target.value < 0 || event.target.value > 999) {
            toast.error('Please enter a number between 0-999.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
            return;
        }
        if (defaultQuota.quota !== quota) {
            defaultQuota.quota = quota;
            createConfiguration(defaultQuota).then(response => {
                toast.success('Default number of gates is saved.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
            })
        }

    }
    return (<>
        {defaultQuota ?
            <div className="d-flex justify-content-end">
                <LoadingIndicator id="createConfig" />
                <label className="col-form-label form-label" style={{ whiteSpace: "nowrap", paddingRight: '0.5rem' }}>Default number of gates: </label>
                <input className="form-control" max="999" style={{ maxWidth: '5.5rem', textAlign: 'center' }} type="number" value={quota} onChange={defaultQuotaChangedHandler} onBlur={defaultQuotaOnBlurHander} />
            </div> : null}
    </>)
}

const mapStateToProps = state => {
    return {
        defaultQuota: state.gateQuota.defaultConfig
    }
}
export default connect(mapStateToProps, { getAllConfigurations, createConfiguration })(DefaultQuota);
