import { Col, Form } from "react-bootstrap";
import { AuthorizationWrapper } from "./AuthorizationRenderer";
import * as PropTypes from "prop-types";
import React from "react";
import { ComboboxFormComponent } from "../../ComboboxFormComponent";

export const ComboboxRenderer = (listOfItems, firstItemObject, field, formColumnCount) => {
    const comboboxControl = (<Form.Group key={field.name} as={Col} md={formColumnCount}>
        <Form.Label>{field.name}{field.isRequired && ' *'}</Form.Label>
        <ComboboxFormComponent name={field.id} listOfItems={listOfItems}
            firstItemObject={firstItemObject}
            isRequired={true}
            isDisabled={field.isDisabled}
            validationArray={field.validations} />
    </Form.Group>);

    if (field.authorizationWrapper) {
        return AuthorizationWrapper(comboboxControl, field.id, field.authorizationWrapper);
    }
    return comboboxControl;
};

ComboboxRenderer.propTypes = {
    item: PropTypes.object.isRequired
};