import React from 'react';
import { Button } from '@dfds-ui/react-components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SplitActionButton = ({ icon, text, clicked, disabled }) => {
    return (
        <Button
            disabled={disabled}
            className="btn-space"
            onClick={clicked}
            >
            <FontAwesomeIcon icon={icon} style={{ marginRight: '10px' }} />
            {text}
        </Button>
    );
};

export default SplitActionButton;