const actionTypes = Object.freeze({
    ADD_FILE_TO_FILE_LIST: Symbol('ADD_FILE_TO_FILE_LIST'),
    REMOVE_FILE_FROM_FILE_LIST: Symbol('REMOVE_FILE_FROM_FILE_LIST'),
    SAVE_FILE_LIST: Symbol('SAVE_FILE_LIST'),
    ADD_SHIPMENTS_TO_LIST: Symbol('ADD_SHIPMENTS_TO_LIST'),
    SAVE_DOCS_MISSING_STATE_LIST: Symbol('SAVE_DOCS_MISSING_STATE_LIST'),
    SAVE_DOCS_MISSING_STATE: Symbol('SAVE_DOCS_MISSING_STATE'),
    UPDATE_SEARCHED_SHIPMENT: Symbol('UPDATE_SEARCHED_SHIPMENT'),
    INITALIZE_SHIPMENT_DATA: Symbol('INITALIZE_SHIPMENT_DATA'),
});

export default actionTypes;