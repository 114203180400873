import React from 'react'
import { Radio } from '@dfds-ui/react-components';

const quickFilters = [
    { value: 'all', label: 'All' },
    { value: 'myTasks', label: 'My Tasks' },
    // { value: 'group', label: 'Group Tasks', disabled: true }
]
const QuickFilters = ({ selectedQuickFilter = 'all', onSelectedQuickFilterChange }) => {
    return <>
        {quickFilters.map(q => <Radio
            key={q.value}
            value={q.value}
            name='quick-filter'
            className='mr-3 mt-3'
            disabled={q.disabled}
            onChange={e => onSelectedQuickFilterChange(e)}
            checked={selectedQuickFilter === q.value}>
            {q.label}
        </Radio>)}
    </>
}

export default QuickFilters;