import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap'
import { TextField, ReactSelect, Button, ButtonStack } from '@dfds-ui/react-components'

// const getYearMonthDay = dateAsString => {
//     const splittedDate = dateAsString.split('-');
//     return { year: splittedDate[0], month: splittedDate[1] - 1, day: splittedDate[2] }
// }


const Filters = ({ entityId, selectedObjectType, objectTypes, onObjectTypeChanged, onEntityIdChanged, onFilterClicked }) => {
    
    // const [err, setErr] = useState('');
    // let startEpoch, endEpoch = 0;
    // const onDateChangedHandler = (start, end) => {
    //     if (start) {
    //         const startSplit = getYearMonthDay(start);
    //         startEpoch = utcToEpoch(new Date(startSplit.year, startSplit.month, startSplit.day));
    //     }

    //     if (end) {
    //         const endSplit = getYearMonthDay(end);
    //         endEpoch = utcToEpoch(new Date(endSplit.year, endSplit.month, endSplit.day));
    //     }
    //     if (startEpoch > endEpoch) {
    //         setErr('Start date must be smaller than end date.');
    //         return;
    //     }else{
    //         setErr('');
    //     }

    //     onDateChanged(startEpoch, endEpoch);
    // }
    const [filterEntityId, setFilterEntityId] = useState(entityId); 
    
    useEffect(() => {
        setFilterEntityId(entityId);
    }, [entityId]);

    const onFilterEntityIdChanged = value => {
        setFilterEntityId(value);
    } 

    return <Row>
        {/* <Col lg='9' md='9'>
             <DateRangePicker
                valid={err === ''}
                infoLabel={err}
                onDateChange={(a, b) => onDateChangedHandler(a, b)}
                startDates={{
                    initial: epochToShortDate(startDateEpoch, 'YYYY-MM-DD'),
                    minDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
                    maxDate: moment().add(1, 'day').format('YYYY-MM-DD'),
                }}
                endDates={{
                    initial: epochToShortDate(endDateEpoch, 'YYYY-MM-DD'),
                    minDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
                    maxDate: moment().add(1, 'day').format('YYYY-MM-DD'),
                }}
                labels={{ start: 'Start date', end: 'End date', header: 'Select a date' }}
            /> 
        </Col> 
        <Col lg='3' md='3'>
            <ReactSelect name='actionType' placeholder='Action Type' options={actionTypes} value={selectedActionType} onChange={selected => onActionTypeChanged(selected)} />
        </Col> */}
        <Col lg='6' md='6'>
            <TextField errorMessage={entityId === '' ? 'Entity ID is required.' : ''} name='entityId' placeholder='Enter ID' className='mb-0' value={filterEntityId} onChange={e => onFilterEntityIdChanged(e.target.value)} onBlur={e => onEntityIdChanged(e.target.value)} />
        </Col>
        <Col lg='4' md='4'>
            <ReactSelect css={{ paddingTop: '4px' }} name='objectType' placeholder='Entity Type' options={objectTypes} value={selectedObjectType} onChange={selected => onObjectTypeChanged(selected)} />
        </Col>
        <Col lg='2' md='2'>
            <ButtonStack orientation='vertical' >
                <Button variation='primary' disabled={!entityId || !selectedObjectType || !selectedObjectType.value} onClick={onFilterClicked}>Filter</Button>
            </ButtonStack>
        </Col>
    </Row>
}

Filters.propTypes = {
    onEntityIdChanged: PropTypes.func.isRequired,
    onObjectTypeChanged: PropTypes.func.isRequired,
    //onActionTypeChanged: PropTypes.func.isRequired,
    //onDateChanged: PropTypes.func.isRequired
}

export default Filters;