/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import styles from './UnitDetail.module.css';
import UnitDetailShipmentRow from './UnitDetailShipmentRow';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { spinnerService } from "@simply007org/react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnitDetailTransportRow = ({ transport }) => {

    const [hasNoDocumentTransport, setHasNoDocumentTransport] = useState(false);
    const [hasMissingDocumentTransport, setHasMissingDocumentTransport] = useState(false);
    const [isFileFetched, setIsFileFetched] = useState(false);

    const collapseId = `collapse${transport.id.replace(' ', '_')}`;

    useEffect(() => {
        if (!isFileFetched) {
            spinnerService.show('fileFetchLoading');
        } else {
            spinnerService.hide('fileFetchLoading');
        }
    }, [isFileFetched])

    const renderTransportInfo = () => {
        return (
            <div className='card-header pl-5 collapsed' href={`#${collapseId}`} data-toggle='collapse' data-parent='#accordion'>
                <h5 className='mb-0'>
                    <div className='row'>
                        {renderCollapseArrow()}
                        {renderTransportNameAndId(transport)}
                        <div className='ml-auto text-right mt-2'>
                            {renderNoDocumentsWarning(hasNoDocumentTransport)}
                            {renderDocsMissingWarning(hasMissingDocumentTransport)}
                            <LoadingIndicator id='fileFetchLoading' />
                        </div>
                    </div>
                </h5>
            </div>
        )
    }

    const renderShipments = () => {

        if (!transport) { return; }

        return transport.shipments.map(shipment => {
            return (
                <div id={collapseId} key={shipment.referenceNumber} className='collapse'>
                    <UnitDetailShipmentRow
                        shipment={shipment}
                        changeNoDocumentsState={setHasNoDocumentTransport}
                        changeDocsMissingState={setHasMissingDocumentTransport}
                        setIsFileFetched={setIsFileFetched}
                    />
                </div>
            )
        })
    }

    return (
        <div className='card'>
            {renderTransportInfo()}
            {renderShipments()}
        </div>
    )
}


const renderNoDocumentsWarning = (hasNoDocument) => {

    if (!hasNoDocument) { return; }

    return (
        <a className='text-warning mr-3'>
            <FontAwesomeIcon title="Shipment Found with No Document" icon="exclamation-triangle" />
        </a>
    )
}

const renderDocsMissingWarning = (hasMissingDocument) => {

    if (!hasMissingDocument) { return; }

    return (
        <img src='/image/icon/missing-image-icon.jpg' title='Documents Missing' alt='' className={`${styles.missingDocumentIcon}`} />
    )
}

const renderCollapseArrow = () => {
    return (
        <div>
            <img src="/image/icon/down.svg" alt='' className={`${styles.collapseArrow}`} />
        </div>
    )
}

const renderTransportNameAndId = transport => {
    return (
        <div>
            <div>{transport.name}</div>
            <div className='dfds-font-light'>{transport.id}</div>
        </div>
    )
}

export default UnitDetailTransportRow;