import {Field} from "redux-form";
import React from "react";
import {InputComponent} from "./Pure/InputComponent";
import {memoize} from 'lodash'
import * as PropTypes from "prop-types";
import {addValidationToArray} from "./Validation/FormValidation";

const memoizedPrepareListOfItems = memoize((listOfItems, firstItemObject) => {
    let items = listOfItems ? listOfItems : [];
    if(firstItemObject){
        items.unshift(firstItemObject);
    }
    return items;
});

export const ComboboxFormComponent = ({name, listOfItems, firstItemObject, isRequired, isDisabled, validationArray}) => {
    const list = memoizedPrepareListOfItems(listOfItems, firstItemObject);
    const validation = addValidationToArray(isRequired, validationArray);
    return (<Field
        name= {name}
        component={InputComponent}
        as="select"
        disabled={isDisabled}
        validate={validation}>
        {list && list.map((item) => (
            <option value={item.value} key={item.value}>
                {item.text}
            </option>
        ))}
    </Field>)
};

ComboboxFormComponent.propTypes = {
    name: PropTypes.string.isRequired,
    listOfItems: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    })),
    firstItemObject: PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string.isRequired
    }),
    isRequired: PropTypes.bool,
    validationArray: PropTypes.arrayOf(PropTypes.func)
};