import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

const ModalInlineInformation = ({ modalData, onHide }) => {

    const handler = modalData.handler;
    const title = modalData.title;
    const data = modalData.data;

    return (
        <Modal show={true} centered onHide={onHide}>
            <Modal.Header closeButton>
                <Col>
                    <Modal.Title className="text-center">
                        {title}
                    </Modal.Title>
                </Col>
            </Modal.Header>
            <Modal.Body>
                {handler(data)}
            </Modal.Body>
        </Modal>
    )

};

export default ModalInlineInformation;