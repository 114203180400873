import PolicyGroupAccessHoc from "../../../../RoutedComponents/Routes/PolicyGroupAccessHoc";
import React from "react";
import {memoize} from "lodash";
import * as PropTypes from "prop-types";

export const AuthorizationWrapper = (control, key, policyId) => {
    return (
        <PolicyGroupAccessHoc componentName={policyId} key={key}>
            {control}
        </PolicyGroupAccessHoc>
    );
};

AuthorizationWrapper.propTypes = {
    control: PropTypes.element.isRequired,
    key: PropTypes.string.isRequired,
    policyId: PropTypes.string.isRequired
};