import React, { useEffect } from 'react';
import history from "../core/history";
import { Col, Row } from "react-bootstrap";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import moment from 'moment';
import { connect } from "react-redux";
import { saveTask, clearState, getUnitTypes, getTaskItem, updateTask, cancelTask, getBusinessAreas } from '../store/action/taskActions';
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import { CreateTaskSchema } from "../component/FormComponents/Schema/createTask";
import { EditTaskSchema } from "../component/FormComponents/Schema/editTask";
import Page from '../component/Page/Page';

const CreateOrUpdateTask = ({ match, isTaskCreated, isTaskUpdated, isTaskCancelled, saveTask, updateTask, cancelTask, getUnitTypes, unitTypes, getBusinessAreas, businessAreas, getTaskItem, taskItem, authenticatedUser, clearState }) => {
    const isUpdate = () => match.params.taskid !== 'new';

    useEffect(() => {
        if (isTaskCreated || isTaskUpdated || isTaskCancelled) {
            history.push('/task');
        }

    }, [isTaskCreated, isTaskUpdated, isTaskCancelled]);

    useEffect(() => {
        if (!unitTypes) {
            getUnitTypes();
        }

        if (!businessAreas) {
            getBusinessAreas();
        }

    }, [getUnitTypes, unitTypes, getBusinessAreas, businessAreas]);

    useEffect(() => {

        if (unitTypes && businessAreas && !taskItem) {
            console.log(taskItem);
            getTaskItem(match.params.taskid);
        }
    }, [getTaskItem, match.params.taskid, taskItem, unitTypes, businessAreas, getBusinessAreas]);

    useEffect(() => {
        return (() => {
            clearState();
        });
    }, [clearState]);

    const submit = (taskModel) => {
        taskModel.valueEpoch = moment.utc(taskModel.operationDate, "DD/MM/YYYY HH:mm").valueOf() / 1000;
        taskModel.channelType = "Web";

        if (taskModel.hasOwnProperty("operationDate")) {
            delete taskModel.operationDate;
        }

        if (isUpdate()) {
            updateTask(taskModel);
        } else {
            saveTask(taskModel);
        }


    };
    const getOperationTypeList = () => {
        return [
            { value: "Loading", text: "Loading" },
            { value: "Unloading", text: "Unloading" }
        ];
    };

    const cancelOperation = (model) => {
        console.log('Cancel Operation', model);
        cancelTask(model.operationId, authenticatedUser.id);
    };

    const getActualForm = () => {
        if (isUpdate()) {
            return (
                <SchemaInterpreter
                    title='Update Task'
                    form={"CreateTaskForBooking"}
                    onSubmit={submit}
                    formItem={taskItem}
                    cancelTask={cancelOperation}
                    formSchema={EditTaskSchema}
                    operationTypes={getOperationTypeList()}
                    typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
                    businessAreas={businessAreas}
                    businessAreasFirstItem={{ value: '', text: 'Choose Business Area' }}
                    unitTypes={unitTypes}
                    unitTypeFirstItemObject={{ value: '', text: 'Choose Unit Type' }}
                />
            );
        }

        return (
            <SchemaInterpreter
                title='Create Task'
                form={"CreateTaskForBooking"}
                onSubmit={submit}
                formItem={taskItem}
                formSchema={CreateTaskSchema}
                operationTypes={getOperationTypeList()}
                typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
                businessAreas={businessAreas}
                businessAreasFirstItem={{ value: '', text: 'Choose Business Area' }}
                unitTypes={unitTypes}
                unitTypeFirstItemObject={{ value: '', text: 'Choose Unit Type' }}
            />
        );
    };

    return <Page backLink='/task' backToWhere='Tasks'>
        <Row className="justify-content-md-center justify-content-sm-center">
            <Col lg={12} sm={8} xs={12}>
                {getActualForm()}
                <LoadingIndicator id="createOrUpdateTask" />
            </Col>
        </Row>
    </Page>
   
};
const mapStateToProps = state => {
    return {
        isTaskCreated: state.task.isTaskCreated,
        isTaskUpdated: state.task.isTaskUpdated,
        isTaskCancelled: state.task.isTaskCancelled,
        unitTypes: state.task.unitTypes,
        businessAreas: state.task.businessAreas,
        taskItem: state.task.taskItemModel,
        authenticatedUser: state.auth.authenticatedUser
    }
};

//export default CreateOrUpdateTask;
export default connect(mapStateToProps, { saveTask, updateTask, cancelTask, clearState, getUnitTypes, getBusinessAreas, getTaskItem })(CreateOrUpdateTask);
