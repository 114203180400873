import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button, ButtonStack } from '@dfds-ui/react-components';
import {
    createBooking,
    clearBookingState,
    updateBooking,
    getBusinessAreas,
    getUnitTypesForBooking
} from '../store/action/bookingActions';
import '../core/schema.css';
import history from '../core/history';
import { convertEpochToUtcTime } from '../core/convertEpochToUtcTime';
import './BookingCommon.css';
import moment from 'moment';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import BookingForm from '../component/BookingForm/BookingForm';
import { isOnlyExternalUser } from "../core/authentication";
import { CreateBookingSchema } from "../component/FormComponents/Schema/createBooking";
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import { ConfirmBookingSchema } from "../component/FormComponents/Schema/confirmBooking";
import Page from '../component/Page/Page';

const ConfirmBooking = ({ match, schemaJson, formData, createBooking, updateBooking, clearBookingState, bookingResult, selectedDate, selectedTime, getUnitTypesForBooking, unitTypesForBooking, getBusinessAreas, businessAreas }) => {
    const isInternalUser = () => {
        return !isOnlyExternalUser();
    }

    useEffect(() => {
        if (getPageHeader() === 'There is not any booking information. Redirecting in 3 seconds...') {
            setTimeout(() => {
                if (isInternalUser()){
                    history.push('/booking/list');
                }else{
                    history.push('/booking/carrier-booking-list');
                }
            }, 3000);
        }
    });
    const ref = useRef(null);
    useEffect(() => {
        if (bookingResult) {
            ref.current.scrollIntoView();
        }
    }, [bookingResult]);

    useEffect(() => {

        if (!businessAreas) {
            getBusinessAreas();
        }
        if (!unitTypesForBooking) {
            getUnitTypesForBooking();
        }
    }, [businessAreas, unitTypesForBooking]);

    const bookingId = match.params.bookingid;

  

    const getPageHeader = () => {
        if (!formData) {
            return 'There is not any booking information. Redirecting in 3 seconds...';
        }
        else if (bookingResult) {
            return 'Confirmed';
        }
        else {
            return 'Confirm your booking';
        }
    }

    const createBookingHandler = () => {
        createBooking(selectedTime, formData, isInternalUser());
    }

    const updateBookingHandler = () => {
        updateBooking(bookingId, formData, isInternalUser(), selectedDate, selectedTime);
    }

    const returnClickedHandler = () => {
        clearBookingState();
        if (!isOnlyExternalUser()) {
            history.push('/booking/list');
        } else {
            history.push('/booking/carrier-booking-list');
        }
    }

    const createNewBookingClickedHandler = () => {
        clearBookingState();
        history.push('/booking/new');
    }

    const getSubmitHandler = () => {
        if (bookingResult)
            return returnClickedHandler;
        if (bookingId === 'new')
            return createBookingHandler;
        return updateBookingHandler;
    }

    const renderReservationDateTime = () => {
        return <h6>Reservation Date Time: <strong>{moment.unix(selectedDate).format('L') + ' ' + convertEpochToUtcTime(selectedTime)} </strong> </h6>
    }

    const renderBookingResult = () => {
        return bookingResult ?
            <div ref={ref}>
                <div className="booking-result clearfix alert">
                    <h6><strong>Booking has been confirmed. Please note your reference number</strong></h6>
                    <h6>Reference Number: <strong>{bookingResult} </strong> </h6>
                    <h6>Reservation Date Time: <strong>{moment.unix(selectedDate).format('L') + ' ' + convertEpochToUtcTime(selectedTime)} </strong> </h6>
                </div>
                <div>
                    <ButtonStack align='right'>
                        <Button type="button" variant="primary" onClick={returnClickedHandler} className="btn-lg float-right">Return to main page</Button>
                        <Button type="button" variant="primary" onClick={createNewBookingClickedHandler} className="btn-lg float-right">Create Another Booking</Button>
                    </ButtonStack>
                </div>
            </div>
            : null
    }

    const getOperationTypeList = () => {
        return [
            { value: "false", text: "Loading" },
            { value: "true", text: "Unloading" }
        ];
    };

    const renderForm = () => {
        return <div>
            {renderReservationDateTime()}
            <SchemaInterpreter
                form={"ConfirmBookingSchema"}
                onSubmit={getSubmitHandler()}
                formItem={formData}
                formSchema={ConfirmBookingSchema}
                operationTypes={getOperationTypeList()}
                typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
                businessAreas={businessAreas}
                businessAreasFirstItem={{ value: '', text: 'Choose Business Area' }}
                unitTypes={unitTypesForBooking}
                unitTypeFirstItemObject={{ value: '', text: 'Choose Unit Type' }}
            />
        </div>
    }

    return <Page backLink='/booking/new/select-time-slot' backToWhere='Time Slot Selection'>
        <h4>{getPageHeader()}</h4><hr className='mt-0' />
        {renderForm()}
        {renderBookingResult && renderBookingResult()}
        <LoadingIndicator id="createOrUpdateBooking" />
    </Page>
    //     <Row>
    //         <Col>
    //             <PageHeader text={getPageHeader()} backButtonEnabled={getPageHeader() === 'Confirm your booking'} />
    //         </Col>
    //     </Row>
    //     <Row className="justify-content-md-center justify-content-sm-center mt-3">
    //         <Col lg={12} sm={8} xs={12} >
    //             <LoadingIndicator id="createOrUpdateBooking" />
    //             {renderForm()}
    //             {renderBookingResult && renderBookingResult()}
    //         </Col>
    //     </Row>
    // </>
    // )
}

const mapStateToProps = (state) => {
    return {
        formData: state.booking.formData,
        selectedDate: state.booking.selectedDate,
        selectedTime: state.booking.selectedTime,
        bookingResult: state.booking.bookingResult,
        authenticatedUser: state.auth.authenticatedUser,
        schemaJson: state.booking.schemaJson,
        businessAreas: state.booking.businessAreas,
        unitTypesForBooking: state.booking.unitTypesForBooking
    }
}
export default connect(mapStateToProps, { createBooking, updateBooking, getBusinessAreas, getUnitTypesForBooking, clearBookingState })(ConfirmBooking);
