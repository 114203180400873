import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import login from '../Login';
import PolicyRoutingHoc from './PolicyRoutingHoc';
import Header from '../../component/Header/Header';
import classes from './routes.module.css';
import history from '../../core/history'
import AuthorizeContext from '../../context/AuthorizeContext';
import { LayoutPolicyList } from "../../context/Policy";
import { routes } from './routes';

const XMSRoutes = () => {
    return <>
        <Router history={history}>
            <AuthorizeContext.Provider value={LayoutPolicyList.Policies.Header}>
                <Header />
            </AuthorizeContext.Provider>
            <div className={[classes.container, 'container'].join(' ')}>
                <Switch>
                    {routes.map((route, i) => <PolicyRoutingHoc key={i} exact path={route.path} component={route.component} name={route.name} />)}
                    <Route path='/login' component={login} />
                </Switch>
            </div>

        </Router>
    </>
}

export default XMSRoutes;