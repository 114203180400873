/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import './FileControllerOperationButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileControllerOperationButtons = (props) => {

    let inputFileElement;


    // Input objects from parent component
    const {
        operationButtonsToShow,
        onFilesSelected,
        onDownloadAllDocuments,
        fileList,
        splitUrl,
        onUploadDocuments,
        onShareDocuments,
        onShowCoverPage,
        missingDocumentOptions,
        onToggleDocsMissingState
    } = props;

    const filesSelected = (e) => {
        onFilesSelected(e.target.files)
        e.target.values = '';
        e.target.value = '';
    }

    const renderAddFileIcon = () => {
        if (!operationButtonsToShow.addFile) { return; }
        return (
            <>
                <input
                    ref={input => inputFileElement = input}
                    type='file'
                    className='d-none'
                    onChange={(e) => filesSelected(e)}
                    multiple
                />
                <a onClick={() => inputFileElement.click()}>
                    <FontAwesomeIcon title="Add Document" icon="plus" size="2x" className="mx-3" />
                </a>
            </>
        )
    }

    const renderSplitIcon = () => {
        if (!operationButtonsToShow.split) { return; }

        return (
            <Link to={splitUrl}>
                <img
                    className='fileControllerOperationButtonsIcon mx-3'
                    src='/image/file-controller/scanner.png'
                    alt=''
                    title="Add Multiple Shipment Documents"
                />
            </Link>
        )
    }



    const renderUploadIcon = () => {
        if (!operationButtonsToShow.upload) { return; }

        const notUploadedFileList = fileList ? Object.values(fileList).find(a => a.status === 'not-uploaded') : null;
        if (notUploadedFileList) {
            return (
                <a onClick={onUploadDocuments}>
                    <FontAwesomeIcon title="Upload Documents" icon="upload" size="2x" className="mx-3 color-yes-green" />
                </a>
            )
        }

        return (
            <a>
                <FontAwesomeIcon title="Upload Documents" icon="upload" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderDownloadIcon = () => {
        if (!operationButtonsToShow.download) { return; }

        return (
            <a onClick={onDownloadAllDocuments}>
                <FontAwesomeIcon title="Download Documents" icon="download" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderShareIcon = () => {
        if (!operationButtonsToShow.shareDocuments) { return; }

        return (
            <a onClick={onShareDocuments}>
                <FontAwesomeIcon title="Share Documents" icon="share-alt" size="2x" className="mx-3" />
            </a>
        )
    }

    const renderCoverPageIcon = () => {
        if (!operationButtonsToShow.coverpage) { return; }

        return (
            <a onClick={onShowCoverPage}>
                <FontAwesomeIcon title="Unit Summary" icon={['far', 'file-alt']} size="2x" className="mx-3" />
            </a>
        )
    }

    const renderChangeDocsMissingState = () => {

        if (!operationButtonsToShow.missingDocuments) { return; }

        if (missingDocumentOptions.hasDocsMissingFlagSet) {
            return (
                <a onClick={onToggleDocsMissingState}>
                    <FontAwesomeIcon title="Unset Document Missing" icon="file-excel" size="2x" className="mx-3 color-yes-green" />
                </a>
            )
        } else {
            return (
                <a onClick={onToggleDocsMissingState}>
                    <FontAwesomeIcon title="Set Document Missing" icon="file-excel" size="2x" className="mx-3" />
                </a>
            )
        }

    }

    return (
        <>
            <div className='d-flex'>
                {renderAddFileIcon()}
                {renderSplitIcon()}
                {renderDownloadIcon()}
                {renderUploadIcon()}
                {renderShareIcon()}
                {renderCoverPageIcon()}
                {renderChangeDocsMissingState()}
            </div>
        </>
    )
}

export default FileControllerOperationButtons;