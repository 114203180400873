import {Field} from "redux-form";
import * as PropTypes from "prop-types";
import React from "react";
import {addValidationToArray} from "./Validation/FormValidation";

export const RadioButtonGroupFormComponent = ({name, options, isRequired=false, validationArray}) => {
    const validation = addValidationToArray(isRequired, validationArray);
    return (<div className="field-radio-group">
        <Field
            component={({input, options, meta: {touched, error, warning}}) => {
                    return (<>
                    {options.map(option => <label key={name + '-' + option.value} className="radio-inline ">
                        <span>
                        <input
                            id={name + '-' + option.value}
                            type='radio'
                            {...input}
                            value={option.value}
                            checked={option.value === input.value}
                        />
                        <span><label htmlFor={name + '-' + option.value}>{option.text}</label></span>
                        </span>
                    </label>)}
                        {touched && ((error && <span className="text-danger" style={{display: 'block', marginTop: '-1rem'}}>{error}</span>) || (warning &&
                            <span className="text-warning">{warning}</span>))}
                    </>);
                }}
            name={name}
            options={options}
            validate={validation}
        />
    </div>);
};

RadioButtonGroupFormComponent.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })),
    name: PropTypes.string.isRequired
};
