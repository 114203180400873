import modalTypes from '../type/modal';
import httpClient from '../../core/httpclient';
import { spinnerService } from "@simply007org/react-spinners";

const apiUrl = 'xms-definition-api/api/actionurl';

export const createActionUrl = (actionKey, actionType, spinnerId) => async dispatch => {
    const endPoint = `${apiUrl}/create-action-url`;

    const createActionUrlRequest = { actionType, actionKey };

    if (spinnerId) { spinnerService.show(spinnerId) }

    httpClient.post(endPoint, createActionUrlRequest)
        .then(response => {
            dispatch({
                type: modalTypes.SHOW_MODAL,
                payload: {
                    modalType: 'share-document',
                    modalData: response.data.result.generatedUrl

                }
            })
            if (spinnerId) { spinnerService.hide(spinnerId) }
        })
        .catch(function (err) {
            console.log(err);
            if (spinnerId) { spinnerService.hide(spinnerId) }
        });
}