import React from 'react';
import { Col } from 'react-bootstrap';
import { Label } from '@dfds-ui/react-components';

const TaskProperty = ({ property }) => {
    const { columnMdSize, columnXsSize, label, value, classes } = property;
    return <Col md={columnMdSize} xs={columnXsSize} className={classes}>
        <Label className='text-secondary'>{label}</Label><br />
        <Label style={{ whiteSpace: 'nowrap' }}>
            {value}
        </Label>
    </Col>
}

export default TaskProperty;