/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getSuffixList, deleteSuffix } from '../store/action/notificationActions';
import { Form, Card } from 'react-bootstrap';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const Notification = ({ suffixList, getSuffixList, deleteSuffix }) => {
    useEffect(() => {
        if (!suffixList) {
            getSuffixList();
        }
        setFilteredData(suffixList);
    }, [suffixList]);

    const [filteredData, setFilteredData] = useState(suffixList);
    const searchTermChangedHandler = (key) => {
        const searchTerm = key.toLowerCase();
        if (!searchTerm) {
            setFilteredData(suffixList);
            return;
        }

        const newFilteredData = [];
        for (const suffix of suffixList) {
            if ((suffix.suffixName && suffix.suffixName.toLowerCase().indexOf(searchTerm) > -1) ||
                (suffix.shortDescription && suffix.shortDescription.toLowerCase().indexOf(searchTerm) > -1) ||
                (suffix.longDescription && suffix.longDescription.toLowerCase().indexOf(searchTerm) > -1) ||
                (suffix.city && suffix.city.toLowerCase().indexOf(searchTerm) > -1) ||
                (suffix.countryCode && suffix.countryCode.toLowerCase().indexOf(searchTerm) > -1) ||
                (suffix.countryName && suffix.countryName.toLowerCase().indexOf(searchTerm) > -1)) {
                newFilteredData.push(suffix);
            }
        }
        setFilteredData(newFilteredData);
    }

    const notificationEmailClickedHandler = (suffix) => {
        history.push('notification/email/' + suffix.suffixName);
    }

    const notificationEditClickedHandler = (suffix) => {
        history.push('notification/' + suffix.suffixName);
    }

    const notificationDeleteClickedHandler = (suffix) => {
        if (confirm('Do you want to delete this suffix? ')) {//eslint-disable-line
            deleteSuffix(suffix);
        }
    }
    const newSuffixClickedHandler = () => {
        history.push('notification/new');
    }

    const renderCountryColumn = row => {
        return [row.countryCode, row.countryName].join(' - ');
    }

    const renderFilters = () => {
        return <Form.Control type="text" placeholder="Search" onChange={(e) => searchTermChangedHandler(e.target.value)} />
    }

    const tableConfigurations = {
        title: 'Suffixes',
        noRowText: 'There are not any suffixes to show.',
        hasFilter: true,
        showFiltersOnInit: true,
        entities: filteredData,
        columns: [
            { alias: 'Name', name: 'suffixName', type: 'string' },
            { alias: 'Desc', name: 'shortDescription', type: 'string' },
            { alias: 'Company', name: 'longDescription', type: 'string' },
            { alias: 'City', name: 'city', type: 'string' },
            { alias: 'Country', name: '', type: 'custom', customRender: renderCountryColumn, style: { whiteSpace: 'nowrap' } }
        ],
        tableActions: [
            { clickEvent: newSuffixClickedHandler, buttonText: 'Create Suffix', icon: 'plus' }
        ],
        rowActions: [
            { title: 'Edit suffix e-mails', clickEvent: notificationEmailClickedHandler, icon: 'envelope' },
            { title: 'Edit suffix', clickEvent: notificationEditClickedHandler, icon: 'edit' },
            { title: 'Delete suffix', clickEvent: notificationDeleteClickedHandler, icon: 'trash' }
        ],
        filterRenderer: renderFilters,
    }
    return <Page>
        <XmsTable config={tableConfigurations} />
        <LoadingIndicator id="notificationGetSuffixList" />
    </Page >
}
const mapStateToProps = (state) => {
    return {
        suffixList: state.notification.suffixList
    }
}
export default connect(mapStateToProps, { getSuffixList, deleteSuffix })(Notification);