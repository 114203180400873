/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fillBookingFormData, clearBookingState, getBookingItem, getBusinessAreas, getUnitTypesForBooking } from '../store/action/bookingActions';
import history from '../core/history';
import '../core/schema.css';
import './BookingCommon.css';
import { Row, Col } from 'react-bootstrap';
import PageHeader from '../component/PageHeader/PageHeader';
import SchemaInterpreter from "../component/FormComponents/Schema/SchemaInterpreter";
import LoadingIndicator from "../component/LoadingIndicator/LoadingIndicator";
import { CreateBookingSchema } from "../component/FormComponents/Schema/createBooking";
import { isDevelopmentEnvironment } from '../core/utility';
import Page from '../component/Page/Page';

const CreateBookingContainer = ({ getBusinessAreas, businessAreas, fillBookingFormData, clearBookingState, formData, getBookingItem, getUnitTypesForBooking, unitTypesForBooking }) => {
    const ref = useRef(null);

    useEffect(() => {
        clearBookingState();
    }, []);

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollIntoView();
        }
    }, [ref]);

    useEffect(() => {

        if (!businessAreas) {
            getBusinessAreas();
        }
        if (!unitTypesForBooking) {
            getUnitTypesForBooking();
        }

        if (unitTypesForBooking && businessAreas) {
            getBookingItem();
        }

    }, [businessAreas, unitTypesForBooking]);

    const getOperationTypeList = () => {
        return [
            { value: "false", text: "Loading" },
            { value: "true", text: "Unloading" }
        ];
    };

    const submit = (bookingModel) => {
        fillBookingFormData(bookingModel);
        history.push('/booking/new/select-time-slot');
    };

    return <Page hasBackButton>
        <div style={{ display: 'hidden' }} ref={ref} ></div>

        <SchemaInterpreter
            title='Please fill the form'
            form={"CreateBooking"}
            onSubmit={submit}
            formItem={formData}
            formSchema={CreateBookingSchema}
            operationTypes={getOperationTypeList()}
            typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
            businessAreas={businessAreas}
            businessAreasFirstItem={{ value: '', text: 'Choose Business Area' }}
            unitTypes={unitTypesForBooking}
            unitTypeFirstItemObject={{ value: '', text: 'Choose Unit Type' }}
        />
        <LoadingIndicator id="createOrUpdateBooking" />
    </Page>
};

const testData = {
    hasDangerousGoods: 'false',
    hasCustoms: 'false',
    businessArea: '719af923-4040-4e20-a0a2-248f366fbb30',
    unitNo: 'TestUnit',
    name: 'TestName',
    surname: 'TestSurname',
    email: 'email@mail.com',
    operationType: 'true',
    phoneNumber: '1234576',
    unitType: '2',
    thuAmount: '123',
    transportNumbers: 'T001',
    carrier: 'CarrierTest'
}

const mapStateToProps = (state) => {
    return {
        formData: state.booking.formData,
        businessAreas: state.booking.businessAreas,
        unitTypesForBooking: state.booking.unitTypesForBooking
    }
}
export default connect(mapStateToProps, {
    fillBookingFormData, clearBookingState, getBookingItem, getBusinessAreas, getUnitTypesForBooking
})(CreateBookingContainer);
