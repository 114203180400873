import React from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../core/history';

const Page = ({ hasBackButton, backLink, backToWhere, children }) => {
    return <Card>
        <Card.Body>
            {(backLink || hasBackButton) && <Row>
                <Col>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <Button style={{ fontSize: '14px', marginBottom: '17px' }} className='color-action-blue' variant="transparent" onClick={() => backLink ? history.push(backLink) : history.goBack()}>
                                <FontAwesomeIcon icon="chevron-left" size='lg' />
                                <span style={{ paddingLeft: '10px' }}>
                                    {!backLink ? `Go back` : `Back to ${backToWhere}`}
                                </span>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>}
            <Row>
                <Col>
                    {children}
                </Col>
            </Row>
        </Card.Body>
    </Card>
}

export default Page;
