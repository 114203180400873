import imageTypes from '../type/imageTypes';
import _ from 'lodash';

const initialState = {
    imageList: null,
    imageFileList: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case imageTypes.CLEAR_IMAGE_FILE_LIST:
            return {
                ...state,
                ...initialState
            }
        case imageTypes.ADD_IMAGE_FILES_TO_LIST:
            return {
                ...state,
                imageList: action.payload
            }
        case imageTypes.ADD_IMAGE_TO_LIST:
            return {
                ...state,
                imageFileList: { ...state.imageFileList, [action.payload.uid]: action.payload.file }
            }
        case imageTypes.REMOVE_IMAGE_FROM_LIST:
            return {
                ...state,
                imageFileList: _.omit({ ...state.imageFileList }, [action.payload])
            }
        default:
            return state;
    }
}