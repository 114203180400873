import React from 'react';
import PageHeader from '../component/PageHeader/PageHeader';
import ThuDetailList from '../component/ShipmentDetail/ThuDetailList';
import CreateThu from '../component/CreateThuDetail/CreateThuDetail';

const CreateThuDetail = (props) => {

    //parent inputs
    const {
        shipmentReferenceNumber,
        shipmentId
    } = props.match.params;


    return (
        <div>
            {renderPageHeader(shipmentId)}
            {renderCreateThuDetail(shipmentReferenceNumber, shipmentId)}
            {renderThuDetailList(shipmentReferenceNumber)}
        </div>
    )
}

const renderPageHeader = (shipmentId) => {
    return <PageHeader text={`Create THU ${shipmentId}`} />
}

const renderCreateThuDetail = (shipmentReferenceNumber, shipmentId) => {
    return (
        <CreateThu
            shipmentReferenceNumber={shipmentReferenceNumber}
            shipmentId={shipmentId}
        />
    )
}

const renderThuDetailList = (shipmentReferenceNumber) => {
    return (
        <div className='card card-body'>
            <h5 className='text-center'>Thu Detail List</h5>
            <ThuDetailList
                shipmentReferenceNumber={shipmentReferenceNumber}
                allowDelete={true}
            />
        </div>
    )
}

export default CreateThuDetail;