import {Field} from "redux-form";
import {InputComponent} from "./Pure/InputComponent";
import React from "react";
import * as PropTypes from "prop-types";

export const HiddenInputFormComponent = ({name}) => {
   return (<Field
        name= {name}
        component={InputComponent}
        type="hidden"
    />)
};

HiddenInputFormComponent.propTypes = {
    name: PropTypes.string.isRequired
};