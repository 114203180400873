/* eslint-disable react-hooks/exhaustive-deps */
import '../core/schema.css';
import { connect } from 'react-redux';
import history from '../core/history';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { epochToShortDate } from '../core/epochToDateConverter';
import TimeSlotTable from '../component/TimeSlotTable/TimeSlotTable';
import { TabCollection } from '../component/BaseComponents/TabCollection';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import { selectTimeSlot, getTimeSlots, getAvailableDates, clearTimeSlots } from '../store/action/bookingActions';
import { isOnlyExternalUser } from '../core/authentication';
import Page from '../component/Page/Page';
const SelectTimeSlot = ({ availableDates, getTimeSlots, selectTimeSlot, timeSlots, isRedirectionNeeded, businessArea, getAvailableDates, clearTimeSlots }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [timeSlotListWithDate, setTimeSlotListWithDate] = useState(null)

    useEffect(() => {
        if (!businessArea) {
            toast.error('Required parameters could not taken. Redirecting to Booking Page');
            setTimeout(() => {
                if (!isOnlyExternalUser()) {
                    history.push('/booking/list');
                } else {
                    history.push('/booking/carrier-booking-list');
                }
            }, 1000);
            return;
        }

        getAvailableDates(businessArea);

    }, []);

    useEffect(() => {
        if (availableDates) {
            setSelectedDate(availableDates[0]);
        }
    }, [availableDates]);

    useEffect(() => {
        if (businessArea && selectedDate && (!timeSlotListWithDate || !timeSlotListWithDate[selectedDate])) {
            getTimeSlots(businessArea, selectedDate);
        }

    }, [businessArea, selectedDate]);

    useEffect(() => {
        if (selectedDate && timeSlots) {
            const timeSlotListClone = { ...timeSlotListWithDate };
            timeSlotListClone[selectedDate] = timeSlots;
            setTimeSlotListWithDate(timeSlotListClone);
            clearTimeSlots();
        }
    }, [selectedDate, timeSlots]);

    useEffect(() => {
        if (isRedirectionNeeded) {
            toast.error('There are not any time slots to select.');
            setTimeout(() => {
                if (!isOnlyExternalUser()) {
                    history.push('/booking/list');
                } else {
                    history.push('/booking/carrier-booking-list');
                }
            }, 1000);
        }
    }, [isRedirectionNeeded]);

    const timeSlotClickedHander = (date, time) => {
        selectTimeSlot(date, time);
        history.push('/booking/new/confirm');
    }

    const onDateClickedHandler = date => {
        setSelectedDate(date);
    }

    const isReadyToRender = () => availableDates && selectedDate && timeSlotListWithDate;
    const getHeaderText = () => isReadyToRender() && timeSlotListWithDate[selectedDate] ? 'Select A Time Slot' : 'Please wait...';

    return <Page backLink='/booking/new' backToWhere='Form'>
        <h4>{isReadyToRender() && timeSlotListWithDate[selectedDate] ? 'Select A Time Slot' : 'Please wait...'}</h4><hr className='mt-0' />
        
        {isReadyToRender() && <TabCollection
            customTabRenderer={timeSlot => epochToShortDate(timeSlot)}
            selectedTab={selectedDate}
            onTabClickedEvent={onDateClickedHandler}
            tabs={availableDates}>
            <Row>
                <TimeSlotTable timeSlots={timeSlotListWithDate[selectedDate]} timeSlotClickedEvent={timeSlotClickedHander} />
            </Row>
        </TabCollection>}
        <LoadingIndicator id="bookingTimeSlot" show />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        availableDates: state.booking.availableDates,
        timeSlots: state.booking.timeSlots,
        businessArea: state.booking.formData && state.booking.formData.businessArea ? state.booking.formData.businessArea : '',
        isRedirectionNeeded: state.booking.isRedirectionNeeded,
    }
};
export default connect(mapStateToProps, { getTimeSlots, selectTimeSlot, getAvailableDates, clearTimeSlots })(SelectTimeSlot);