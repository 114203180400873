import calendarActions from "../type/calendar";

const initialState = {
    rule: null,
    rules: null,
    businessAreas: null,
    isCreateRuleSuccess: false,
    simulatedTimeSlots: null,
    effectingRules: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case calendarActions.CALENDAR_GETALLRULES_SUCCESS:
            return {
                ...state,
                rules: action.payload
            }
        case calendarActions.CALENDAR_GETRULE_SUCCESS:
            return {
                ...state,
                rule: action.payload
            }

        case calendarActions.CALENDAR_CREATERULE_SUCCESS:
            return {
                ...state,
                rule: null,
                rules: state.rules.concat(action.payload)
            }
        case calendarActions.CALENDAR_DELETERULE_SUCCESS:
            const rulesCloned = [...state.rules];
            const deletedRule = rulesCloned.find(x => x.id === action.payload);
            if (deletedRule) {
                rulesCloned.splice(rulesCloned.indexOf(deletedRule), 1);
            }
            return {
                ...state,
                rules: rulesCloned
            }
        case calendarActions.BUSINESS_AREAS_RETRIEVED: {
            return {
                ...state,
                businessAreas: action.payload
            }
        }
        case calendarActions.TIME_SLOT_SIMULATION_RETRIEVED: {
            return {
                ...state,
                simulatedTimeSlots: action.payload
            }
        }
        case calendarActions.CLEAR_RULES: {
            return {
                ...state,
                rules: null
            }
        }
        case calendarActions.TIME_SLOT_EFFECTING_RULES_RETRIEVED: {
            return {
                ...state,
                effectingRules: action.payload
            }
        }
        default:
            return state;
    }

}