import React from 'react';

const NoAuth = () => {
    return (
        <>
            <p>It seems you don't have an access to see this page.</p>
        </>
    );
};

export default NoAuth;