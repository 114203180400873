/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getService, clearServiceState, getServiceList, insertOrUpdateService } from '../store/action/serviceActions';
import { Row, Col, Card, Button } from 'react-bootstrap';
import ServiceForm from '../component/ServiceForm/ServiceForm';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import { toast } from 'react-toastify';
import { CreateOrUpdateServiceSchema } from '../component/FormComponents/Schema/createOrUpdateService';
import SchemaInterpreter from '../component/FormComponents/Schema/SchemaInterpreter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Page from '../component/Page/Page';

function AddOrEditService({ match, serviceList, service, getService, clearServiceState, getServiceList, insertOrUpdateService }) {
    useEffect(() => {

        if (!serviceList) {
            getServiceList();
        }
        return () => {
            clearServiceState();
        }
    }, []);

    useEffect(() => {
        if (match.params.servicecode !== 'new' && !service) {
            getService(serviceList, match.params.serviceId);
        }
    }, [serviceList, match.params.servicecode])

    const handleSubmit = (form) => {
        insertOrUpdateService(form.id, form.code, form.name, form.description, form.operationType);
        if (match.params.servicecode === 'new') {
            toast.success('Service added.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        } else {
            toast.success('Service updated.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }

        history.push('/service');
    }
    const getOperationTypeList = () => {
        return [
            { value: "Loading", text: "Loading" },
            { value: "Unloading", text: "Unloading" }
        ];
    };

    return (<Page backLink='/service/' backToWhere='Services'>
        <SchemaInterpreter
            title={match.params.serviceId === 'new' ? 'New service' : service ? service.name : '...'}
            form={"CreateOrUpdateService"}
            onSubmit={handleSubmit}
            formItem={service}
            formSchema={CreateOrUpdateServiceSchema}
            operationTypes={getOperationTypeList()}
            typeFirstItemObject={{ value: '', text: 'Choose Operation Type' }}
        />
        <LoadingIndicator id="serviceDetail" />
    </Page>)
}

const mapStateToProps = (state) => {
    return {
        service: state.service.service,
        serviceList: state.service.serviceList,
    }
}
export default connect(mapStateToProps, { getServiceList, getService, clearServiceState, insertOrUpdateService })(AddOrEditService);
