import roleTypes from '../type/role';

const initialState = {
    roleListResponse: null,
    role: null,
    policies: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case roleTypes.ROLE_GETROLES_SUCCESS:
            return {
                ...state,
                roleListResponse: action.payload
            }
        case roleTypes.ROLE_GETROLE_SUCCESS:
            return {
                ...state,
                role: action.payload
            }
        case roleTypes.ROLE_GETPOLICIES_SUCCESS:
            return {
                ...state,
                policies: action.payload
            }
        case roleTypes.ROLE_CLEARSTATE_SUCCESS:
            return initialState;
        default:
            return state;
    }
}