import React from 'react';
import { Card, Button } from 'react-bootstrap'
import classes from './PageHeader.module.css';
import history from '../../core/history';

const PageHeader = ({ text, backButtonEnabled = true }) => {
    return (
        <Card px={0}>
            <Card.Header className={`text-center ${classes['xms-page-header']}`}>
                {backButtonEnabled ? <div className="float-left">
                    <Button variant="transparent" onClick={() => history.goBack()}>
                        <img src="/image/icon/back.svg" alt="" />
                    </Button>
                </div> : null}

                <div className="text-center">
                    <h5>{text}</h5>
                </div>
            </Card.Header>
        </Card>
    );
};

export default PageHeader;

