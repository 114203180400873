/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
    resetUnitData,
    searchUnit,
    concatUnit,
    getShipmentFilesOfUnit,
    getDocsMissingStates
} from '../store/action/unitActions';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import PageHeader from '../component/PageHeader/PageHeader';
import UnitTransport from '../component/UnitDetail/UnitTransport';
import FileController from '../component/FileController/FileController';
import ImageController from '../component/ImageController';
import UnitSummary from '../component/UnitSummary/UnitSummary';
import AuthorizeContext from '../context/AuthorizeContext';

const UnitDetails = (props) => {

    const policies = useContext(AuthorizeContext);

    const [isUnitFetched, setIsUnitFetched] = useState(false);
    //Inputs from url
    const {
        unitId
    } = props.match.params;

    //Inputs from redux
    const {
        fetchedUnit,
        fileList,
        docsMissingStateList,
        resetUnitData,
        searchUnit,
        getShipmentFilesOfUnit,
        getDocsMissingStates
    } = props;

    useEffect(() => { if (fetchedUnit) { resetUnitData() } }, [])

    useEffect(() => {
        if (!fetchedUnit) {
            searchUnit(unitId, false, 'searchUnit');
            setIsUnitFetched(true);
        }
    }, [fetchedUnit])

    useEffect(() => {
        if (fetchedUnit && isUnitFetched) {
            getShipmentFilesOfUnit(fetchedUnit, 'fetchFileList');
            getDocsMissingStates(fetchedUnit, 'fetchFileList');
        }
    }, [fetchedUnit, isUnitFetched])

    return (
        <div>
            {renderPageHeader(fetchedUnit)}
            {renderUnitSummaryInfo(fetchedUnit)}
            <div id="accordion">
                {renderFileController(fetchedUnit, docsMissingStateList, fileList)}
                {renderImageController(fetchedUnit)}
                {renderUnitTransport(fetchedUnit)}
            </div>
            <LoadingIndicator id='searchUnit' />
        </div>
    )
}

const renderPageHeader = fetchedUnit => {

    if (!fetchedUnit) { return <PageHeader text='' /> }

    return <PageHeader text={fetchedUnit.internalId} />
}

const renderUnitSummaryInfo = fetchedUnit => {

    if (!fetchedUnit)
        return;

    return (<UnitSummary unit={fetchedUnit} />);
}

const renderUnitTransport = (fetchedUnit) => {

    if (!fetchedUnit)
        return;

    return (<UnitTransport unit={fetchedUnit} />);
}

const renderFileController = (fetchedUnit, docsMissingStateList, fileList) => {

    if (!fetchedUnit)
        return;

    const concatenatedUnitPath = concatUnit(fetchedUnit.internalId);
    const operationButtonsToShow = { addFile: true, split: true, download: true, upload: true, shareDocuments: true, coverpage: true };
    const uploadOptions = {
        uploadFileRelativePath: concatenatedUnitPath,
        uploadFileRelativePathAsArray: [concatenatedUnitPath],
        uploadData: fetchedUnit
    };
    const deleteOptions = { deleteFileRelativePath: [concatenatedUnitPath] };
    const downloadOptions = {
        downloadAllFilePathList: [concatenatedUnitPath, ...fetchedUnit.shipmentRelativePathList],
        downloadFileRelativePath: [concatenatedUnitPath]
    };
    const splitOptions = { splitUrl: `/unit/${fetchedUnit.internalId}/split` }
    const shareOptions = {
        actionKey: fetchedUnit.internalId,
        actionType: 'download-unit-documents'
    }

    const coverPageOptions = {
        coverPageData: createCoverPageOptions(fetchedUnit, docsMissingStateList, fileList),
        coverPageDownloadName: fetchedUnit.externalId
    }

    return (
        <FileController
            operationButtonsToShow={operationButtonsToShow}
            uploadOptions={uploadOptions}
            deleteOptions={deleteOptions}
            downloadOptions={downloadOptions}
            splitOptions={splitOptions}
            shareOptions={shareOptions}
            coverPageOptions={coverPageOptions}
        />
    );
}

const concatShipment = (relativePath) => {
    if (relativePath === '' || relativePath === null) {
        return 'Shipment';
    } else {
        return 'Shipment/' + relativePath;
    }
}
const findShipmentPageCountFromList = (fileList, shipmentRef) => {
    const fileArray = fileList[shipmentRef] ? fileList[shipmentRef] : [];
    const fileCount = fileArray.length;
    const sumOfPages = fileArray.reduce((prev, cur) => prev + cur.pageCount, 0);
    const result = { fileCount, sumOfPages };
    return result;
}
const createCoverPageOptions = (fetchedUnit, docsMissingStateList, fileList) => {

    const coverPageOptions = {
        unitInternalRef: fetchedUnit.internalId,
        unitNumber: fetchedUnit.externalId,
        shipments: []
    };
    for (const transport of fetchedUnit.transports) {
        for (const shipment of transport.shipments) {
            if (!fileList) {
                continue;
            }
            const fileAndPageCounts = findShipmentPageCountFromList(fileList, shipment.referenceNumber);
            const coverPageShipment = {
                docsMissing: (fileAndPageCounts.fileCount === 1 && fileAndPageCounts.sumOfPages === 1) || (docsMissingStateList ? docsMissingStateList[shipment.referenceNumber] : false),
                internalRef: shipment.referenceNumber,
                ocId: shipment.associateID,
                ocName: shipment.carrierName,
                shipmentNo: shipment.id,
                suffix: shipment.suffix,
                thuCount: shipment.thuCount,
                volume: shipment.volume,
                weight: shipment.grossWeight
            }
            coverPageOptions.shipments.push(coverPageShipment);
        }
    }

    return coverPageOptions;
}

const renderImageController = (fetchedUnit) => {

    if (!fetchedUnit) { return; }

    const imageControllerOptions = {
        relativePath: [`image/unit/${fetchedUnit.internalId}`],
        relativePathForFetch: `image/unit/${fetchedUnit.internalId}`,
        redirectUrl: `/unit/${fetchedUnit.internalId}`,
        sourceType: 'unit',
        sourceKey: fetchedUnit.internalId
    }

    return (
        <ImageController
            imageControllerOptions={imageControllerOptions}
        />
    )
}

const mapStateToProps = ({ unit }) => {
    return {
        fetchedUnit: unit.fetchedUnit,
        fileList: unit.fileList,
        docsMissingStateList: unit.docsMissingStateList
    }
}

const mapActionsToProps = {
    resetUnitData,
    searchUnit,
    getShipmentFilesOfUnit,
    getDocsMissingStates
}

export default connect(mapStateToProps, mapActionsToProps)(UnitDetails);