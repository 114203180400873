/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPolicies } from '../../store/action/roleActions';

const PolicyList = (props) => {

    const [selectedPolicies, setSelectedPolicies] = useState([]);

    //store elements
    const { policies } = props;
    const { getPolicies } = props;

    //comes from parent 
    const {
        onPolicyToggle,
        predefinedPolicies
    } = props;

    useEffect(() => {
        getPolicies();
    }, [])

    useEffect(() => {
        if (predefinedPolicies) {
            setSelectedPolicies(predefinedPolicies);
        }
    }, [predefinedPolicies])

    const toggleStatus = (policyName, newValue) => {
        let tempList = [...selectedPolicies];
        if (newValue) {
            tempList.push(policyName)
        } else {
            tempList = tempList.filter(p => p !== policyName);
        }
        setSelectedPolicies(tempList);
        onPolicyToggle(policyName, newValue);
    }

    const isPolicySelected = (policyName) => {
        const queryResult = selectedPolicies.filter(p => p === policyName);
        return queryResult.length > 0;
    }

    return (
        <>
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <td>

                        </td>
                        <td>Name</td>
                        <td>Description</td>
                    </tr>
                </thead>
                {generateTableBody(policies, toggleStatus, isPolicySelected)}
            </table>
        </>
    )
}


const generateTableBody = (policies, toggleStatus, isPolicySelected) => {

    if (!policies) { return; }

    const tableRows = policies.map(policy => {
        return (
            <tr key={policy.name}>
                <td>
                    <input
                        type='checkbox'
                        id={policy.name}
                        onChange={event => { toggleStatus(policy.name, event.target.checked) }}
                        checked={isPolicySelected(policy.name)}
                    />
                </td>
                <td>{policy.name}</td>
                <td>{policy.description}</td>
            </tr>
        )
    })

    return (
        <tbody>
            {tableRows}
        </tbody>
    )
}

const mapStateToProps = ({ role }) => {
    return {
        policies: role.policies
    }
}


export default connect(mapStateToProps, { getPolicies })(PolicyList);