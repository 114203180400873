//import { useTranslation as i18useTranslation } from 'react-i18next';
//import i18next from 'i18next';
import i18n from '../core/i18n';

const findKey = (key) => {
    //const { t } = i18useTranslation();

    if (i18n.exists(key)) {
        return i18n.t(key);
    }

    const splittedKey = key.split('.');
    if (splittedKey.length < 2 || !key) {
        return null;
    }

    splittedKey.splice(splittedKey.length - 2, 1); //Remove rigth most namespace.
    return findKey(splittedKey.join('.'));
}

const translator = function (key) {
    const translated = findKey(key);

    return translated ? translated : key;
}

const useTranslation = () => {
    return { t: translator };
}

// JSON formatındaki translation dokümanında hiyerarşiye göre arama yapan t fonksiyonunu döner. Böylece "id", "shipment.id", "history.shipment.id" şeklinde namespace'e göre özelleştirmelere imkan vermiş olur.
// Örneğin key olarak "history.shipment.id" gönderilmiş olsun. Tanımlı olan JSON içinde { history: { shipment:{ id: 'ID' }}} şeklinde bir tanımlama var mı diye bakar. Bulamazsa en sağdan bir namespace'i kaldırır ve tekrar arar.
// { history: { id: 'ID' }} gibi bir tanımlama arar. O da yoksa { id: 'ID' } şeklinde kökte bir tanımlama var mı diye arar. Bulamazsa, "history.shipment.id" key'ini geri döner. 
//Böylece ekranda translate edilmemiş alanlar kolayca görülebilir ve tanımlama yapılabilir.
//useTranslation ismi özellikle verilmiştir. Böylece i18 convention'ı bozulmadan aynı fonksiyonalite extend edilmiş oldu.
export default useTranslation;
