const calendarActions = Object.freeze({
    CALENDAR_GETALLRULES_SUCCESS: Symbol('CALENDAR_GETALLRULES_SUCCESS'),
    CALENDAR_GETRULE_SUCCESS: Symbol('CALENDAR_GETRULE_SUCCESS'),
    CALENDAR_CREATERULE_SUCCESS: Symbol('CALENDAR_CREATERULE_SUCCESS'),
    CALENDAR_DELETERULE_SUCCESS: Symbol('CALENDAR_DELETERULE_SUCCESS'),
    BUSINESS_AREAS_RETRIEVED: Symbol('BUSINESS_AREAS_RETRIEVED'),
    TIME_SLOT_SIMULATION_RETRIEVED: Symbol('TIME_SLOT_SIMULATION_RETRIEVED'),
    TIME_SLOT_EFFECTING_RULES_RETRIEVED: Symbol('TIME_SLOT_EFFECTING_RULES_RETRIEVED'),
    CLEAR_RULES: Symbol('CLEAR_RULES')
});

export default calendarActions;
 