/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import history from '../../core/history';
import { getUser, clearSelectedUser } from '../../store/action/userActions';
import { Button } from '@dfds-ui/react-components';

const validate = values => {
    const errors = {}
    if (values.password !== values.repassword) {
        errors.repassword = "Password does not match.";
    }
    return errors;
}
const renderField = ({ input, label, type, meta: { touched, error, warning }, ...props }) => (
    <div>
        <label>{label}</label>
        <div>
            <Form.Control {...props} {...input} type={type} />
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
)
let ChangePasswordForm = ({ userName, getUser, userList, clearSelectedUser, handleSubmit }) => {
    useEffect(() => {
        if (!userList) {
            history.push('/user');
        }
        getUser(userList, userName);

        return () => {
            clearSelectedUser();
        }
    }, [userList, getUser]);

    return (<>
        <Form onSubmit={handleSubmit}>
            <Form.Row>
                <Form.Group as={Col} md="4">
                    <Form.Label>Username</Form.Label>
                    <Field name="username"
                        disabled
                        type="input"
                        component={renderField} />
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Name</Form.Label>
                    <Field name="name"
                        disabled
                        type="input"
                        component={renderField} />
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Label>Surname</Form.Label>
                    <Field name="surname"
                        disabled
                        type="input"
                        component={renderField} />
                </Form.Group>

            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>Password</Form.Label>
                    <Field
                        name="password"
                        required
                        component={renderField}
                        type="password"
                    />
                </Form.Group>
                <Form.Group as={Col} md="6">
                    <Form.Label>Re-Password</Form.Label>
                    <Field
                        name="repassword"
                        required
                        component={renderField}
                        type="password"
                    />
                </Form.Group>
            </Form.Row>
            <Button type="submit" className="float-right">Change password</Button>
        </Form>
    </>)
}

const mapStateToProps = (state) => {
    return {
        initialValues: state.user.user,
        user: state.user.user,
        userList: state.user.userListResponse ? state.user.userListResponse.items : null,
    }
}
ChangePasswordForm = reduxForm({
    form: 'changeUserPasswordForm', // a unique identifier for this form
    validate
})(ChangePasswordForm);
ChangePasswordForm = connect(mapStateToProps, { clearSelectedUser, getUser })(ChangePasswordForm);

export default ChangePasswordForm
