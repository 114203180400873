/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getRules, deleteRule, getBusinessAreas } from '../store/action/reservedSlotActions';
import history from '../core/history';
import XmsTable from '../component/XmsTable/XmsTable';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import moment from 'moment';
import XmsPage from '../component/XmsPage/XmsPage';
import Page from '../component/Page/Page';
import { Card } from 'react-bootstrap';

const ReservedSlotRuleList = (props) => {
    //Redux actions
    const { getRules, deleteRule, getBusinessAreas } = props;

    //Redux store
    let { rules, businessAreas, refreshRules } = props;

    useEffect(() => {
        if (!businessAreas) {
            getBusinessAreas();
        }
    }, [businessAreas]);


    useEffect(() => {
        if (!rules || refreshRules) {
            getRules();
        }
    }, [rules, refreshRules]);

    const newRuleClickedHandler = () => {
        history.push('/booking/reserved-slot-rule/new');
    }

    const deleteRuleClickedHandler = rule => {
        deleteRule(rule.id);
    }

    const editRuleClickedHandler = rule => {
        history.push(`/booking/reserved-slot-rule/${rule.id}`);
    }

    const renderValidTimeColumn = rule => {
        return moment.utc().startOf('day').add(rule.startMinutesSinceMidnight, 'minutes').format('HH:mm') + ' - ' + moment.utc().startOf('day').add(rule.endMinutesSinceMidnight, 'minutes').format('HH:mm')
    }

    const renderBusinessAreaColumn = rule => {
        if (!rule || !businessAreas || !rule.businessAreaId) return '';
        const businessArea = businessAreas.find(x => x.id === rule.businessAreaId);
        return businessArea ? businessArea.name : '';
    }

    const tableConfiguration = {
        title: 'Reserved Slot Rules',
        noRowText: 'There are not any rules to show.',
        hasFilter: false,
        entities: rules,
        columns: [
            { alias: 'Business Area', name: 'businessAreaId', type: 'custom', customRender: renderBusinessAreaColumn },
            { alias: 'Company', name: 'company', type: 'string' },
            { alias: 'Weekday', name: 'dayOfWeek', type: 'string' },
            { alias: 'Start Date', name: 'startDate', type: 'epoch', format: 'shortDate' },
            { alias: 'End Date', name: 'endDate', type: 'epoch', format: 'shortDate' },
            { alias: 'Valid Time', name: null, type: 'custom', customRender: renderValidTimeColumn },
        ],
        tableActions: [
            { clickEvent: newRuleClickedHandler, buttonText: 'Create rule', icon: 'plus' }
        ],
        rowActions: [
            {
                title: 'Delete rule', clickEvent: deleteRuleClickedHandler, icon: 'trash', hasConfirm: false, confirmConfig: {
                    textValues: ['', "Are you sure?", "Deleting..."],
                    showTimer: true
                }
            },
            { title: 'Edit rule', clickEvent: editRuleClickedHandler, icon: 'edit' },
        ],
    }


    return <Page backLink='/booking/calendar-rule' backToWhere='Rules'>
        <XmsTable config={tableConfiguration} />
        <LoadingIndicator id='getRules' />
    </Page>
}

const mapStateToProps = state => {
    return {
        rules: state.reservedSlot.rules,
        refreshRules: state.reservedSlot.refreshRules,
        businessAreas: state.reservedSlot.businessAreas
    }
}
export default connect(mapStateToProps, { getRules, deleteRule, getBusinessAreas })(ReservedSlotRuleList);