/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getServiceList } from '../store/action/serviceActions';
import history from '../core/history';
import LoadingIndicator from '../component/LoadingIndicator/LoadingIndicator';
import XmsTable from '../component/XmsTable/XmsTable';
import Page from '../component/Page/Page';

const ServiceList = ({ pageIndex, serviceList, getServiceList }) => {
    useEffect(() => {
        if (!serviceList) {
            getServiceData(null);
        }
    }, [serviceList])
    const getServiceData = () => {
        getServiceList(pageIndex);
    }
    const serviceClickedHandler = service => {
        history.push('/service/' + service.id);
    }
    const newServiceClickedHandler = () => {
        history.push('/service/new');
    }

    const tableConfigurations = {
        title: 'Services',
        noRowText: 'There are not any services to show.',
        hasFilter: false,
        entities: serviceList,
        columns: [
            { alias: 'Code', name: 'code', type: 'string' },
            { alias: 'Name', name: 'name', type: 'string' },
            { alias: 'Description', name: 'description', type: 'string' },
            { alias: 'Operation Type', name: 'operationType', type: 'string' },
        ],
        tableActions: [
            { clickEvent: newServiceClickedHandler, buttonText: 'Create service', icon: 'plus' }
        ],
        rowActions: [
            { title: 'Edit service', clickEvent: serviceClickedHandler, icon: 'edit' },
        ],
    }

    return <Page>
        <XmsTable config={tableConfigurations} />
        <LoadingIndicator id="getServiceList" />
    </Page>
}

const mapStateToProps = (state) => {
    return {
        serviceList: state.service.serviceList,
    }
}
export default connect(mapStateToProps, { getServiceList })(ServiceList);  
