import React from 'react';
import { Label } from '@dfds-ui/react-components';
import CopyToClipboard from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '../../../BaseComponents/Badge';
import { toast } from 'react-toastify';
import { utcToEpochWithTime } from '../../../../core/utcToEpochConverter';
import { epochToShortDateTime } from '../../../../core/epochToDateConverter';

const LoadingTaskBadge = ({ task }) => {
    const onCopy = () => {
        toast.success('Copied to clipboard...', { autoClose: 2000 });
    }

    const getDetailsOf = (task) => {
        let timeOfArrival, elapsedTimeOfArrival = 0;
        timeOfArrival = (task && task.details && task.details['actualTimeOfArrival'] ? parseInt(task.details['actualTimeOfArrival']) : utcToEpochWithTime(new Date()));
        elapsedTimeOfArrival = task.operationDateTime;
        return {
            isLate: elapsedTimeOfArrival + (15 * 60) < timeOfArrival,
            isArrived: task.details && task.details['isArrived'] && task.details['isArrived'] === 'true',
            actualTimeOfArrival: task && task.details && task.details['actualTimeOfArrival'] && task.details['actualTimeOfArrival'] ? epochToShortDateTime(task.details['actualTimeOfArrival']) : '',
            gate: task.details && task.details['gate'] ? task.details['gate'] : ''
        }
    }

    const renderIdLabel = () => {
        const taskDetails = getDetailsOf(task);
        return <>
            {!taskDetails.isArrived && !taskDetails.isLate && !taskDetails.gate && 'ID'}
            {taskDetails.gate && <Badge style={{ fontWeight: '100', letterSpacing: '0.7px' }} title={`Gate: ${taskDetails.gate}`} className='p-2 mr-2' variant='secondary'>{taskDetails.gate.length <= 10 ? taskDetails.gate : taskDetails.gate.substring(0, 8) + '...'}</Badge>}
            {taskDetails.isArrived && <Badge title={`ATA: ${taskDetails.actualTimeOfArrival}`} className='p-2 mr-2' variant={taskDetails.isLate ? 'danger' : 'success'}>{`Arrived ${taskDetails.isLate ? ' late' : ''}`}</Badge>}
            {!taskDetails.isArrived && taskDetails.isLate && <Badge className='p-2' variant='danger'>Late</Badge>}
        </>
    }

    return <>
        <Label className='text-secondary'>{renderIdLabel()}</Label><br />
        <Label style={{ whiteSpace: 'nowrap' }}>
            {<Label onClick={e => e.stopPropagation()}>
                {task.id}
                <CopyToClipboard text={task.id} onCopy={onCopy}>
                    <FontAwesomeIcon title='Copy to clipboard' className='ml-2' style={{ cursor: 'pointer' }} icon="copy" />
                </CopyToClipboard>
            </Label>}
        </Label>
    </>
}

export default LoadingTaskBadge;